import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FileService } from 'app/shared/file.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Vendor, Questionnaire } from 'models/vendor.model';
import { Entity, Qualification, DefaultSetting } from 'models/entity.model';
import { NgForm } from '@angular/forms';
import { AccessLevelEnum } from 'app/shared/enums/accessLevel.enum';
import { UtilsService } from 'app/shared/utils.service';
import { ArtifactsEnum } from 'app/shared/enums/artifacts.enum';
import { VendorService } from '../../vendor.service';
import { ImpactTextEnum } from 'app/shared/enums/impact.enum';
import { StandardEnum } from 'app/shared/enums/standard.enum';

@Component({
  selector: 'cygov-add-vendor-modal',
  templateUrl: './add-vendor-modal.component.html',
  styleUrls: ['./add-vendor-modal.component.scss'],
})
export class AddVendorModalComponent implements OnInit {
  @Input() entity: Entity;
  @Output() modalResult = new EventEmitter<boolean>();
  vendor: Vendor = new Vendor();
  mandatoryArtifacts: Qualification[];
  selectedSurvey: ImpactTextEnum;
  selectedDueDate;
  isLoading = false;

  dueDateOptions: number[] = [30, 60, 90];
  accessLevels = Object.keys(AccessLevelEnum).map(key => AccessLevelEnum[key]);
  surveys = Object.keys(ImpactTextEnum).map(key => ImpactTextEnum[key]);
  standardList = [
    StandardEnum.PCI_DSS,
    StandardEnum.FFIEC,
    StandardEnum.GDPR,
    StandardEnum.HIPAA,
    StandardEnum.ISO_27001,
    StandardEnum.FERPA,
  ];
  standardOptions: Qualification[];

  constructor(
    private fileService: FileService,
    private vendorService: VendorService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.loadDefaultSetting(this.entity.defaultSetting);
  }

  loadDefaultSetting(defaultSetting: DefaultSetting) {
    // Default setting contains default standards, mandatory artifacts, dueDate\time, frequency
    const dueDate = defaultSetting.dueDateInDays || this.dueDateOptions[0];
    this.selectedDueDate = dueDate ? this.dateAsNgbDateStruct(dueDate) : null;
    this.setTimeline();

    this.vendor.vendorDetails.timeline.frequency = defaultSetting.frequency;
    this.selectedSurvey = defaultSetting.surveyLevel;
    this.vendor.vendorDetails.accessLevel = defaultSetting.accessLevel;

    // default entity standards and artifacts - predetermined by MSSP
    this.standardOptions =
      defaultSetting.standards ||
      this.standardList.map(standard => {
        return { isQualify: false, name: standard };
      });

    const artifactsWithOutCompliance = Object.keys(ArtifactsEnum).filter(
      key => !key.includes('COMPLIANCE')
    );
    this.mandatoryArtifacts =
      defaultSetting.artifacts ||
      artifactsWithOutCompliance.map(key => {
        return {
          isQualify: false,
          name: ArtifactsEnum[key],
        };
      });
  }

  isValid(ngForm: NgForm) {
    let valid = true;
    if (!ngForm.value.name) {
      this.toastr.error('Vendor name is required');
      valid = false;
    }
    if (!ngForm.value.primaryPocName) {
      this.toastr.error('Vendor POC name is required');
      valid = false;
    }
    if (!ngForm.value.primaryPocMail) {
      this.toastr.error('Vendor POC email is required');
      valid = false;
    } else if (ngForm.value.primaryPocMail.toLowerCase().indexOf('gmail') !== -1) {
      this.toastr.error('Company email only');
      valid = false;
    }

    return valid;
  }

  resetForm() {
    this.vendor = new Vendor();
    this.loadDefaultSetting(this.entity.defaultSetting);
  }

  async addVendor(form: NgForm) {
    this.toastr.info(`Adding ${this.vendor.name}!`, null, {
      timeOut: 50000,
      tapToDismiss: false,
      progressBar: true,
      progressAnimation: 'increasing',
    });
    this.isLoading = true;
    try {
      // add more data to vendor
      this.vendor.details.reference.id = this.vendor.id;
      this.vendor.details.reference.name = this.vendor.name;
      this.vendor.details.reference.date = new Date(this.vendor.details.reference.date).getTime();
      this.vendor.details.stateReference.id = this.entity.id;
      this.vendor.details.stateReference.name = this.entity.name;

      this.vendor.vendorDetails.impact = this.calcVendorImpact(
        this.selectedSurvey,
        this.mandatoryArtifacts
      );

      this.vendor.vendorDetails.primaryPoc.email = this.vendor.vendorDetails.primaryPoc.email.toLowerCase();
      if (!this.vendor.vendorDetails.domain) {
        this.vendor.vendorDetails.domain = this.vendor.vendorDetails.primaryPoc.email
          .split('@')
          .pop();
      }

      const requiredStandards: Qualification[] = this.standardOptions.filter(
        standard => standard.isQualify
      );
      this.vendor.vendorDetails.standardsQualify = requiredStandards.map(standard => {
        standard.isQualify = false;
        return standard;
      });

      // load/add survey
      const file = await this.fileService.getFile('vendor-survey.csv');
      const { data } = await FileService.parseFile(file);

      let surveyData = this.filterSurveyByPriorityEnum(data, this.selectedSurvey);
      surveyData = this.removeUnMandatoryArtifacts(surveyData, this.vendor);
      this.vendor.vendorDetails.questionnaires = this.initQuestionnaires(surveyData);

      this.vendor = await this.vendorService.addVendor(this.vendor, surveyData);
      this.toastr.clear();
      this.isLoading = false;
      this.toastr.success(`${this.vendor.name} added successfully!`);
      this.modalResult.emit(true);
      this.resetForm();
    } catch (e) {
      this.toastr.clear();
      this.isLoading = false;
      console.log('addVendor - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  calcVendorImpact(selectedSurvey: ImpactTextEnum, mandatoryArtifacts: Qualification[]) {
    let minPoints: number;
    let maxPoints: number;
    const pointForEachArtifact = 32 / (mandatoryArtifacts.length + 1);

    switch (selectedSurvey) {
      case ImpactTextEnum.LOW:
        minPoints = 1;
        maxPoints = 33;
        break;

      case ImpactTextEnum.MEDIUM:
        minPoints = 34;
        maxPoints = 66;
        break;

      case ImpactTextEnum.HIGH:
        minPoints = 67;
        maxPoints = 100;
        break;
    }
    const checkedArtifacts = mandatoryArtifacts.filter(artifact => artifact.isQualify).length;
    const unCheckedArtifacts = mandatoryArtifacts.length - checkedArtifacts;
    minPoints = checkedArtifacts ? minPoints + checkedArtifacts * pointForEachArtifact : minPoints;
    maxPoints = unCheckedArtifacts
      ? maxPoints - pointForEachArtifact * unCheckedArtifacts
      : maxPoints;

    return UtilsService.getRandomNumber(minPoints, maxPoints, 0);
  }

  initQuestionnaires(surveyData) {
    const questionnaires = [];
    const processAreaMap = {};
    surveyData.forEach(el => {
      const processArea = el.processArea.toLowerCase();
      if (!processAreaMap[processArea]) {
        processAreaMap[processArea] = true;
        const questionnaire = new Questionnaire({ tag: processArea });
        questionnaires.push(questionnaire);
      }
    });

    return questionnaires;
  }

  filterSurveyByPriorityEnum(data, selectedSurvey: ImpactTextEnum) {
    let surveyData = data;
    switch (selectedSurvey) {
      case ImpactTextEnum.LOW:
        surveyData = data.filter(el => !el.sal || el.sal === ImpactTextEnum.LOW);
        break;

      case ImpactTextEnum.MEDIUM:
        surveyData = data.filter(el => !el.sal || el.sal !== ImpactTextEnum.HIGH);
        break;

      default:
        surveyData = data; // ImpactTextEnum.HIGH
        break;
    }

    return surveyData;
  }

  removeUnMandatoryArtifacts(surveyData, vendor: Vendor) {
    const filteredSurvey = [];
    // update mandatory artifacts
    const isIncludes = (artifactType: string) => {
      let boolToReturn = false;
      artifactType = artifactType.toLowerCase();
      if (artifactType.includes('certifications')) {
        artifactType = artifactType.split('_').pop();
        boolToReturn = !!vendor.vendorDetails.standardsQualify.find(
          standard => standard.name === artifactType
        );
      } else {
        artifactType = artifactType.toUpperCase();
        boolToReturn = !!this.mandatoryArtifacts.find(artifact => {
          return artifact.isQualify && artifact.name === ArtifactsEnum[artifactType];
        });
      }

      return boolToReturn;
    };

    surveyData.forEach(el => {
      if (el.artifactsTag && isIncludes(el.artifactsTag)) {
        el.isArtifactsMandatory = true;
      }
      if (!el.artifactsTag || el.isArtifactsMandatory) {
        filteredSurvey.push(el);
      }
    });

    return filteredSurvey;
  }

  setTimeline() {
    const date: Date = this.getDueDate();
    this.vendor.vendorDetails.timeline.collectionDate = date.getTime();
    this.vendor.vendorDetails.timeline.nextReviewDate = date.getTime();
  }

  getDueDate() {
    return new Date(
      this.selectedDueDate.year,
      this.selectedDueDate.month - 1,
      this.selectedDueDate.day
    );
  }

  dateAsNgbDateStruct(days) {
    days = Number(days);
    const date = new Date();
    date.setDate(date.getDate() + days);

    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }
}
