import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'percent'})
export class PercentPipe implements PipeTransform {
  transform(value: number, toFixed = 0, args: string[]): any {
    return isNaN(value) ? null : value.toFixed(toFixed) + '%';
  }
}



