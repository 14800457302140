export class ImpactEnum {
  static LOW = 25;
  static MEDIUM = 50;
  static HIGH = 75;
  static VERY_HIGH = 100;
}

export class ProbabilityEnum {
  static LOW = 25;
  static MEDIUM = 50;
  static HIGH = 75;
  static VERY_HIGH = 100;
}

export class ImpactTextEnum {
  static LOW = 'low';
  static MEDIUM = 'medium';
  static HIGH = 'high';
  static VERY_HIGH = 'very high';
}
