import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  constructor() {}

  transform(value, numberOfChar, args?: string[]): any {
    const arr = [];
    for (const key in value) {
      if (key === 'minId') {
        continue;
      }
      if (value.hasOwnProperty(key)) {
        const val = value[key];
        const present =
          key.length > numberOfChar ? key.substring(0, numberOfChar).concat('...') : key;
        arr.push({
          key: key,
          value: val,
          present: present,
          minId: val.minId
        });
      }
    }
    return arr.sort((a, b) => (a.minId > b.minId ? 1 : a.minId < b.minId ? -1 : 0));
  }
}
