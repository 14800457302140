import { ProgressBarModule } from 'app/shared/progress-bar/progress-bar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThirdPartyProgressBarComponent } from './third-party-progress-bar.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    ProgressBarModule,
    SvgIconModule,
    NgbTooltipModule
  ],
  declarations: [ThirdPartyProgressBarComponent],
  exports: [ThirdPartyProgressBarComponent]
})
export class ThirdPartyProgressBarModule {

}
