import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { User } from '../../../models/user.model';
import { Observable } from 'rxjs';
import { RoleEnum } from '../enums/role.enum';
import { ShortRef } from 'models/agency.model';

@Injectable()
export class UserRoleGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('canActivate: canActivateChild was called');
    const url: string = state.url;
    return this.checkUserRole(url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('canActivateChild: canActivateChild was called');
    const url: string = state.url;
    return this.checkUserRole(url);
  }

  async checkUserRole(url?) {
    try {
      const user: User = await this.authService.getCurrentUser();
      if (user) {
        switch (user.role) {
          case RoleEnum.ADMIN:
          case RoleEnum.MSSP:
            return true;
          case RoleEnum.ENTITY_LEADER:
            if (this.hasIdInUrl(url, user.affiliateReference.states)) {
              return true;
            } else {
              console.log('checkUserRole: unauthenticated');
              await this.router.navigate(['/login']);
              return false;
            }
          case RoleEnum.SUB_ENTITY_LEADER:
            if (this.hasIdInUrl(url, user.affiliateReference.agencies)) {
              return true;
            } else {
              console.log('checkUserRole: unauthenticated');
              await this.router.navigate(['/login']);
              return false;
            }
          case RoleEnum.PARTICIPANT:
            if (this.hasIdInUrl(url, user.affiliateReference.agencies)) {
              return true;
            } else {
              console.log('checkUserRole: unauthenticated');
              await this.router.navigate(['/login']);
              return false;
            }
        }
      } else {
        await this.router.navigate(['/login']);
        return Promise.reject(false);
      }
    } catch (e) {
      console.log('UserRoleGuard - Error: ', e);
      await this.router.navigate(['/login']);
      return Promise.reject(false);
    }
  }

  hasIdInUrl(url: string, shortRefArray: ShortRef[]) {
    return !!shortRefArray.filter(a => url.includes(a.id)).length;
  }
}
