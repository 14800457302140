import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThirdPartyComponent } from './third-party.component';
import { EntityLeaderRoleGuard } from 'app/shared/guards/entity-leader-role.guard';
import { RiskManagementHeatmapComponent } from './risk-management-heatmap/risk-management-heatmap.component';
import { AllVendorListComponent } from './all-vendor-list/all-vendor-list.component';
import { ThirdPartyOverviewComponent } from './third-party-overview/third-party-overview.component';
import { VendorLeaderRoleGuard } from 'app/shared/guards/vendor-leader-role.guard';

const routes: Routes = [
  {
    path: ':entityId',
    canActivate: [VendorLeaderRoleGuard],
    component: ThirdPartyComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview'
      },
      {
        path: 'overview',
        canActivate: [EntityLeaderRoleGuard],
        component: ThirdPartyOverviewComponent
      },
      {
        path: 'heat-map',
        canActivate: [EntityLeaderRoleGuard],
        component: RiskManagementHeatmapComponent
      },
      {
        path: 'vendors',
        canActivate: [EntityLeaderRoleGuard],
        component: AllVendorListComponent
      },
      {
        path: 'gaps',
        loadChildren: 'app/remediation/remediation.module#RemediationModule'
      },
      {
        path: 'questionnaire',
        loadChildren: 'app/questionnaire/questionnaire.module#QuestionnaireModule'
      }
    ]
  }
];

export const ThirdPartyRoutes: ModuleWithProviders = RouterModule.forChild(routes);
