import { RevisionRef } from './entity-revision.model';
import { TableEnum } from '../app/shared/enums/table.enum';
import * as uuid from 'uuid';
import { StatisticUser } from './user.model';
import { CollectionStatusEnum } from 'app/shared/enums/collection-status.enum';
import { ImpactTextEnum } from 'app/shared/enums/impact.enum';
import { Qualification } from './entity.model';

export class Scores {
  total: number;
  target: number;
  collection: number;
  remediation: number;

  constructor(obj: any = {}) {
    this.total = obj.total || 0;
    this.target = obj.target || 0;
    this.collection = obj.collection || 0;
    this.remediation = obj.remediation || 0;
  }
}

export class QuestionSheet {
  id: string;
  name: string;
  completion: number;
  numberOfQuest: number;
  numberOfUsers?: number;
  sheet: any;
  docStackId: string;
  date: number;

  constructor(obj: any = {}) {
    this.id = obj.id || uuid();
    this.name = obj.name || null;
    this.completion = obj.completion || 0;
    this.numberOfQuest = obj.numberOfQuest || 0;
    this.sheet = obj.sheet || null;
    this.docStackId = obj.docStackId;
    this.date = obj.date || Date.now();
  }
}

export type QuestionSheetsType = string | QuestionSheet[];

export class Agency {
  id: string;
  name: string;
  surveyCore: SurveyCore;
  review: Review;
  details: AgencyDetails;
  questionSheets: QuestionSheetsType;
  flatQuestionSheet: FlatQuestionSheet[];
  completionByField: CompletionIfFields[];
  users: string[]; // userID
  revisions: RevisionRef[];
  createdBy: string;
  createdAt: number;
  tableName: TableEnum;
  jira: Jira;

  constructor(obj: any = {}) {
    this.id = obj.id || uuid();
    this.name = obj.name;
    this.surveyCore = obj.surveyCore || new SurveyCore();
    this.review = obj.review || new Review();
    this.details = obj.details || new AgencyDetails();
    this.users = obj.users || [];
    this.completionByField = obj.completionByField || [];
    this.questionSheets = obj.questionSheets || '';
    this.flatQuestionSheet = obj.flatQuestionSheet || [];
    this.revisions = obj.revisions || [];
    this.createdBy = obj.createdBy;
    this.jira = obj.jira || new Jira();
    this.createdAt = obj.createdAt || Date.now();
    this.tableName = TableEnum.AGENCIES;
  }
}

export class CompletionIfFields {
  name: string;
  completion: number;
  percentage: number;
  numberOfQuest: number;
  appearInSurveys: AppearInSurvey[];

  constructor(obj: any = {}) {
    this.name = obj.name || null;
    this.completion = obj.completion || 0;
    this.percentage = obj.percentage || 0;
    this.numberOfQuest = obj.numberOfQuest || 0;
    this.appearInSurveys = obj.appearInSurveys || [];
  }
}

export class Jira {
  host: string;
  email: string;
  apiToken: string;
  boardId: any;
  boardName?: string;
  inheritFromParent?: boolean;
  projectKey: any;
  issueType: any;
  isAuth: boolean;

  constructor(obj: any = {}) {
    this.host = obj.host;
    this.email = obj.email;
    this.apiToken = obj.apiToken;
    this.boardId = obj.boardId;
    this.boardName = obj.boardName;
    this.inheritFromParent = obj.inheritFromParent;
    this.projectKey = obj.projectKey;
    this.issueType = obj.issueType;
    this.isAuth = obj.isAuth || false;
  }
}

export class AppearInSurvey {
  name: string;
  numberOfQuest: number;
  numberOfUsers: number;

  constructor(obj: any = {}) {
    this.name = obj.name || null;
    this.numberOfQuest = obj.numberOfQuest || 0;
    this.numberOfUsers = obj.numberOfUsers || 0;
  }
}

export interface SurveyStatistic {
  numberOfAnswers: number;
  numberOfUserQuestions: number;
  numberOfQuestions: number;
  numberOfCantAnswer: number;
  numberOfYesAnswers: number;
  numberOfNoAnswers: number;
  numberOfRemediation: number;
  questionSheet: {
    id: string;
    name: string;
  };
  users: {
    [propName: string]: StatisticUser;
  };
}

type SurveyCoreChildren = [SurveyCore];

export class SurveyCore {
  id: any;
  name: string;
  questionId: string;
  question: Question;
  completion: number;
  todo: string;
  jiraId: string;
  score: number;
  label: string;
  description: string;
  priority: ImpactTextEnum;
  standards: string[];
  processArea: string;
  function: string;
  category: string;
  subCategory: string;
  overRideScore?: number;
  docStackId: string;
  target: number;
  isRemediation?: boolean;
  isPending?: boolean;
  statistic: SurveyStatistic;
  userIds: string[];
  children: SurveyCoreChildren;

  constructor(obj: any = {}) {
    this.id = obj.id || uuid();
    this.name = obj.name || null;
    this.questionId = obj.questionId;
    this.question = obj.question;
    this.completion = obj.completion || 0;
    this.todo = obj.todo || null;
    this.jiraId = obj.jiraId || null;
    this.score = obj.score || 0;
    this.label = obj.label;
    this.description = obj.description;
    this.priority = obj.priority;
    this.standards = obj.standards || [];
    this.processArea = obj.processArea;
    this.function = obj.function;
    this.category = obj.category;
    this.subCategory = obj.subCategory;
    this.overRideScore = obj.overRideScore;
    this.docStackId = obj.docStackId;
    this.target = obj.target;
    this.isRemediation = obj.isRemediation;
    this.isPending = obj.isPending;
    this.statistic = obj.statistic || {};
    this.userIds = obj.userIds || [];
    this.children = obj.children || [];
  }
}

export class Todo {
  id: string;
  content: string;
  comments: string[];
  isIgnore: false;
  ignoreExplain: null;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.content = obj.content || null;
    this.comments = obj.comments || [];
    this.isIgnore = obj.isIgnore || false;
    this.ignoreExplain = obj.ignoreExplain;
  }
}

export class TodoPresent extends Todo {
  openComment: boolean;
  newComment: string;
  taskComplete?: boolean;
  timeOut?: any;

  constructor(obj: any = {}) {
    super({});
    this.id = obj.id;
    this.content = obj.content || null;
    this.comments = obj.comments || [];
    this.isIgnore = obj.isIgnore || false;
    this.ignoreExplain = obj.ignoreExplain;
    this.openComment = false;
    this.newComment = obj.newComment;
    this.openComment = false;
    this.timeOut = obj.timeOut;
  }
}

export class Review {
  assets: Asset[];
  allTodos: TodosForFields[];
  riskCompliant: RiskCompliant;
  fieldsNames: string[];

  constructor(obj: any = {}) {
    this.assets = obj.assets || []; // assets: [{area: String, value: String, bullets: [String]}]
    this.allTodos = obj.todos || []; // new AllTodos[];
    this.riskCompliant = obj.riskCompliant || new RiskCompliant();
    this.fieldsNames = obj.fieldsNames || [];
  }
}

export class Asset {
  area: string;
  value: number;
  bullets?: string[];

  constructor(obj: any = {}) {
    this.area = obj.area || null;
    this.value = obj.value || null;
    this.bullets = obj.bullets || [];
  }
}

export class TodosForFields {
  name: string;
  todos: Todo[];
  todosArchaive: Todo[];

  constructor(obj: any = {}) {
    this.name = obj.name || null;
    this.todos = obj.todos || [];
    this.todosArchaive = obj.todosArchaive || [];
  }
}

export class AllTodos {
  name: string;
  todo: any;
  todosArchaive: any;

  constructor(obj: any = {}) {
    this.name = obj.name || null;
    this.todo = obj.todo || []; // todos: [{title: String, content: String, comments: [String]}],
    this.todosArchaive = obj.todosArchaive || []; // todosArchaive: [{title: String, content: String, comments: [String]}]
  }
}

export class RiskCompliant {
  risks: RiskComplCol;
  complaints: RiskComplCol;

  constructor(obj: any = {}) {
    this.risks = obj.risks;
    this.complaints = obj.complaints;
  }
}

export class RiskComplCol {
  title: string;
  content: string;

  constructor(obj: any = {}) {
    this.title = obj.title;
    this.content = obj.content;
  }
}

export class Reference {
  id: string;
  name: string;
  completion: number;
  compliance: number;
  stage: CollectionStatusEnum;
  date: number;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.name = obj.name;
    this.completion = obj.completion;
    this.compliance = obj.compliance;
    this.stage = obj.stage;
    this.date = obj.date || Date.now();
  }
}

export class Details {
  name: string;
  stateReference: any;
  reference: any;
  img: any;
  sheetsNames: any;

  constructor(obj: any = {}) {
    this.name = obj.name;
    this.stateReference = obj.stateReference;
    this.reference = obj.reference;
    this.img = obj.img;
    this.sheetsNames = obj.sheetsNames || [];
  }
}

export class CompletionByField {
  name: string;
  percentage: number;
  numberOfQuest: number;
  completion: number;
  appearInSurveys: any[];

  constructor(obj: any = {}) {
    this.name = obj.name;
    this.percentage = obj.percentage || 0;
    this.numberOfQuest = obj.numberOfQuest || 0;
    this.completion = obj.completion || 0;
    this.appearInSurveys = obj.appearInSurveys || []; // appearInSurveys: [{name: String, numberOfQuest: Number, numberOfUsers: Number}]
  }
}

export class QuestionSheets {
  name: string;
  numberOfQuest: number;
  completion: number;
  numberOfUsers: number;
  order: number;
  sheet: any;
  date: number;

  constructor(obj: any = {}) {
    this.name = obj.name;
    this.numberOfQuest = obj.numberOfQuest || 0;
    this.completion = obj.completion || 0;
    this.numberOfUsers = obj.numberOfUsers || 0;
    this.order = obj.order;
    this.sheet = obj.sheet;
    this.date = obj.date || Date.now();
  }
}

export class FlatQuestionSheet {
  id: string;
  name: string;
  score: number;
  result: any;
  question: any;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.name = obj.name;
    this.score = obj.score || 0;
    this.result = obj.result; // result: { rawScore: Number,  numberOfUsers: Number },
    this.question = obj.question; // question: {answers: [String], weight: Number, surveyNames: [String]}
  }
}

export class Denied {
  issue: {
    impact: 'high' | 'medium' | 'low';
    description: string;
  };
  rootCause: string;
  impact: string;
  recomendation: string;
  actionPlan: string;
  owner: 'string';

  constructor(obj: any = {}) {
    this.issue = obj.issue || { impact: 'high' };
    this.rootCause = obj.rootCause;
    this.impact = obj.impact;
    this.recomendation = obj.recomendation;
    this.actionPlan = obj.actionPlan;
    this.owner = obj.owner;
  }
}

export class Question {
  weight: any; // deprecated - moved to SecurityControl
  surveyNames: string[]; // deprecated
  sal: string; // deprecated - moved to SecurityControl as priority
  field: string; // deprecated - use processArea instead

  id: number; // questionId from csv
  name: string;
  title: string;
  answers: string[];
  jiraId: string;
  isIgnore: boolean;
  ignoreExplain: string;
  isCompleted: boolean;
  todo: string;
  selection: string;
  auditors: { userId: string; name: string }[];
  isApproved: boolean;
  auditComment: string;
  auditCheckboxes: Qualification[];
  tempSelection: string;
  isArtifactsMandatory: boolean;
  artifactsTag: string;
  processArea: string;
  function: string;
  category: string;
  subCategory: string;
  controlName: string;
  files: any;
  input: string;
  isTop20: boolean;
  description: string;
  denied?: Denied;
  isPending?: boolean;
  dueDate?: number;

  constructor(obj: any = {}) {
    this.weight = obj.weight;
    this.surveyNames = obj.surveyNames;
    this.sal = obj.sal;
    this.field = obj.field;

    this.id = obj.id;
    this.name = obj.name;
    this.title = obj.title;
    this.answers = obj.answers;
    this.jiraId = obj.jiraId;
    this.isIgnore = obj.isIgnore || false;
    this.ignoreExplain = obj.ignoreExplain;
    this.isCompleted = obj.isCompleted || false;
    this.todo = obj.todo;
    this.selection = obj.selection;
    this.auditors = obj.auditors;
    this.isApproved = obj.isApproved;
    this.auditComment = obj.auditComment;
    this.auditCheckboxes = obj.auditCheckboxes || defaultAuditCheckboxes();
    this.tempSelection = obj.tempSelection;
    this.isArtifactsMandatory = obj.isArtifactsMandatory || false;
    this.artifactsTag = obj.artifactsTag;
    this.processArea = obj.processArea;
    this.function = obj.function;
    this.category = obj.category;
    this.subCategory = obj.subCategory;
    this.controlName = obj.controlName;
    this.files = obj.files;
    this.input = obj.input;
    this.isTop20 = obj.isTop20;
    this.description = obj.description;
    this.denied = new Denied(obj.decline);
    this.isPending = obj.isPending;
    this.dueDate = obj.dueDate;
  }
}

function defaultAuditCheckboxes() {
  return ['Strong', 'Satisfactory', 'Needs Improvement', 'Weak'].map(name => ({
    name,
    isQualify: false,
  }));
}

export class Revisions {
  revisionId: string;
  revisionDate: number;

  constructor(obj: any = {}) {
    this.revisionId = obj.revisionId;
    this.revisionDate = obj.revisionDate || Date.now();
  }
}

export class AgencyDetails {
  stateReference: ShortRef;
  reference: AgencyReference;
  img: string;
  sheetsNames: string[];

  constructor(obj: any = {}) {
    this.stateReference = new ShortRef(obj.stateReference);
    this.reference = new AgencyReference(obj.reference);
    this.img = obj.img;
    this.sheetsNames = obj.sheetsNames;
  }
}

export class ShortRef {
  name: string;
  id: string;
  email?: string;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.name = obj.name;
    this.email = obj.email;
  }
}

interface ScoreMap {
  id: string;
  name: string;
  collection: number;
  isQualify: boolean;
  order: number;
  remediation: number;
  target?: number;
  total: number;
}

export interface ProcessAreaScoreMap extends ScoreMap {
  functionScoreMap: {
    [propName: string]: ScoreMap;
  };
}

export class AgencyReference {
  id: string;
  name: string;
  completion: number;
  compliance: number;
  stage: CollectionStatusEnum;
  date: number;
  scores: Scores;
  processAreaScoreMap: {
    [propName: string]: ProcessAreaScoreMap;
  };

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.name = obj.name;
    this.completion = obj.completion || 0;
    this.compliance = obj.compliance || 0;
    this.stage = obj.stage;
    this.date = obj.date || Date.now();
    this.scores = obj.scores || new Scores();
    this.processAreaScoreMap = obj.processAreaScoreMap || {};
  }
}
