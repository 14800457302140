import { Component, Input, OnInit } from '@angular/core';
import { StatusData } from 'models/status-data.model';

@Component({
  selector: 'cygov-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  @Input() inProcessCount: number;
  @Input() pendingCount: number;
  @Input() approvedCount: number;
  @Input() deniedCount: number;

  // Constants
  SVG_WIDTH = 485.03;
  SVG_HEIGHT = 124.08;
  SVG_VIEW_BOX = `0 0 ${this.SVG_WIDTH} ${this.SVG_HEIGHT}`;
  LINE_CURVE = 15;

  STATUS_WIDGET_WIDTH = 511.34;
  PER_UNIT_WIDTH = this.STATUS_WIDGET_WIDTH / 100;

  MIN_X = 0;
  MIN_Y = 50;
  DENIED_WIDTH_DIFF = 5.38;
  CORNER_RADIUS = 30;
  FONT_SIZE = 16;
  TEXT_FONT_SIZE = 14;
  WIDGET_BAR_HEIGHT = 30.76;
  TEXT_LINE_HEIGHT = 24.5;
  DEFAULT_COUNT = 18;
  TOTAL_COUNT = 100;
  DEFAULT_REMAINING_COUNT = this.TOTAL_COUNT - this.DEFAULT_COUNT * 4;

  totalCount: number = 0;
  statusesData: StatusData[] = [];

  constructor() { }

  ngOnInit() {
    this.calculateStatusWidgetValues();
  }

  private calculateStatusWidgetValues() {
    const inProcessStatusData = new StatusData();
    inProcessStatusData.GradientClass = 'gradient-inprocess';
    inProcessStatusData.Text = 'In process';
    inProcessStatusData.Count = this.inProcessCount;
    inProcessStatusData.OriginalCount = this.inProcessCount;
    this.statusesData.push(inProcessStatusData);

    const pendingStatusData = new StatusData();
    pendingStatusData.GradientClass = 'gradient-pending';
    pendingStatusData.Text = 'Pending';
    pendingStatusData.Count = this.pendingCount;
    pendingStatusData.OriginalCount = this.pendingCount;
    this.statusesData.push(pendingStatusData);

    const approvedStatusData = new StatusData();
    approvedStatusData.GradientClass = 'gradient-approved';
    approvedStatusData.Text = 'Approved';
    approvedStatusData.Count = this.approvedCount;
    approvedStatusData.OriginalCount = this.approvedCount;
    this.statusesData.push(approvedStatusData);

    const deniedStatusData = new StatusData();
    deniedStatusData.GradientClass = 'gradient-denied';
    deniedStatusData.Text = 'Denied';
    deniedStatusData.Count = this.deniedCount;
    deniedStatusData.OriginalCount = this.deniedCount;
    this.statusesData.push(deniedStatusData);

    this.statusesData.forEach(d => (this.totalCount += d.Count));

    this.normalizeData();

    this.setStatusesData();
  }

  private setStatusesData() {
    this.statusesData.forEach((statusData, i) => {
      this.calculateStatusData(statusData, i);
    });
  }

  calculateStatusData(statusData: StatusData, i: number) {
    statusData.Total = (statusData.Count / this.TOTAL_COUNT) * 100 * this.PER_UNIT_WIDTH;
    statusData.TextAddWidth = (this.FONT_SIZE * statusData.Count.toString().length) / 2;
    statusData.Width = statusData.Total - this.CORNER_RADIUS;

    if (i === 0) {
      statusData.StartX = this.MIN_X;
      statusData.CountTextX = statusData.StartX + statusData.Total / 2 - statusData.TextAddWidth;
    } else if (i < this.statusesData.length - 1) {
      statusData.StartX = this.statusesData[i - 1].StartX + this.statusesData[i - 1].Width;
      statusData.CountTextX = statusData.StartX + statusData.Total / 2;
    } else {
      statusData.StartX =
        this.statusesData[i - 1].StartX + this.statusesData[i - 1].Width + this.CORNER_RADIUS + this.DENIED_WIDTH_DIFF;
      statusData.CountTextX = statusData.StartX + statusData.Width / 2;
    }
    statusData.StartY = this.MIN_Y;
    statusData.CountTextY = this.MIN_Y + this.WIDGET_BAR_HEIGHT / 2 + this.FONT_SIZE / 4;
    statusData.Cx = statusData.CountTextX;
    if (i % 2 === 0) {
      statusData.Cy = this.MIN_Y;
      statusData.LineY2 = statusData.Cy - this.TEXT_LINE_HEIGHT;
      statusData.StatusTextY = this.MIN_Y - 29;
    } else {
      statusData.Cy = this.MIN_Y + this.WIDGET_BAR_HEIGHT;
      statusData.LineY2 = statusData.Cy + this.TEXT_LINE_HEIGHT;
      statusData.StatusTextY = 115.26;
    }
    statusData.LineX1 = statusData.Cx;
    statusData.LineY1 = statusData.Cy;
    statusData.LineX2 = statusData.Cx;
    statusData.StatusTextX = statusData.Cx;
    if (i !== this.statusesData.length - 1) {
      statusData.PathD = `M ${statusData.StartX}, ${statusData.StartY} l ${this.LINE_CURVE}, ${this.WIDGET_BAR_HEIGHT /
        2} -${this.LINE_CURVE}, ${this.WIDGET_BAR_HEIGHT / 2} ${statusData.Width},0 c ${this.CORNER_RADIUS}, 0 ${
        this.CORNER_RADIUS
        }, -${this.WIDGET_BAR_HEIGHT} 0, -${this.WIDGET_BAR_HEIGHT} l -${statusData.Width}, 0`;
    } else {
      // tslint:disable-next-line: max-line-length
      statusData.PathD = `M ${statusData.StartX}, ${statusData.StartY} l 0, ${this.WIDGET_BAR_HEIGHT} ${statusData.Width},0 c ${this.CORNER_RADIUS}, 0 ${this.CORNER_RADIUS}, -${this.WIDGET_BAR_HEIGHT} 0, -${this.WIDGET_BAR_HEIGHT} l -${statusData.Width}, 0`;
    }
  }

  /**
   * Normalizes our data.
   *
   * We get total count on scale of 100.
   *
   * 1- Give weightage to all count wrt DEFAULT_REMAINING_COUNT.
   * 2- Add DEFAULT_COUNT to all the values.
   */
  normalizeData() {
    this.statusesData.forEach(d => {
      if (d.Count !== 0) { d.Count = Math.floor((d.Count / this.totalCount) * this.DEFAULT_REMAINING_COUNT); }
      d.Count += this.DEFAULT_COUNT;
    });
  }
}
