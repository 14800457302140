import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityStandardsComponent } from './entity-standards.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { VendorService } from 'app/third-party/vendor.service';

@NgModule({
  declarations: [
    EntityStandardsComponent
  ],
  imports: [
    CommonModule,
    SvgIconModule,
  ],
  exports: [
    EntityStandardsComponent
  ],
  providers: [
    VendorService
  ]
})
export class EntityStandardsModule {
}
