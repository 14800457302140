import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
  selector: 'cygov-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent implements OnInit {
  @Input() assessmentLevel: number;
  @Input() preFill: string;
  @Input() postFill: string[];
  @Input() height: number;
  postFillStr: string;

  constructor() {
  }

  ngOnInit() {
    this.assessmentLevel = this.assessmentLevel ? this.assessmentLevel : 0;
    this.preFill = this.preFill || '#2E3240'; // '#979797';
    this.postFillStr = this.postFill && this.postFill.length > 1 ? this.postFill.join(',') : '#2684D6, #0c3152';
    this.height = this.height || 10;
  }

  barStyle() {
    return {
      'height': `${this.height}px`,
      'width': `${this.assessmentLevel}%`,
      'background': `linear-gradient(to left, ${this.postFillStr})`,
    }
  }

}
