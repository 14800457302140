import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgencyNameSortPipe } from './agency-name-sort.pipe';
import { KeysPipe } from './keys.pipe';
import { PercentPipe } from './percent.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { ReversePipe } from './reverse.pipe';
import { CapitalizeFirstPipe } from './capitalize-first.pipe';
import { CutStrPipe } from './cut-str.pipe';
import { CapAndRemoveDashesPipe } from './cap-and-dashes.pipe';
import { NormalizeScorePipe } from './normalize-score.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    EllipsisPipe,
    AgencyNameSortPipe,
    KeysPipe,
    PercentPipe,
    ReversePipe,
    CapitalizeFirstPipe,
    CutStrPipe,
    CapAndRemoveDashesPipe,
    NormalizeScorePipe
  ],
  providers: [
    EllipsisPipe,
    AgencyNameSortPipe,
    KeysPipe,
    PercentPipe,
    ReversePipe,
    CapitalizeFirstPipe
  ],
  exports: [
    EllipsisPipe,
    AgencyNameSortPipe,
    KeysPipe,
    PercentPipe,
    ReversePipe,
    CapitalizeFirstPipe,
    CutStrPipe,
    CapAndRemoveDashesPipe,
    NormalizeScorePipe
  ]
})
export class PipesModule {}
