import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Vendor } from 'models/vendor.model';
import { IKeyValue } from 'models/ikey-value';
import { FileService } from 'app/shared/file.service';
import { AuthService } from 'app/shared/auth.service';
import {
  ADD_VENDOR,
  GET_VENDOR,
  UPDATE_VENDOR,
  REMOVE_VENDOR,
  GET_VENDOR_LIST,
  USER_GET_VENDOR_LIST,
} from 'app/app.paths';
import { DocTypeEnum } from 'app/shared/enums/docType.enum';
import { User } from 'models/user.model';
import { RoleEnum } from 'app/shared/enums/role.enum';
import { DataService } from 'app/shared/data.service';

@Injectable()
export class VendorService {
  private static promCache: any = {};
  private static vendorMap: IKeyValue<any> = {}; // Vendor | Vendor[] types

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private dataService: DataService
  ) {}

  async addVendor(vendor: Vendor, dataFile?: any): Promise<Vendor> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    let data: any;
    try {
      // TODO: handel vendor survey
      data = dataFile ? FileService.mapDataToSurveyCore(dataFile) : null;
    } catch (e) {
      return Promise.reject({ type: 'dataFile', element: e });
    }
    vendor.surveyCore = data.surveyCore;
    vendor.questionSheets = data.questionSheets;
    vendor.completionByField = data.completionByField;
    const entityId = vendor.details.stateReference.id;

    try {
      const body = { vendor, entityId };
      vendor = <any>await this.http.post(ADD_VENDOR, body).toPromise();
      // update cache map
      VendorService.vendorMap[vendor.id] = vendor;

      return vendor;
    } catch (e) {
      console.log(`addVendor - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getVendor(vendorId: string, fromCache = true): Promise<Vendor> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    if (!vendorId) {
      console.log(`getVendor - Error: vendorId is required`);
      return Promise.reject(`getVendor - Error: vendorId is required`);
    }

    // Data cache mechanism
    let vendor: Vendor = null;
    if (fromCache && vendorId && VendorService.vendorMap[vendorId]) {
      vendor = VendorService.vendorMap[vendorId];
    }

    if (fromCache && vendor) {
      return Promise.resolve(vendor);
    }

    // Promises cache mechanism
    let queryProm: Promise<Vendor>;
    if (fromCache && VendorService.promCache && VendorService.promCache[vendorId]) {
      console.log('VendorService.getVendor() returned from cache');
      queryProm = VendorService.promCache[vendorId];
    } else {
      const options = { params: { vendorId } };
      queryProm = <Promise<Vendor>>this.http.get(GET_VENDOR, options).toPromise();
      VendorService.promCache[vendorId] = queryProm;
      console.log('VendorService.getVendor returned (not from cache)');
    }

    try {
      const agency: Vendor = await queryProm;
      vendor = new Vendor(agency);
      vendor.scores.total = agency.details.reference.compliance || 0;
      vendor.scores.collection = agency.details.reference.completion || 0;
      vendor.docType = DocTypeEnum.VENDOR;

      // update cache map
      VendorService.vendorMap[vendor.id] = vendor;

      return vendor;
    } catch (e) {
      console.log(`getVendor - Error: `, e);
      return Promise.reject(e);
    }
  }

  async updateVendor(vendor: Vendor, refreshScore = true): Promise<Vendor> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    try {
      const body = { vendor, subEntityId: vendor.id, refreshScore };
      let updatedEntity: any = await this.http.post(UPDATE_VENDOR, body).toPromise();
      if (updatedEntity) {
        updatedEntity = new Vendor(updatedEntity);
      }

      // update cache map
      VendorService.vendorMap[updatedEntity.id] = updatedEntity;

      return updatedEntity ? updatedEntity : null;
    } catch (e) {
      console.log(`updateVendor - Error: `, e);
      return Promise.reject(e);
    }
  }

  async removeVendor(vendorId: string, entityId: string): Promise<void> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const body = { vendorId, entityId };
      await this.http.post(REMOVE_VENDOR, body).toPromise();
      // update cache map
      delete VendorService.vendorMap[vendorId];
    } catch (e) {
      console.log(`removeVendor - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getVendorList(entityId: string, fromCache = true): Promise<Vendor[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    if (!entityId) {
      console.log(`getVendorList - Error: entityId is required`);
      return Promise.reject(`getVendorList - Error: entityId is required`);
    }

    // Data cache mechanism
    let vendorList: Vendor[] = null;
    if (fromCache && entityId && VendorService.vendorMap[entityId]) {
      vendorList = VendorService.vendorMap[entityId];
    }

    if (fromCache && vendorList) {
      return Promise.resolve(vendorList);
    }

    // Promises cache mechanism
    let queryProm: Promise<Vendor[]>;
    if (fromCache && VendorService.promCache && VendorService.promCache[entityId]) {
      console.log('VendorService.getVendorList() returned from cache');
      queryProm = VendorService.promCache[entityId];
    } else {
      const options = { params: { entityId } };
      queryProm = <Promise<Vendor[]>>this.http.get(GET_VENDOR_LIST, options).toPromise();
      // queryProm = <Promise<Vendor[]>>this.http.get('http://www.mocky.io/v2/5d7defb52f00006641fedfa4', options).toPromise();
      VendorService.promCache[entityId] = queryProm;
      console.log('VendorService.getVendorList returned (not from cache)');
    }

    try {
      vendorList = await queryProm;
      // update cache map
      vendorList.forEach(vendor => (VendorService.vendorMap[vendor.id] = vendor));

      return vendorList;
    } catch (e) {
      console.log(`getVendorList - Error: `, e);
      return Promise.reject(e);
    }
  }

  participantFromEntity(vendor: Vendor): { name: string; email: string; email2: string } {
    const withoutSpacialChars = vendor.name.replace(/[^a-zA-Z0-9] /g, '');
    const name = `${vendor.name} Participant`;
    const email = `${vendor.id}@example.com`;
    const email2 = `${withoutSpacialChars.split(' ').join('-')}-Participant-${
      vendor.id
    }@example.com`;
    return { name, email, email2 };
  }

  async getVendorParticipant(vendorId: string) {
    let vendorParticipant: User = null;
    const vendor = vendorId ? await this.getVendor(vendorId) : null;
    if (vendor) {
      const { name, email, email2 } = this.participantFromEntity(vendor);
      vendorParticipant = await this.authService.getUserByEmail(email);
      vendorParticipant = vendorParticipant
        ? vendorParticipant
        : await this.authService.getUserByEmail(email2);
      if (!vendorParticipant) {
        const newUser: User = new User({
          name,
          email,
          role: RoleEnum.PARTICIPANT,
          questionSheet: {
            name: vendor.details.sheetsNames.find(sheetName => sheetName.includes('Technical')), // 'Technical Questionnaire'
          },
          affiliateReference: {
            states: [{...vendor.details.stateReference}],
            agencies: [{ id: vendor.id, name: vendor.name }],
          },
        });
        vendorParticipant = await this.dataService.createNewUser(newUser);
      }
    }

    return vendorParticipant;
  }

  async getVendorUsers(vendorId: string): Promise<User[]> {
    const options = { params: { vendorId } };
    return this.http.get(USER_GET_VENDOR_LIST, options).toPromise() as Promise<User[]>;
  }
}
