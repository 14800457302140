import { Qualification, Entity } from 'models/entity.model';
import { ArtifactsEnum } from 'app/shared/enums/artifacts.enum';
import { StandardEnum } from 'app/shared/enums/standard.enum';
import * as Models from './generator.model';
import { User } from 'models/user.model';
import { RoleEnum } from 'app/shared/enums/role.enum';
import { QuestionSheet } from 'models/agency.model';
import { Vendor, Questionnaire } from 'models/vendor.model';
import { ImpactTextEnum } from 'app/shared/enums/impact.enum';
import { UtilsService } from 'app/shared/utils.service';
import { FileService } from 'app/shared/file.service';
import { AccessLevelEnum } from 'app/shared/enums/accessLevel.enum';
import { CollectionStatusEnum } from 'app/shared/enums/collection-status.enum';
import { StatusEnum } from 'app/shared/enums/status.enum';
import { FrequencyCheckEnum } from 'app/shared/enums/frequency-check.enum';

export default class GeneratorHelpers {
  public static artifactsStub(): Qualification[] {
    return Object.entries(ArtifactsEnum)
      .filter(([key]) => !key.includes('COMPLIANCE'))
      .map(([_, name]) => ({ name, isQualify: false }));
  }

  public static certificationsStub(): Qualification[] {
    return [
      StandardEnum.PCI_DSS,
      StandardEnum.FFIEC,
      StandardEnum.GDPR,
      StandardEnum.HIPAA,
      StandardEnum.ISO_27001,
      StandardEnum.FERPA
    ].map(name => ({ name, isQualify: false }));
  }

  public static subEntityMapper(entity: Entity): (value: Models.SubEntity) => Entity {
    return subEntity => new Entity({
      name: subEntity.name,
      id: subEntity.id,
      stage: subEntity.stage,
      details: {
        reference: {
          id: subEntity.id,
          name: subEntity.name,
          date: Date.now(),
          stage: subEntity.stage,
        },
        stateReference: {
          id: entity.id,
          name: entity.name,
        },
      },
      ...subEntity.survey.data
    });
  }

  private static calcVendorImpact(selectedSurvey: ImpactTextEnum, artifacts: Qualification[]): number {
    let minPoints: number;
    let maxPoints: number;
    const pointForEachArtifact = 32 / (artifacts.length + 1);

    switch (selectedSurvey) {
      case ImpactTextEnum.LOW:
        minPoints = 1
        maxPoints = 33;
        break;

      case ImpactTextEnum.MEDIUM:
        minPoints = 34;
        maxPoints = 66;
        break;

      case ImpactTextEnum.HIGH:
        minPoints = 67;
        maxPoints = 100;
        break;
    }
    const checkedArtifacts = artifacts.filter(artifact => artifact.isQualify).length;
    const unCheckedArtifacts = artifacts.length - checkedArtifacts
    minPoints = checkedArtifacts ? minPoints + (checkedArtifacts * pointForEachArtifact) : minPoints;
    maxPoints = unCheckedArtifacts ? maxPoints - (pointForEachArtifact * unCheckedArtifacts) : maxPoints;

    return UtilsService.getRandomNumber(minPoints, maxPoints, 0);
  }

  private static buildQualifications(qualifications: Qualification[]): Qualification[] {
    return qualifications
      .filter(({ isQualify }) => isQualify)
      .map(({ name }) => ({ name, isQualify: false }))
  }

  private static vendorSurveyImpactFilter({ impact }: Models.Vendor): (value: { sal: ImpactTextEnum }) => boolean {
    switch (impact) {
      case ImpactTextEnum.LOW:
        return ({ sal }) => !sal || sal === ImpactTextEnum.LOW;
      case ImpactTextEnum.MEDIUM:
        return ({ sal }) => !sal || sal !== ImpactTextEnum.HIGH;
      default: return (() => true);
    }
  }

  private static vendorSurveyFilter({ certifications, artifacts }: Models.Vendor): ({ artifactsTag }) => boolean {
    return ({ artifactsTag }) => {
      if (!artifactsTag) {
        return true;
      }
      if (artifactsTag.includes('certifications')) {
        const artifactType = artifactsTag.toLowerCase().split('_').pop();
        return certifications.some(({ isQualify, name }) => isQualify && name === artifactType);
      }
      return artifacts.some(({ isQualify, name }) => isQualify && name === ArtifactsEnum[artifactsTag.toUpperCase()]);
    }
  }

  private static vendorSurveyMandatoryArtifactMapper(vendor: any): any {
    return {
      ...vendor,
      isArtifactsMandatory: !!vendor.artifactsTag
    }
  }

  private static buildVendorQuestionnaires(survey: any[], standardsQualify: Qualification[]) {
    const tagIsStandard = (tag: string) => standardsQualify.some(({ name, isQualify }) => isQualify && name === tag);
    const result = survey.reduce((cur, { processArea }) => {
      const tag = processArea.toLowerCase();
      if (tagIsStandard(tag) && !cur.has(tag)) {
        cur.set(tag, new Questionnaire({ tag }));
      }
      return cur;
    }, new Map());
    return [...result.values()];
  }


  public static vendorMapper(entity: Models.Entity, surveyData: any[]): (value: Models.Vendor) => Vendor {
    // TODO: replace vendorDetails data with defaultSettings if possible
    return vendor => {
      const survey = surveyData
        .filter(GeneratorHelpers.vendorSurveyImpactFilter(vendor))
        .filter(GeneratorHelpers.vendorSurveyFilter(vendor))
        .map(GeneratorHelpers.vendorSurveyMandatoryArtifactMapper);
      const impact = GeneratorHelpers.calcVendorImpact(vendor.impact, vendor.artifacts);
      const standardsQualify = GeneratorHelpers.buildQualifications(vendor.certifications);
      const questionnaries = GeneratorHelpers.buildVendorQuestionnaires(survey, vendor.certifications);
      const { surveyCore, questionSheets, completionByField } = FileService.mapDataToSurveyCore(survey);
      return new Vendor({
        name: vendor.name,
        id: vendor.id,
        details: {
          reference: {
            id: vendor.id,
            name: vendor.name,
            date: Date.now(),
          },
          stateReference: {
            id: entity.id,
            name: entity.name,
          },
        },
        vendorDetails: {
          impact,
          standardsQualify,
          questionnaries,
          primaryPoc: vendor.poc,
          accessLevel: AccessLevelEnum.PHYSICAL,
          collectionStatus: CollectionStatusEnum.HAS_NOT_BEGUN,
          criticalGaps: 0,
          externalScan: 0,
          financialRisk: 0,
          probability: 100,
          status: StatusEnum.IN_PROCESS,
          timeline: {
            frequency: FrequencyCheckEnum.ANNUAL,
            initiationDate: Date.now(),
            collectionDate: vendor.dueDate,
            nextReviewDate: vendor.dueDate,
          }
        },
        surveyCore,
        questionSheets,
        completionByField,
      });
    };
  }

  public static userMapper(entity: Models.Entity, subEntities: Models.SubEntity[]): (value: Models.User) => User {
    return user => new User({
      ...user,
      details: {
        name: user.name,
        email: user.email,
      },
      affiliateReference: {
        states: [{
          name: entity.name,
          id: entity.id,
        }],
        agencies: [{
          id: user.subEntityId,
          name: subEntities.find(({ id }) => id === user.subEntityId).name,
        }]
      },
      role: RoleEnum.PARTICIPANT,
      questionSheet: new QuestionSheet({ name: user.questionSheetName }),
    });
  }


}

