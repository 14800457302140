export class DocTypeEnum {
  static ENTITY = 'entity';
  static SUB_ENTITY = 'subEntity';
  static VENDOR = 'vendor';
  static USER = 'user';
  static STANDARD = 'standard';
  static CONTROL = 'control';
  static QUESTION = 'question';
  static ANSWER_RESULT = 'answerResult';
  static CONTROL_RESULT = 'controlResult';
  static SURVEY = 'survey';
}
