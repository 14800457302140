import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'agencyNameSort'})
export class AgencyNameSortPipe implements PipeTransform {

  constructor() {
  }

  transform(value, args: string[]): any {

    function compere(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }

    value.sort(compere);
    return value;
  }
}
