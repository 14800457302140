import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SecurityControl } from 'models/security-control.model';
import { ImpactTextEnum } from 'app/shared/enums/impact.enum';
import { Question } from 'models/agency.model';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { CrbVendorConstant } from 'app/third-party/add-vendor/add-crb-vendor-modal/crb-vendor.constant';
import { ArtifactsEnum } from '../enums/artifacts.enum';

@Component({
  selector: 'cygov-new-task-modal',
  templateUrl: './new-task-modal.component.html',
  styleUrls: ['./new-task-modal.component.scss'],
})
export class NewTaskModalComponent implements OnInit {
  @Input() newTask: any;
  @Output() modalResult = new EventEmitter<any>();
  priorityOpt = Object.keys(ImpactTextEnum).map(key => ImpactTextEnum[key]);
  selectedSubEntityId: string;
  isCrbAdjustments = UtilsService.isCRB;
  isDefined = UtilsService.isDefined;

  constructor(private route: ActivatedRoute, public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.newTask = this.newTask || new SecurityControl();
    this.newTask.relatedRequirements =
      this.newTask.relatedRequirements || this.getRelatedRequirements();
  }

  getRelatedRequirements() {
    let relatedRequirements: any;
    if (this.isCrbAdjustments) {
      relatedRequirements = CrbVendorConstant.artifactsQualification();
    } else {
      relatedRequirements = Object.entries(ArtifactsEnum)
        .filter(([key]) => !key.includes('COMPLIANCE'))
        .map(([_, name]) => ({ name, isQualify: false }));
    }

    return relatedRequirements;
  }

  subEntityChanged(subEntityId: string) {
    this.newTask.agencyId = subEntityId;
  }

  impactChanged(opt) {
    this.newTask.priority = opt.toLowerCase();
  }

  emitEntity(newTask: any) {
    const subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
    newTask.stateId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    newTask.agencyId = newTask.agencyId ? newTask.agencyId : subEntityId ? newTask.agencyId : null;
    newTask.isRemediation = true;
    newTask.todo = newTask.description;
    newTask.question = new Question({
      id: Date.now(),
      name: newTask.todo,
      title: newTask.todo,
      todo: newTask.todo,
      description: newTask.todo,
      answers: ['not applicable', 'yes', 'no'],
      controlName: newTask.name,
    });
    this.modalResult.emit(newTask);
  }
}
