import { Injectable, OnDestroy } from '@angular/core';
import { User } from '../../models/user.model';
import {
  USER_GET_BY_EMAIL,
  USER_GET_ME,
  USER_RESEND_CODE,
  USER_RESET,
  USER_SEND_INVITE,
  USER_SEND_REMINDER,
  USER_UPDATE,
  USER_LOGIN,
  TWO_FACTOR_URL,
  USER_FORGET,
} from '../app.paths';
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { RoleEnum } from './enums/role.enum';
import { Auth } from 'aws-amplify';
import { UserChallengeEnum } from './enums/userChallenge.enum';
import { AWSEnum } from './enums/aws.enum';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'app/shared/utils.service';

export interface Credentials {
  email: string;
  password: string;
  remember?: boolean;
}

const CYGOV_STORAGE = 'cygov_storage';
const COGNITO_USER = 'isCognitoUser';
const AD_USER = 'isADUser';
const ACCESS_TOKEN = 'ad-token';

@Injectable()
export class AuthService implements OnDestroy {
  storage: Storage = localStorage;
  _user: User;
  authUser = null; // This is for cognito user
  tokenHTTP: HttpClient; // We need a separate instance of httpClient to bypass the interceptors.

  constructor(
    private http: HttpClient,
    private router: Router,
    private handler: HttpBackend,
    private toastr: ToastrService
  ) {
    this.tokenHTTP = new HttpClient(this.handler);
    if (!!sessionStorage.getItem(CYGOV_STORAGE)) {
      this.storage = sessionStorage;
    } else {
      this.storage = localStorage;
    }
  }

  get user(): User {
    return this._user;
  }

  set user(user: User) {
    if (!!user) {
      user.affiliateReference.states = user.affiliateReference.states ? user.affiliateReference.states : [user.affiliateReference.state];
      user.affiliateReference.agencies = user.affiliateReference.agencies ? user.affiliateReference.agencies : [user.affiliateReference.agency];
      this._user = user;
    } else {
      this._user = null;
    }
  }

  async getCurrentSession() {
    if (!this.isCognitoUser) {
      return this.currentCode && this.currentSession;
    }
    try {
      let session: string;
      if (this.isADUser) {
        session = this.accessToken;
      } else {
        const currSession = await Auth.currentSession();
        session = currSession.getAccessToken().getJwtToken();
      }
      return session;
    } catch (e) {
      console.log('get Current Session', e);
      return null;
    }
  }

  get isCognitoUser(): boolean {
    try {
      const isCognitoUser = JSON.parse(this.storage.getItem(COGNITO_USER));
      return isCognitoUser ? isCognitoUser : null;
    } catch (_) {
      return null;
    }
  }

  setIsCognitoUser(value: boolean) {
    this.storage.setItem(COGNITO_USER, String(value));
  }

  get isADUser(): boolean {
    try {
      const isADUser = JSON.parse(this.storage.getItem(AD_USER).toString());
      return isADUser ? isADUser : null;
    } catch (_) {
      return null;
    }
  }

  setIsADUser(value: boolean) {
    this.storage.setItem(AD_USER, String(value));
  }

  get accessToken(): string {
    try {
      const accessToken = this.storage.getItem(ACCESS_TOKEN);
      return accessToken ? accessToken : null;
    } catch (_) {
      return null;
    }
  }

  setAccessToken(value: string) {
    this.storage.setItem(ACCESS_TOKEN, value);
  }

  get currentAuthUser() {
    return this.authUser;
  }

  get isStorageLocal() {
    return this.storage === localStorage;
  }

  async isAuthenticated() {
    if (await this.getCurrentSession()) {
      return true;
    }
    return false;
  }

  get currentCode() {
    try {
      const curStorage = JSON.parse(this.storage.getItem(CYGOV_STORAGE));
      return curStorage ? curStorage.code : null;
    } catch (_) {
      return null;
    }
  }

  get currentSession() {
    try {
      const curStorage = JSON.parse(this.storage.getItem(CYGOV_STORAGE));
      return curStorage ? curStorage.session : null;
    } catch (_) {
      return null;
    }
  }

  /**
   * Checks for the session in the local storage.
   *
   * Returns false and logout if not present.
   * Otherwise returns true.
   *
   * @returns {boolean} session exists or not.
   */
  async isSessionExpired(redirect = true) {
    if (await this.getCurrentSession()) {
      return false;
    }
    if (redirect) {
      this.logOut()
        .catch(e => {
          console.log('isSessionExpired: Error: ', e);
          const message = UtilsService.msgFromError(e);
          this.toastr.error(message);
        })
        .finally(() => this.router.navigate(['/login']));
    }
    return true;
  }

  async setFirstPassword(password: string, phone: String) {
    console.log('In setFirstPassword');
    try {
      const currentUser = this.authUser;
      const loggedUser = await Auth.completeNewPassword(currentUser, password, {
        phone_number: phone,
      });
      this.authUser = loggedUser;
      return this.authUser;
    } catch (e) {
      console.log(`twoFactorPhase - Error: `, e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  async signIn(credentials: Credentials) {
    const { email: username, password } = credentials;
    if (environment.useCognitoAuth) {
      this.setIsCognitoUser(true);
      try {
        this.authUser = await Auth.signIn(username, password);
        console.log('CURRENT AUTH USER', this.authUser);
        return this.authUser.challengeName;
      } catch (e) {
        console.log('signIn - Error: ', e);
        return Promise.reject(e);
      }
    } else {
      // TODO: UPDATE when migrate to cognito.
      this.setIsCognitoUser(false);
      return this.signInDB(credentials);
    }
  }

  async signInDB(credentials: Credentials) {
    const body = { email: credentials.email, password: credentials.password };
    this.storage = credentials.remember ? localStorage : sessionStorage;
    try {
      const user: User = <User>await this.http.post(USER_LOGIN, body).toPromise();
      if (user) {
        this.storage.setItem(
          CYGOV_STORAGE,
          JSON.stringify({
            session: user.token.session,
            code: null,
          })
        );
      }
      return UserChallengeEnum.SMS_MFA;
    } catch (e) {
      console.log(`login - Error: `, e);
      return Promise.reject(e);
    }
  }

  async loginCodeConfirm(codeFromUser?: string) {
    try {
      const authUser = this.authUser;
      if (authUser) {
        // @ts-ignore
        this.authUser = await Auth.confirmSignIn(authUser, codeFromUser, UserChallengeEnum.SMS_MFA);
      }
    } catch (e) {
      console.log(`twoFactorPhase - Error: `, e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  async twoFactorPhase(codeFromUser?: string): Promise<User> {
    console.log('Inside TwoFactorPhase');
    const session = this.currentSession;
    const code = codeFromUser || this.currentCode;
    try {
      const user: User = <User>await this.http.post(TWO_FACTOR_URL, { session, code }).toPromise();
      this.storage.setItem(
        CYGOV_STORAGE,
        JSON.stringify({
          code: user.token.key,
          session: user.token.session,
        })
      );
      this.user = user;
      return this.user;
    } catch (e) {
      console.log(`twoFactorPhase - Error: `, e);
      this.storage.setItem(
        CYGOV_STORAGE,
        JSON.stringify({
          session: session,
        })
      );
      return Promise.reject(e);
    }
  }

  async fetchUser(redirect = true) {
    if (await this.isSessionExpired(redirect)) {
      return;
    }
    try {
      this.user = <User>await this.http.get(USER_GET_ME).toPromise();
    } catch (e) {
      console.log('fetch User', e);
      this.logOut()
        .catch(err => console.log('fetchUser: Error: ', err))
        .finally(() => this.router.navigate(['/login']));
    }
  }

  async logOut(): Promise<void> {
    try {
      this.user = null;
      sessionStorage.removeItem(CYGOV_STORAGE);
      localStorage.removeItem(CYGOV_STORAGE);
      localStorage.removeItem(COGNITO_USER);
      localStorage.removeItem(AD_USER);
      localStorage.removeItem(ACCESS_TOKEN);
      await Auth.signOut();
      return;
    } catch (e) {
      console.log(`logOut - Error: `, e);
      return Promise.reject(e);
    }
  }

  async sendInviteEmail(userId: string, agencyId: string) {
    if (await this.isSessionExpired()) {
      return;
    }
    try {
      return this.http.post(USER_SEND_INVITE, { userId, agencyId }).toPromise();
    } catch (e) {
      console.log(`sendInviteEmail - Error: `, e);
      return Promise.reject(e);
    }
  }

  async sendReminderEmail(userId: string, agencyId: string, toAll: boolean = false): Promise<any> {
    if (await this.isSessionExpired()) {
      return;
    }
    try {
      return this.http.post(USER_SEND_REMINDER, { userId, agencyId, toAll }).toPromise();
    } catch (e) {
      console.log(`sendInviteEmail - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getUserByEmail(userEmail: string): Promise<User> {
    if (await this.isSessionExpired()) {
      return;
    }

    try {
      return <User>await this.http.get(`${USER_GET_BY_EMAIL}?userEmail=${userEmail}`).toPromise();
    } catch (e) {
      return Promise.resolve(null);
    }
  }

  sendTokenAgain(session) {
    return this.http.get(`${USER_RESEND_CODE}?session=${session}`).toPromise();
  }

  async sendRestartEmail(userEmail) {
    try {
      this.setIsCognitoUser(true);
      return await Auth.forgotPassword(userEmail);
    } catch (e) {
      this.setIsCognitoUser(false);
      return this.http.post(USER_FORGET, { userEmail }).toPromise();
    }
  }

  resetPassword(token: string, password: string, phone?: string) {
    return this.http.post(USER_RESET, { token, password, phone }).toPromise();
  }

  getCurrentUserSync(): User {
    return this.user ? this.user : null;
  }

  async getCurrentUser(redirect = true) {
    if (!this.user) {
      await this.fetchUser(redirect);
    }
    return this.user;
  }

  async updateCurrentUser(user: User, updateAgency: boolean = false): Promise<User> {
    if (await this.isSessionExpired()) {
      return;
    }
    try {
      const body = { user, updateAgency };
      this.user = await this.http.post<User>(USER_UPDATE, body).toPromise();
      return this.user;
    } catch (e) {
      console.log(`updateCurrentUser - Error: `, e);
      return Promise.reject(e);
    }
  }

  async resendCode(username: string) {
    try {
      return await Auth.resendSignUp(username);
    } catch (e) {
      return Promise.reject(e.message);
    }
  }

  /**
   * Fetch the authentication tokens for the user.
   *
   * @param token string The token  returned by SAML Auth.
   */
  getOAtuhTokens(token: string) {
    const params = new HttpParams({
      fromObject: {
        grant_type: AWSEnum.GRANT_TYPE,
        client_id: AWSEnum.CLIENT_ID,
        redirect_uri: AWSEnum.REDIRECT_URI,
        code: token,
      },
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': AWSEnum.CONTENT_TYPE_HEADER,
        Authorization:
          AWSEnum.AUTHORIZATION_TYPE + ' ' + btoa(AWSEnum.CLIENT_ID + ':' + AWSEnum.CLIENT_SECRET),
      }),
    };

    try {
      const result = this.tokenHTTP
        .post(AWSEnum.TOKEN_VERIFICATION_ENDPOINT, params, httpOptions)
        .toPromise();
      return result;
    } catch (e) {
      console.log(`OAuth get tokens - Error: `, e);
      return Promise.reject(e);
    }
  }

  getEntityIdFromCurrentUser(): string {
    return this.user && this.user.entityPermissionList ? this.user.entityPermissionList[0] : null;
  }

  async navigateByRole(): Promise<void> {
    try {
      const user: User = await this.getCurrentUser();
      if (!user || !user.role) {
        console.log(`User role is required!`);
        await this.router.navigate(['']);
        return Promise.reject();
      }

      let entityId = null;
      let subEntityId = null;
      switch (user.role) {
        case RoleEnum.ADMIN:
          this.router.navigate(['clients']);
          break;
        case RoleEnum.MSSP:
          this.router.navigate(['clients']);
          break;
        case RoleEnum.ENTITY_LEADER:
          entityId = user.affiliateReference.states[0].id;
          this.router.navigate([`first-party/${entityId}/upperdeck`]);
          break;
        case RoleEnum.SUB_ENTITY_LEADER:
          entityId = user.affiliateReference.states[0].id;
          subEntityId = user.affiliateReference.agencies[0].id;
          this.router.navigate([`first-party/${entityId}/multi-entity`, subEntityId]);
          break;
        case RoleEnum.PARTICIPANT:
          entityId = user.affiliateReference.states[0].id;
          subEntityId = user.affiliateReference.agencies[0].id;
          this.router.navigate([`first-party/${entityId}/questionnaire`, subEntityId], {
            queryParams: { userId: user.id },
          });
          break;
        case RoleEnum.VENDOR_LEADER:
          entityId = this.user.affiliateReference.states[0].id;
          subEntityId = this.user.affiliateReference.agencies[0].id;
          this.router.navigate([`vendor/${entityId}/questionnaire`], {
            queryParams: { vendorId: subEntityId },
          });
          break;

        default:
          this.router.navigate(['**']);
      }
    } catch (e) {
      console.log(`navigateByRole - Error: `, e);
      await this.router.navigate(['']);
      return Promise.reject(e);
    }
  }

  async hasPermission(minRole: RoleEnum, entityId?: string, subEntityId?: string) {
    try {
      const user: User = await this.getCurrentUser();
      let hasPermission: boolean = false;
      if (user) {
        switch (minRole) {
          case RoleEnum.ADMIN:
            hasPermission = user.role === RoleEnum.ADMIN;
            break;
          case RoleEnum.MSSP:
            hasPermission = user.role === RoleEnum.ADMIN || user.role === RoleEnum.MSSP;
            break;
          case RoleEnum.ENTITY_LEADER:
            hasPermission =
              user.role === RoleEnum.ADMIN ||
              user.role === RoleEnum.MSSP ||
              (user.role === RoleEnum.ENTITY_LEADER &&
                entityId &&
                !!user.affiliateReference.states.filter(a => a.id === entityId).length);
            break;
          case RoleEnum.SUB_ENTITY_LEADER:
            hasPermission =
              user.role === RoleEnum.ADMIN ||
              user.role === RoleEnum.MSSP ||
              user.role === RoleEnum.ENTITY_LEADER ||
              (user.role === RoleEnum.SUB_ENTITY_LEADER &&
                entityId &&
                subEntityId &&
                !!user.affiliateReference.states.filter(a => a.id === entityId).length &&
                !!user.affiliateReference.agencies.filter(a => a.id === subEntityId).length);
            break;
          case RoleEnum.PARTICIPANT:
            hasPermission =
              user.role === RoleEnum.ADMIN ||
              user.role === RoleEnum.MSSP ||
              user.role === RoleEnum.ENTITY_LEADER ||
              ((user.role === RoleEnum.SUB_ENTITY_LEADER || user.role === RoleEnum.PARTICIPANT) &&
                entityId &&
                subEntityId &&
                !!user.affiliateReference.states.filter(a => a.id === entityId).length &&
                !!user.affiliateReference.agencies.filter(a => a.id === subEntityId).length);
            break;
          case RoleEnum.VENDOR_LEADER:
            hasPermission =
              user.role === RoleEnum.ADMIN ||
              user.role === RoleEnum.MSSP ||
              user.role === RoleEnum.ENTITY_LEADER ||
              (user.role === RoleEnum.VENDOR_LEADER &&
                entityId &&
                subEntityId &&
                !!user.affiliateReference.states.filter(a => a.id === entityId).length &&
                !!user.affiliateReference.agencies.filter(a => a.id === subEntityId).length);
            break;
        }
      } else {
        console.log('No user is found');
      }

      return Promise.resolve(hasPermission);
    } catch (e) {
      console.log('hasPermission - Error: ', e);
      return Promise.reject(e);
    }
  }

  isAdmin(): boolean {
    return this.user && this.user.role === RoleEnum.ADMIN;
  }

  clearCache(): void {
    this.user = null;
  }

  ngOnDestroy(): void {
    this.clearCache();
  }
}
