import { VendorService } from '../../vendor.service';
import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Vendor } from 'models/vendor.model';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'app/shared/confirmation-modal/confirmation-modal.component';
import { StatusEnum } from 'app/shared/enums/status.enum';
import { UtilsService } from 'app/shared/utils.service';
import { ReportsService } from 'app/shared/reports.service';

@Component({
  selector: 'cygov-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.scss'],
})
export class VendorDetailComponent implements OnInit {
  @Input() vendor: Vendor;
  @Input() groupList: string[];
  @Output() closeVendorDetail = new EventEmitter<any>();
  @Output() removeVendor = new EventEmitter<string>();
  isLoading: boolean = true;
  showCloseDetailAnimation: boolean = false;
  statusEnum = StatusEnum;

  constructor(
    private vendorService: VendorService,
    private reportsService: ReportsService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.getVendor();
  }

  closeDetail() {
    this.showCloseDetailAnimation = true;
    setTimeout(() => {
      this.closeVendorDetail.emit();
    }, 1500);
  }

  async getVendor() {
    try {
      this.vendor = await this.vendorService.getVendor(this.vendor.id);
      this.isLoading = false;
    } catch (e) {
      console.log('Error While calling Vendor Api', e);
    }
  }

  async updateVendor() {
    try {
      this.vendor = await this.vendorService.updateVendor(this.vendor, false);
      this.toastr.success(`${this.vendor.name} updated successfully`);
    } catch (e) {
      this.toastr.error(`Failed to update ${this.vendor.name}`);
    }
  }

  openConfirmationModal() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      windowClass: 'confirmation-modal',
    });
    modalRef.componentInstance.message = `Are you sure you want to approved ${this.vendor.name}?`;

    modalRef.componentInstance.modalResult.subscribe((event: boolean) => {
      if (event) {
        this.saveStatus(StatusEnum.APPROVED);
      }
      modalRef.close();
    });
  }

  saveStatus(status: StatusEnum): void {
    this.statusChanged(status);
    this.updateVendor();
  }

  statusChanged(status: StatusEnum): void {
    this.vendor.vendorDetails.status = status;
    if (status === StatusEnum.APPROVED) {
      this.vendor.vendorDetails.approvalDate = Date.now();
    } else {
      this.vendor.vendorDetails.approvalDate = null;
    }
  }

  async deleteVendor() {
    try {
      await this.vendorService.removeVendor(this.vendor.id, this.vendor.details.stateReference.id);
      this.toastr.success(`${this.vendor.name} deleted successfully`);
      this.removeVendor.emit(this.vendor.id);
      this.closeDetail();
    } catch (e) {
      this.toastr.error(`Failed to delete ${this.vendor.name}`);
    }
  }

  downloadVendorReport(event: any, vendor: Vendor) {
    UtilsService.stopBubbling(event);
    this.toastr.info('The report will be generated in a couple of seconds.', null, {
      timeOut: 10000,
      tapToDismiss: false,
      progressBar: true,
      progressAnimation: 'increasing',
    });
    const year = new Date().getFullYear();
    this.reportsService
      .generateVendorReport(vendor.id)
      .then(data => {
        this.toastr.clear();
        saveAs(data, `${vendor.name} Due Diligence Memo ${year}.docx`);
      })
      .catch(() => {
        this.toastr.clear();
        this.toastr.info("Can't build the report!");
      });
  }
}
