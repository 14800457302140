import { Routes, RouterModule } from '@angular/router';
import { EntityListComponent } from './entity-list/entity-list.component';
import { GeneratorComponent } from './generator/generator.component';
import { SubEntityListComponent } from './sub-entity-list/sub-entity-list.component';
import { ModuleWithProviders } from '@angular/core';
import { UserListComponent } from './user-list/user-list.component';
import { AdminRoleGuard } from 'app/shared/guards/admin-role.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'entity-list'
  },
  {
    path: 'entity-list',
    canActivate: [AdminRoleGuard],
    component: EntityListComponent
  },
  {
    path: ':entityId/sub-entity-list',
    canActivate: [AdminRoleGuard],
    component: SubEntityListComponent
  },
  {
    path: ':entityId/:subEntityId/user-list',
    canActivate: [AdminRoleGuard],
    component: UserListComponent
  },
  {
    path: 'generator',
    canActivate: [AdminRoleGuard],
    component: GeneratorComponent
  },
];

export const AdminRoutes: ModuleWithProviders = RouterModule.forChild(routes);
