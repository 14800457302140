import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { User } from '../../../models/user.model';
import { Observable } from 'rxjs';
import { RoleEnum } from '../enums/role.enum';
import { ShortRef } from 'models/agency.model';

@Injectable()
export class SubEntityLeaderRoleGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('canActivate: canActivateChild was called');
    const url: string = state.url;
    return this.checkUserRole(url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('canActivateChild: canActivateChild was called');
    const url: string = state.url;
    return this.checkUserRole(url);
  }

  async checkUserRole(url?: string) {
    try {
      const user: User = await this.authService.getCurrentUser();
      if (
        user &&
        (user.role === RoleEnum.ADMIN ||
          user.role === RoleEnum.MSSP ||
          user.role === RoleEnum.ENTITY_LEADER ||
          (user.role === RoleEnum.SUB_ENTITY_LEADER &&
            this.hasIdInUrl(url, user.affiliateReference.states) &&
            this.hasIdInUrl(url, user.affiliateReference.agencies)))
      ) {
        return true;
      } else {
        console.log('checkUserRole - SubEntity Leader: Unauthenticated');
        await this.router.navigate(['/login']);
        return Promise.reject(false);
      }
    } catch (e) {
      console.log('UserRoleGuard - Error: ', e);
      await this.router.navigate(['/login']);
      return Promise.reject(false);
    }
  }

  hasIdInUrl(url: string, shortRefArray: ShortRef[]) {
    return !!shortRefArray.filter(a => url.includes(a.id)).length;
  }
}
