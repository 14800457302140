import { environment } from '../environments/environment';

const API = `${environment.backendUrl}/api`;

const LOGGEDIN = API.concat('/loggedIn');
const AUTH = API.concat('/auth');
const COGNITO = API.concat('/cognito');

const SIMPLE = LOGGEDIN.concat('/simple');
const ADMIN = LOGGEDIN.concat('/admin');
const STATE_LEADER = LOGGEDIN.concat('/state_leader');
const AGENCY_LEADER = LOGGEDIN.concat('/agency_leader');
const JIRA = LOGGEDIN.concat('/jira');
const GENERATOR = LOGGEDIN.concat('/generator');

export const GET_FILE = LOGGEDIN.concat('/getFile');

export const STATE_ICON = LOGGEDIN.concat('/stateIcon');

export const USER_REGISTER = AUTH.concat('/register');
export const USER_LOGIN = AUTH.concat('/login');
export const USER_LOGOUT = AUTH.concat('/logOut');
export const USER_FORGET = AUTH.concat('/forget');
export const USER_RESET = AUTH.concat('/reset');
export const TWO_FACTOR_URL = AUTH.concat('/2fa');
export const USER_RESEND_CODE = AUTH.concat('/reSendCode');

const USER = '/user';
export const USER_GET_ME = LOGGEDIN.concat(USER.concat('/me'));
export const USER_GET = LOGGEDIN.concat(USER.concat('/getUser'));
export const USER_GET_LIST = LOGGEDIN.concat(USER.concat('/getUserList'));
export const USER_GET_VENDOR_LIST = LOGGEDIN.concat(USER.concat('/getVendorUsers'));

export const USER_GET_CURRENT = LOGGEDIN.concat(USER.concat('/getCurrentUser'));
export const USER_UPLOAD_FILE = SIMPLE.concat(USER.concat('/upload'));
export const USER_DOWNLOAD_FILE = SIMPLE.concat(USER.concat('/download'));
export const USER_GET_BY_EMAIL = SIMPLE.concat(USER.concat('/getByEmail'));
export const USER_UPDATE = SIMPLE.concat(USER.concat('/update'));

export const UPLOAD_ARTIFACT = LOGGEDIN.concat('/uploadArtifact');
export const DOWNLOAD_ARTIFACT = LOGGEDIN.concat('/downloadArtifact');
export const DELETE_ARTIFACT = LOGGEDIN.concat('/deleteArtifact');

export const UPLOAD_STATE_LOGO = LOGGEDIN.concat('/uploadStateLogo');

export const USER_GETALL_BYAGENCYID = AGENCY_LEADER.concat(USER.concat('/getAll'));
export const USER_ADD = AGENCY_LEADER.concat(USER.concat('/add'));
export const USER_ADD_DB = COGNITO.concat('/add');
export const USER_ADD_COGNITO = AGENCY_LEADER.concat(USER.concat('/addCognito'));
export const USER_REMOVE = AGENCY_LEADER.concat(USER.concat('/remove'));
export const USER_SEND_INVITE = AGENCY_LEADER.concat(USER.concat('/sendInviteToMail'));
export const USER_SEND_REMINDER = AGENCY_LEADER.concat(USER.concat('/sendIReminderToMail'));
export const USER_DOWNLOAD_AGENCY_DATA = AGENCY_LEADER.concat(USER.concat('/downloadAGENCYData'));

const AGENCY = '/agency';
export const AGENCY_GET_AGENCY = AGENCY_LEADER.concat(AGENCY.concat('/getAgency'));
export const AGENCY_UPDATE = AGENCY_LEADER.concat(AGENCY.concat('/updateAgency'));
export const AGENCY_UPDATE_REVIEW = AGENCY_LEADER.concat(
  AGENCY.concat('/updateAgencyReviewFromFile')
);
export const AGENCY_UPDATE_DATE = AGENCY_LEADER.concat(AGENCY.concat('/agencyUpdateDate'));
export const UPDATE_TODOS = AGENCY_LEADER.concat(AGENCY.concat('/updateTodos'));

export const AGENCY_ADD = STATE_LEADER.concat(AGENCY.concat('/add'));
export const AGENCY_REMOVEBYID = STATE_LEADER.concat(AGENCY.concat('/remove'));
const STATE = '/state';

export const AGENCY_GETALL = STATE_LEADER.concat(STATE.concat('/getAllAgencies'));
export const STATE_GET = STATE_LEADER.concat(STATE.concat('/get'));
export const STATE_UPDATE = STATE_LEADER.concat(STATE.concat('/update'));

export const STATE_ADD = ADMIN.concat(STATE.concat('/add'));
export const STATE_GETALL = ADMIN.concat(STATE.concat('/getAll'));
export const STATE_REMOVE = ADMIN.concat(STATE.concat('/remove'));

export const GENERATE_FAKE_DATA = ADMIN.concat(STATE.concat('/generateFakeData'));

// agency revision
export const ADD_AGENCY_REVISION = AGENCY_LEADER.concat(AGENCY.concat('/addAgencyRevision'));
export const GET_REVISION_BY_ID = AGENCY_LEADER.concat(AGENCY.concat('/getAgencyRevisionById'));
export const GET_LATEST_REVISION_AGENCY = AGENCY_LEADER.concat(
  AGENCY.concat('/getLatestAgencyRevision')
);
export const GET_ALL_AGENCY_REVISIONS = AGENCY_LEADER.concat(
  AGENCY.concat('/getAllAgencyRevision')
);

// questionnaire
export const GET_QUESTIONNAIRE = AGENCY_LEADER.concat(AGENCY.concat('/questionnaire'));

// binary-edge services
const BINARY_EDGE = LOGGEDIN.concat('/binary-edge');
export const BINARY_EDGE_DATALEAKS = BINARY_EDGE.concat('/dataleaks');
export const BINARY_EDGE_DATALEAKS_INFO = (leakId: string) =>
  BINARY_EDGE.concat(`/dataleaks/${leakId}`);
export const BINARY_EDGE_SCORE = BINARY_EDGE.concat('/score');
export const BINARY_EDGE_CVE = BINARY_EDGE.concat('/cve');
export const BINARY_EDGE_DOMAINS = BINARY_EDGE.concat('/domains');
export const BINARY_EDGE_HOST = BINARY_EDGE.concat('/host');
export const BINARY_EDGE_SENSORS = BINARY_EDGE.concat('/sensors');
export const BINARY_EDGE_JOBS = BINARY_EDGE.concat('/jobs');
export const BINARY_EDGE_JOB_REPLAY = (jobId: string) =>
  BINARY_EDGE.concat(`/jobs/${jobId}/replay`);
export const BINARY_EDGE_JOB_REVOKE = (jobId: string) =>
  BINARY_EDGE.concat(`/jobs/${jobId}/revoke`);

// tenable services
const TENABLE = LOGGEDIN.concat('/tenable');
export const TENABLE_ANALYSIS = TENABLE.concat('/analysis');
export const TENABLE_SCAN_RESULT = TENABLE.concat('/scanResult');

// Rapid7 services
const RAPID7 = LOGGEDIN.concat('/rapid7');
export const RAPID7_ANALYSIS = RAPID7.concat('/analysis');
export const RAPID7_SITES = RAPID7.concat('/sites');
export const RAPID7_SCAN_RESULT = RAPID7.concat('/scan-result');
export const RAPID7_SCAN_RESULT_DETAIL = RAPID7.concat('/scan-result/detail');

// jira
export const JIRA_IS_AUTH_VALID = JIRA.concat('/isAuthValid');
export const JIRA_SAVE_DETAILS = JIRA.concat('/saveJiraDetails');
export const JIRA_GET_TASK = JIRA.concat('/getTask');
export const JIRA_UPDATE_TASK = JIRA.concat('/updateTask');
export const JIRA_EXPORT = JIRA.concat('/export');
export const JIRA_WEBHOOK = JIRA.concat('/webhooks');
export const JIRA_GET_BOARD = JIRA.concat('/getBoard');

// Third Party Services
const THIRD_PARTY = LOGGEDIN.concat('/thirdParty');
export const ADD_VENDOR = THIRD_PARTY.concat('/addVendor');
export const GET_VENDOR = THIRD_PARTY.concat('/getVendor');
export const UPDATE_VENDOR = THIRD_PARTY.concat('/updateVendor');
export const REMOVE_VENDOR = THIRD_PARTY.concat('/removeVendor');
export const GET_VENDOR_LIST = THIRD_PARTY.concat('/getVendorList');

// Report Services
const REPORT = LOGGEDIN.concat('/report');
export const DOWNLOAD_BOARD_REPORT = REPORT.concat('/board/download');
export const DOWNLOAD_OVERALL_REPORT = STATE_LEADER.concat('/report/overall/download');
export const DOWNLOAD_CRB_REPORT = AGENCY_LEADER.concat('/report/crb/download');
export const DOWNLOAD_CSV_REPORT = AGENCY_LEADER.concat('/report/crb/csv');
export const DOWNLOAD_MPL_REPORT = STATE_LEADER.concat('/report/crb/mpl');
export const DOWNLOAD_VENDOR_REPORT = STATE_LEADER.concat('/report/crb/vendor');

// Generator
export const GENERATOR_USERS = (amount: number) => GENERATOR.concat(`/users/${amount}`);
export const GENERATOR_BULK_CREATE = GENERATOR.concat('/bulkCreate');
