import { Component, Input, OnInit } from '@angular/core';
import { Alert, Entity } from '../../../models/entity.model';
import { UtilsService } from '../utils.service';
import { IKeyValue } from '../../../models/ikey-value';

interface AlertModal extends Alert {
  open: boolean;
  hidden: boolean;
}

@Component({
  selector: 'cygov-critical-alerts',
  templateUrl: './critical-alerts.component.html',
  styleUrls: ['./critical-alerts.component.scss']
})
export class CriticalAlertsComponent implements OnInit {
  @Input() entity: Entity;
  alertMap: IKeyValue<AlertModal[]>;

  constructor() {}

  ngOnInit() {
    if (this.entity && this.entity.alerts && this.entity.alerts.length) {
      const alertMap = {};

      // sort alerts by priority
      const sortedAlerts = UtilsService.sortByProp(this.entity.alerts, 'priority');

      // format all alerts
      sortedAlerts.forEach(alert => {
        alertMap[alert.type] = alertMap[alert.type] || [];
        alertMap[alert.type].push({
          content: alert.content,
          open: false,
          hidden: false
        });
      });

      // cut only 3 items of each alert type
      this.alertMap = {};
      Object.keys(alertMap).forEach(type => {
        this.alertMap[type] = alertMap[type].slice(0, 3);
      });
    }
  }

  togglePanel(event, sectionKey, alertToOpen) {
    const openAction = !alertToOpen.open;
    const currentTarget = event.currentTarget;
    console.log(openAction);
    this.alertMap[sectionKey].forEach(alert => {
      if (openAction) {
        // toggle - open
        if (alert.content === alertToOpen.content) {
          currentTarget.classList.add('zero-height');
          currentTarget.classList.remove('show');
          setTimeout(() => {
            currentTarget.classList.add('open');
          }, 0);
          alert.open = true;
        } else {
          alert.open = false;
          alert.hidden = true;
        }
      } else {
        // toggle - close
        currentTarget.classList.remove('open');
        currentTarget.classList.remove('zero-height');
        currentTarget.classList.add('show');
        alert.open = false;
        alert.hidden = false;
      }
    });
  }

  closePanel(sectionKey, alert) {
    this.alertMap[sectionKey].forEach(alertItem => {
      if (alert.content === alertItem.content) {
        alert.open = false;
      } else {
        alert.hidden = false;
      }
    });
  }
}
