import { ShortRef, Scores } from './agency.model';
import { Entity, Qualification } from './entity.model';
import { StatusEnum } from 'app/shared/enums/status.enum';
import { AccessLevelEnum } from 'app/shared/enums/accessLevel.enum';
import { S3File } from './file.model';
import { CollectionStatusEnum } from 'app/shared/enums/collection-status.enum';

export class Vendor extends Entity {
  /** from Agency model */
  // id: string;
  // name: string;
  // surveyCore: SurveyCore;                   // deprecated
  // review: Review;                           // deprecated
  // details: AgencyDetails;                   // deprecated
  // questionSheets: QuestionSheet[];          // deprecated
  // flatQuestionSheet: FlatQuestionSheet[];   // deprecated
  // completionByField: CompletionIfFields[];  // deprecated
  // users: string[];
  // revisions: RevisionRef[];
  // createdAt: number;
  // createdBy: string;
  // tableName: TableEnum;
  // jira: Jira;
  // logo: string;
  // scores: Scores;

  vendorDetails: VendorDetails;
  isVendor: boolean;

  constructor(obj: any = {}) {
    super(obj);
    this.vendorDetails = obj.vendorDetails || new VendorDetails();
    this.isVendor = obj.isVendor || true;
  }
}

export class VendorDetails {
  impact: number;
  externalScan: number;
  financialRisk: number;
  criticalGaps: number;
  standardsQualify: Qualification[];
  impactsQualify: Qualification[];
  status: StatusEnum;
  probability: number;
  location: string;
  size: string;
  accessLevel: AccessLevelEnum;
  collectionStatus: CollectionStatusEnum;
  timeline: Timeline;
  approvalDate: number;
  questionnaires: Questionnaire[];
  artifacts: Artifacts;
  intelligence: Intelligence;
  primaryPoc: ShortRef;
  stage: StatusEnum;
  privacyData: boolean;
  domain: string;
  activeScan: boolean;
  group: string;

  constructor(obj: any = {}) {
    this.impact = obj.impact;
    this.externalScan = obj.externalScan || 0;
    this.financialRisk = obj.financialRisk || 0;
    this.criticalGaps = obj.criticalGaps || 0;
    this.standardsQualify = obj.standardsQualify || [];
    this.impactsQualify = obj.impactsQualify || [];
    this.status = obj.status || StatusEnum.IN_PROCESS;
    this.probability = obj.probability || 100;
    this.location = obj.location;
    this.size = obj.size;
    this.accessLevel = obj.accessLevel;
    this.collectionStatus = obj.collectionStatus || CollectionStatusEnum.HAS_NOT_BEGUN;
    this.timeline = obj.timeline || new Timeline();
    this.approvalDate = obj.approvalDate;
    this.questionnaires = obj.questionnaires;
    this.artifacts = obj.artifacts;
    this.intelligence = obj.intelligence;
    this.primaryPoc = obj.primaryPoc || new ShortRef();
    this.stage = obj.stage;
    this.privacyData = obj.privacyData;
    this.domain = obj.domain;
    this.activeScan = obj.activeScan || true;
    this.group = obj.group;
  }
}

export class Timeline {
  initiationDate: number;
  collectionDate: number;
  nextReviewDate: number;
  frequency: string;

  constructor(obj: any = {}) {
    this.initiationDate = obj.initiationDate || Date.now();
    this.collectionDate = obj.collectionDate;
    this.nextReviewDate = obj.nextReviewDate;
    this.frequency = obj.frequency;
  }
}

export class Questionnaire {
  tag: string;
  status: Status;
  criticalGaps: string[];
  allGaps: string;

  constructor(obj: any = {}) {
    this.tag = obj.tag;
    this.status = obj.status;
    this.criticalGaps = obj.criticalGaps;
    this.allGaps = obj.allGaps;
  }
}

export class Status {
  totalQuestions: number;
  completedQuestions: number;

  isCompleted() {
    return this.totalQuestions === this.completedQuestions;
  }
}

export class Artifacts {
  documents: S3File[];
  scans: S3File[];
  insurances: S3File[];
  certifications: S3File[];
}

export class Intelligence {
  breaches: Breaches[];
  externalThreats: ExternalThreats[];
  others: InternalTasks[];

  constructor(obj: any = {}) {
    this.breaches = obj.breaches || [];
    this.externalThreats = obj.externalThreats || [];
    this.others = obj.others || [];
  }
}

export class Document {
  tag: string;
  name: string;
  type: string;
  modifiedDate: number;
  url: string;
}

export class Scan {
  type: string;
  date: number;
  auditor: string;
  reportFile: string;
  url: string;
}

export class Insurance {
  provider: string;
  type: string;
  coverage: string;
  supportingDocument: string;
  date: number;
  url: string;
}

export class Certifications {
  name: string;
  date: number;
  auditor: string;
  expirationDate: number;
  supportingDocument: string;
  url: string;
}

export class Breaches {
  date: number;
  name: string;
  description: string;
  leaks: number;
  data: string[];
  status: string;
}

export class ExternalThreats {
  source: string;
  findings: string[];
  riskScore: string;
  details: ThreatDetails[];
  status: string;
}

export class ThreatDetails {
  ip: string;
  port: number;
  cve: string;
  cvss: number;
}

export class InternalTasks {
  name: string;
  label: string;
  severity: string;
  description: string;
  status: string;
}
