import { VendorService } from './vendor.service';
import { Component, OnInit } from '@angular/core';
import { Vendor } from '../../models/vendor.model';
import { UtilsService } from '../shared/utils.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RoleEnum } from 'app/shared/enums/role.enum';
import { AuthService } from 'app/shared/auth.service';

@Component({
  selector: 'cygov-third-party',
  templateUrl: './third-party.component.html',
  styleUrls: ['./third-party.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'third-party-class',
  },
})
export class ThirdPartyComponent implements OnInit {
  isLoading: boolean = true;
  expandHeatMap: boolean = false;
  vendors: Vendor[];
  sortBy: string;
  filterBy: string;
  searchText: string;

  constructor(
    private authService: AuthService,
    private vendorService: VendorService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    if (await this.authService.hasPermission(RoleEnum.ENTITY_LEADER, entityId)) {
      this.getVendorList(entityId);
    }
  }

  async getVendorList(entityId: string) {
    try {
      this.vendors = await this.vendorService.getVendorList(entityId);
      this.isLoading = false;
      const url = this.router.url.slice(1, this.router.url.length);
      if (entityId && url.indexOf('/') === -1) {
        this.router.navigate([`third-party/${entityId}/overview`]);
      }
    } catch (e) {
      console.log('Error While calling Vendor Api', e);
    }
  }
  openExtendedHeatMap(value: boolean) {
    this.expandHeatMap = value;
  }
}
