import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { STATE_ICON } from '../../app.paths';

/** Don't use this service - use only in the directives */

@Injectable()
export class SvgIconService {
  private _clientBaseUrl = '/assets/svg';
  private iconMapper = {};

  constructor(private http: HttpClient) {
  }

  set baseUrl(value) {
    this._clientBaseUrl += `/${value}`;
  }

  public async getSvgByName(svgName: string): Promise<any> {
    let promise: Promise<any>;
    if (this.iconMapper[svgName]) {
      promise = this.iconMapper[svgName];
    } else {
      promise = this.http.get(`${this._clientBaseUrl}/${svgName}.svg`, {responseType: 'text'}).toPromise();
      this.iconMapper[svgName] = promise;
    }
    // svg result
    return await promise;
  }

  public getSvgFromServerByName(stateName: string): Promise<any> {
    let promise: Promise<any>;
    if (this.iconMapper[stateName]) {
      promise = this.iconMapper[stateName];
    } else {
      const options: any = { responseType: 'text' };
      promise = this.http.get(STATE_ICON, options).toPromise();
      this.iconMapper[stateName] = promise;
    }
    // svg result
    return promise;
  }
}
