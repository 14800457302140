import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { FileUploadCallback } from '../../generator.model';
import { GeneratorService } from '../../generator.service';

@Component({
  selector: 'cygov-generator-exist-entity-settings',
  templateUrl: './exist-entity-settings.component.html',
  styleUrls: ['./exist-entity-settings.component.scss'],
})
export class ExistEntitySettingsComponent implements OnInit {
  constructor(public service: GeneratorService) {}

  ngOnInit() {}

  selectEntity({ target }) {
    this.service.setSelectedEntity(target.value);
  }
}
