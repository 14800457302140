import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { SvgIconService } from './svg-icon.service';
import { LoggerService } from '../logger/logger.service';

@Directive({
  selector: '[cygovEntitySvgIcon]'
})
export class EntitySvgIconDirective implements OnInit, OnChanges, OnDestroy {
  @Input() stateName: string;
  @Input() stopPropagation: boolean;
  @Input() svgUrl: string = '';

  constructor(private element: ElementRef,
              private svgIconService: SvgIconService) {
  }

  ngOnInit(): void {
    if (this.stopPropagation) {
      this.element.nativeElement.addEventListener('click', (event) => {
        event.stopPropagation();
      });
    }
  }

  ngOnDestroy(): void {
    this.element.nativeElement.removeEventListener('click', () => {
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentValue: string = changes.stateName.currentValue;
    if (this.svgUrl) {
      this.svgIconService.baseUrl = this.svgUrl;
    }
    this.handleSvgCall(currentValue);
  }

  private async handleSvgCall(currentValue) {
    try {
      // result = svg object
      this.element.nativeElement.innerHTML = await this.svgIconService.getSvgFromServerByName(currentValue);
    } catch (e) {
      LoggerService.error(`handleSvgCall: Failed to get svg content. `, e);
    }
  }

}
