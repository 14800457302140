import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CygovLoaderComponent } from './cygov-loader.component';
import { CygovLoaderDirective } from './cygov-loader.directive';

@NgModule({
  declarations: [
    CygovLoaderComponent,
    CygovLoaderDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CygovLoaderComponent,
    CygovLoaderDirective
  ],
  entryComponents: [
    CygovLoaderComponent,
  ]
})
export class CygovLoaderModule { }
