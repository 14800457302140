import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cygov-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() items: any;
  @Input() placeholder: string;
  @Input() ngModelValue: any;
  @Input() multiple: boolean;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() groupBy: string;
  @Input() clearable: boolean = true;
  @Input() searchable: boolean = true;
  @Input() disabled: boolean = false;
  @Input() customTemplate: boolean = false;
  @Input() labelKeys: string[] = [];
  @Input() optionKeys: string[] = [];
  @Input() searchFn = null;
  @Output() ngModelValueChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
