import { Component, OnInit, Input } from '@angular/core';
import { Artifacts } from 'models/vendor.model';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { S3File } from 'models/file.model';
import JSZip from 'jszip';
import { FileService } from 'app/shared/file.service';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'cygov-artifacts',
  templateUrl: './artifacts.component.html',
  styleUrls: ['./artifacts.component.scss'],
})
export class ArtifactsComponent implements OnInit {
  @Input() vendorArtifacts: Artifacts;

  keys = ['Documents', 'Scans', 'Insurance', 'Certifications'];

  headElements = ['Document Tag', 'Name', 'Type', 'Modified date'];

  // headElementsScan = ['Type', 'Date', 'Auditor', 'Report', ''];

  // headElementsInsurance = ['Provider', 'Type', 'Coverage', 'Supporting Documents', 'Date', ''];

  // headElementsCertification = ['Name', 'Date', 'Auditor/Provider', 'Expiration Date', 'Supporting Documents', ''];

  showDoc = false;
  isShow: boolean = true;
  isShowScan: boolean = false;
  isShowInsurance: boolean = false;
  isShowCertification: boolean = false;
  isCrbAdjustments = UtilsService.isCRB;

  constructor(private fileService: FileService, private toastr: ToastrService) {}

  ngOnInit() {}

  toggleContent() {
    this.isShow = !this.isShow;
  }

  toggleContentScan() {
    this.isShowScan = !this.isShowScan;
  }

  toggleContentInsurance() {
    this.isShowInsurance = !this.isShowInsurance;
  }

  toggleContentCertification() {
    this.isShowCertification = !this.isShowCertification;
  }

  openDock() {
    this.showDoc = true;
  }
  closeDock() {
    this.showDoc = false;
  }

  downloadFile(file: S3File) {
    try {
      this.toastr.info('Preparing file...');
      this.fileService.downloadArtifact(file).subscribe(fileStream => {
        saveAs(fileStream, `${file.name}.zip`);
      });
    } catch (e) {
      console.log(e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  async downloadAll(key: string) {
    try {
      if (this.vendorArtifacts[key].length !== 0) {
        let zip = new JSZip();
        this.toastr.info('Preparing file...', null, {
          timeOut: 60000,
          tapToDismiss: false,
          progressBar: true,
          progressAnimation: 'increasing',
        });

        for (const file of this.vendorArtifacts[key]) {
          zip = await this.fileService.getExistingZip(file, zip);
        }

        const zipAllFiles = await zip.generateAsync({ type: 'blob' });
        setTimeout(() => {
          saveAs(zipAllFiles, `${key}-artifacts`);
          this.toastr.clear();
        });
      }
    } catch (e) {
      console.error(e);
      this.toastr.clear();
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }
}
