import { UtilsService } from 'app/shared/utils.service';

const loremData: string[] = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  'Integer convallis lacus ac mollis faucibus.',
  'Fusce non lacus at augue condimentum sollicitudin quis non ante.',
  'Donec rutrum est sit amet metus tristique dictum.',
  'Integer varius ligula vel nisl varius vestibulum.',
  'Morbi et risus id lectus ultricies mattis sed id tortor.',
  'Sed sit amet nulla cursus, gravida dolor ac, volutpat sapien.',
  'Nulla eu metus a justo aliquam convallis et vel nunc.',
  'Aliquam volutpat tortor venenatis euismod convallis.',
  'Morbi ut nibh ut leo maximus maximus eget ut nunc.',
  'Vivamus nec neque eget orci efficitur pharetra.',
  'Nunc consectetur purus et congue consectetur.',
  'In ut magna blandit velit malesuada facilisis ac et quam.',
  'Suspendisse nec eros sit amet arcu vehicula interdum id molestie nibh.',
  'Suspendisse et nisi viverra, posuere ipsum nec, congue odio.',
  'Nunc sagittis nisi eget lorem volutpat venenatis.',
  'Aliquam ac ante vitae velit cursus dignissim.',
  'Vivamus quis erat at elit elementum blandit.',
  'Morbi ullamcorper dolor eget quam bibendum, et rhoncus nibh auctor.',
  'Nam vel ex sed felis egestas gravida id at nunc.',
  'Nunc eleifend enim ut odio tempus commodo.',
  'Nulla ultrices libero consectetur augue ornare, id tincidunt leo laoreet.',
  'Donec ac mauris molestie, euismod lacus fermentum, maximus nibh.',
  'Maecenas consequat neque sit amet facilisis dignissim.',
  'Donec id libero placerat, lobortis lectus id, porta neque.',
  'Donec fringilla sem nec volutpat aliquet.',
  'Phasellus quis orci at nisi posuere ultrices.',
  'Donec tempor augue et leo venenatis, lobortis posuere lectus vestibulum.',
  'Cras nec tellus varius, mollis justo sit amet, pharetra velit.',
  'In a lectus sit amet dui commodo vehicula et sed enim.',
  'Nulla vitae nisl quis mi iaculis sollicitudin.',
  'Duis et metus id orci laoreet blandit a non arcu.',
  'Donec dignissim tortor vel libero semper, at porta nisl dictum.',
  'Aliquam tempus lacus at erat aliquet varius.',
  'Donec non enim at arcu lobortis fringilla eget imperdiet turpis.',
  'Etiam congue leo at molestie tincidunt.',
  'Duis sagittis velit eu ex suscipit eleifend.',
  'Cras blandit elit lobortis neque consectetur consequat.',
  'Vestibulum malesuada elit at lacinia iaculis.',
  'Duis scelerisque lorem id lorem aliquam, et laoreet turpis ultrices.',
  'Nam vestibulum est ac vehicula porta.',
  'Vestibulum eget velit sed erat porttitor condimentum.',
  'Nunc a lorem in lacus aliquam feugiat id non sapien.',
  'Proin efficitur dui vel vestibulum iaculis.',
  'In nec nisi non magna tempor placerat nec et tortor.',
  'Etiam a odio a turpis hendrerit efficitur nec sed nibh.',
  'Suspendisse id urna et nisi efficitur eleifend non volutpat lorem.',
  'Sed et tortor ut dui euismod gravida.',
  'Integer in mauris sed mauris rutrum scelerisque at in massa.',
  'Nam non libero fermentum, dapibus felis et, porta tortor.',
  'Vestibulum sed lacus quis quam tincidunt faucibus sed tincidunt purus.',
  'Ut volutpat leo ut tristique finibus.',
  'Cras a sem vel est convallis tempor.',
  'Curabitur malesuada erat eget sem condimentum, eu aliquet nisl cursus.',
  'Curabitur vel lorem cursus, fermentum metus sit amet, vulputate ante.',
  'Mauris venenatis risus eu mauris tincidunt egestas.',
  'Nullam quis sapien ut augue iaculis consequat vel ac dui.',
  'Nunc eget quam mollis, maximus tellus vel, iaculis eros.',
  'Cras semper felis nec dictum placerat.',
  'Integer dictum ex in ante posuere hendrerit.',
  'Mauris a nulla interdum, blandit dolor suscipit, venenatis nunc.',
  'Nam id turpis et lacus hendrerit dapibus ut at mauris.',
  'Maecenas et lectus et nibh accumsan vestibulum et nec lorem.',
  'Donec suscipit sapien ac malesuada aliquam.',
  'Morbi luctus arcu quis est mollis, posuere dapibus ex pharetra.',
  'Aenean malesuada tellus in varius malesuada.',
  'Suspendisse aliquet mi convallis, tincidunt est at, vehicula lacus.',
  'Integer vulputate nulla sit amet tortor placerat pulvinar.',
  'Morbi eget enim condimentum, malesuada nibh sit amet, rhoncus leo.',
  'Donec imperdiet sem vel libero sollicitudin aliquam.',
  'Suspendisse vitae diam ac ligula ultricies tempus.',
  'Aliquam vitae magna quis turpis vestibulum scelerisque.',
  'Vestibulum rhoncus mi eget vestibulum auctor.',
  'Aenean egestas est et justo euismod facilisis non a ex.',
  'Donec gravida tortor ac dolor vulputate, et scelerisque est volutpat.',
  'Fusce vel metus id urna euismod fermentum.',
  'Sed viverra mi sed leo elementum malesuada.',
  'Duis a ante a arcu laoreet posuere non ut mi.',
  'Sed nec magna ullamcorper, fringilla purus at, viverra felis.',
  'Sed malesuada est malesuada ex scelerisque fringilla.',
  'Cras ultricies sapien vitae sapien lobortis fermentum.',
  'Nam blandit risus a lorem sagittis, sit amet fermentum felis tempus.',
  'Donec non mi ac lorem pharetra efficitur.',
  'Maecenas vestibulum augue vel odio lobortis, a sagittis turpis bibendum.',
  'Fusce sit amet lorem ac ante facilisis mollis.',
  'Praesent tristique elit id mauris tincidunt varius.',
  'Nullam maximus lectus interdum, vehicula massa quis, placerat elit.',
  'Ut bibendum leo non nulla volutpat scelerisque.',
  'Praesent viverra augue vel mauris malesuada feugiat.',
  'Proin suscipit nisi ut enim maximus, vitae vehicula leo accumsan.',
  'Aenean fermentum felis eget posuere mollis.',
  'Maecenas suscipit tortor eget sem molestie egestas.',
  'Nam varius est at ligula ultrices sagittis.',
  'Sed feugiat erat eu enim aliquet vestibulum.',
  'Nulla ullamcorper nisl sit amet lacus ultricies, vitae porta est tincidunt.',
  'Donec ac ligula id nunc volutpat posuere.',
  'Maecenas condimentum sem quis orci ultricies, ac egestas ex ultrices.',
  'Nulla a arcu aliquet, commodo diam eu, pulvinar ligula.',
  'Cras et justo vulputate nulla pulvinar molestie.',
  'Curabitur at risus ac nunc vestibulum ullamcorper.',
  'Phasellus facilisis neque ac posuere facilisis.',
  'Pellentesque in justo vitae neque malesuada cursus.',
  'In eu tortor volutpat, tristique dui nec, dictum ex.',
  'Sed condimentum neque et erat dapibus, nec blandit dui luctus.',
  'In vehicula dolor vel augue semper, et consequat lorem facilisis.',
  'Nam ultricies quam a mi pharetra hendrerit.',
  'Proin nec mi eu orci maximus lobortis.',
  'Mauris convallis ipsum non arcu tincidunt, in porttitor ligula placerat.',
  'Maecenas vel lacus ac nulla ornare placerat et vel neque.',
  'Vestibulum vel enim sit amet urna viverra volutpat.',
  'Phasellus gravida orci eu tellus tincidunt condimentum.',
  'Integer feugiat augue id nisl laoreet, pretium molestie neque tristique.',
  'Mauris a libero iaculis, tincidunt erat eu, commodo tortor.',
  'Curabitur ac ipsum et lectus posuere porttitor eget vitae urna.',
  'Nunc consectetur neque ut nulla vulputate bibendum.',
  'Nunc id metus et lacus maximus suscipit sed id nisi.',
  'Vivamus nec tortor in magna tempor pulvinar.',
  'Proin at justo ultrices, dapibus ligula ut, consequat dolor.',
  'Phasellus tristique eros sit amet mauris condimentum, nec euismod tellus iaculis.',
  'Donec ultrices est eget gravida suscipit.',
  'Donec blandit risus ut augue egestas sodales.',
  'Donec laoreet risus nec ex eleifend accumsan.',
  'Nulla pulvinar metus facilisis sapien varius, non placerat nibh fringilla.',
  'Morbi tincidunt ex nec elementum fringilla.',
  'Donec nec lacus non ex rhoncus rhoncus.',
  'Aenean ac ipsum eu dui faucibus suscipit.',
  'Nulla consectetur enim eu ullamcorper dapibus.',
  'Phasellus ut ante eu nisl euismod volutpat.',
  'Mauris volutpat nulla ut molestie placerat.',
  'Nulla in nulla pulvinar, varius lectus sed, venenatis diam.',
  'Nam rutrum lacus quis augue tincidunt, eu luctus lectus malesuada.',
  'Nam vitae dolor dignissim, faucibus enim id, mollis lectus.',
  'Aenean bibendum ex et nunc porttitor sodales.',
  'Phasellus auctor purus id erat sagittis vestibulum.',
  'Etiam accumsan leo vel ex dignissim, ut semper nisi maximus.',
  'Suspendisse et mauris ut orci convallis egestas in non dolor.',
  'Maecenas tincidunt arcu non condimentum vestibulum.',
  'Proin et tellus vel est aliquet elementum non a dolor.',
  'Etiam interdum tortor at urna vulputate dictum.',
  'Morbi facilisis nunc ut bibendum lacinia.',
  'Nulla ornare metus sit amet eros varius vulputate.',
  'Duis gravida massa sit amet justo laoreet vulputate.',
  'Etiam fringilla sem a turpis interdum, eget fringilla felis dapibus.',
  'Nullam in nisi ac metus egestas imperdiet quis sit amet nulla.',
  'Morbi aliquam erat ut risus porta tristique.',
  'Etiam tincidunt mauris quis quam viverra, sit amet commodo quam placerat.',
  'Integer at sem eget dolor finibus porta.',
  'Maecenas ac ante nec odio mattis luctus.',
  'Pellentesque bibendum dolor dapibus sem aliquam euismod.',
  'Curabitur bibendum velit sed velit efficitur feugiat.',
  'Sed volutpat ante at turpis facilisis lobortis.',
  'Donec sagittis ligula vel ipsum consectetur tincidunt.',
  'Ut ullamcorper massa vel arcu dictum elementum.',
  'Sed rutrum felis vitae ligula aliquet rutrum.',
  'Morbi vel mi at ante consequat venenatis eu in lacus.',
  'Donec fermentum sem mattis, molestie dolor in, fringilla arcu.',
  'Aenean ornare velit ac pharetra pretium.',
  'Sed consectetur elit et massa tincidunt finibus.',
  'Fusce dictum velit eget volutpat efficitur.',
  'Proin nec tortor congue, venenatis elit vehicula, malesuada sem.',
  'In sit amet quam vestibulum, cursus lectus eu, vulputate augue.',
  'Duis ac felis congue odio eleifend tempus sit amet vel ante.',
  'Curabitur sodales enim vel sem finibus blandit ac vel odio.',
  'In sit amet tellus lobortis dolor volutpat vehicula.',
  'Integer et odio et urna laoreet pulvinar sed nec velit.',
  'In quis felis euismod, ullamcorper turpis mattis, rhoncus dolor.',
  'Donec feugiat nisi vitae metus ultrices, sed feugiat lectus ultricies.',
  'Donec volutpat lorem vitae dui tristique, interdum venenatis tellus dignissim.',
  'Aenean scelerisque ligula ut urna facilisis imperdiet.',
  'Nulla imperdiet magna in semper pretium.',
  'Integer placerat metus non diam pretium, ac blandit dolor porttitor.',
  'Curabitur sollicitudin turpis vitae felis ullamcorper, dignissim interdum leo congue.',
  'Nunc lacinia nisl nec lacus congue, vitae condimentum eros luctus.',
  'Donec ut nisi nec orci rutrum vehicula id eget ex.',
  'Etiam auctor neque vitae mattis dictum.',
  'Fusce gravida elit maximus, placerat est eu, hendrerit nulla.',
  'Nullam facilisis odio sed ex iaculis ultricies.',
  'Sed eget lacus nec erat malesuada ornare.',
  'Nullam ut eros ac enim malesuada maximus.',
  'In congue nisi id magna iaculis finibus.',
  'In eget leo non nisi suscipit mattis et vitae urna.',
  'Suspendisse interdum dolor ac rhoncus commodo.',
  'Nulla nec libero id ligula semper maximus.',
  'Cras imperdiet tortor imperdiet dolor dictum, eu feugiat ligula pulvinar.',
  'In tempor ante eget dictum viverra.',
  'Nulla consectetur urna suscipit sapien tempus pretium eget sit amet nibh.',
  'Fusce eget augue quis orci consequat feugiat condimentum accumsan nisl.',
  'Proin viverra magna quis pellentesque cursus.',
  'Sed eget quam malesuada, imperdiet magna non, mollis metus.',
  'Donec posuere sapien vel aliquet auctor.',
  'Cras pellentesque dui imperdiet, varius dolor eu, tincidunt nibh.',
  'Pellentesque laoreet quam eget est euismod, id gravida tellus sollicitudin.',
  'Nam eleifend dui id lacinia eleifend.',
  'Nam laoreet magna at nisl ornare, eget porttitor nibh porta.',
  'Mauris rutrum lectus vel lorem elementum, sit amet sollicitudin eros mollis.',
  'Curabitur eu ipsum ut mi iaculis finibus.',
  'Sed a elit ultrices, porttitor nunc facilisis, pretium nisi.',
  'Mauris vel nunc ullamcorper, porta nisl ut, finibus odio.',
  'Integer sed nunc eget urna faucibus placerat vitae ut sem.',
  'Pellentesque rhoncus velit eu dui porttitor, eu sodales tortor lobortis.',
  'Maecenas et urna sit amet magna ornare varius.',
  'Aliquam a velit et risus imperdiet volutpat a vitae risus.',
  'Praesent vulputate ligula quis lacinia auctor.',
  'Sed eget lectus commodo, rhoncus nisi quis, scelerisque diam.',
  'Proin aliquet eros quis neque ultrices sagittis.',
  'Morbi nec mi in odio mattis mollis eu id tortor.',
  'Sed nec felis quis massa dapibus maximus eu quis urna.',
  'Sed sodales diam eget ligula lacinia, id lobortis tortor condimentum.',
  'Maecenas gravida odio id purus sodales pellentesque.',
  'Etiam hendrerit dui nec dolor facilisis, quis gravida massa fermentum.',
  'Suspendisse feugiat leo elementum ante pretium fringilla.',
  'Sed lacinia mauris sed est consequat sollicitudin.',
  'Fusce accumsan ante in tellus rutrum, nec ultricies tortor lacinia.',
  'Proin dapibus felis ac viverra ullamcorper.',
  'Donec tristique sapien in nibh molestie tempor.',
  'Mauris sed nisi id mi placerat iaculis.',
  'In pellentesque purus vitae est aliquet sagittis.',
  'Aenean mollis est ut nisi pretium convallis.',
  'Fusce congue diam eget porta gravida.',
  'Morbi finibus diam sit amet libero pharetra dictum.',
  'Donec ac turpis non urna consequat iaculis.',
  'Praesent finibus ex ullamcorper venenatis sagittis.',
  'Quisque pretium tellus et justo tristique, et consectetur nunc tincidunt.',
  'Suspendisse eu nulla eu purus venenatis convallis ut ac mi.',
  'Nam molestie nisi vitae maximus bibendum.',
  'In hendrerit enim luctus purus egestas hendrerit.',
  'Nunc elementum tortor sit amet augue fermentum, nec molestie nibh ullamcorper.',
  'In ut ligula nec lacus placerat vestibulum.',
  'Cras sit amet mauris aliquet, mattis enim ac, aliquet est.',
  'Vestibulum finibus orci quis enim pulvinar, sed venenatis risus dapibus.',
  'Mauris in est ac tortor fringilla rutrum.',
  'Integer tincidunt ex nec lectus vestibulum, nec lacinia nulla malesuada.',
  'Cras non lorem sed ex semper imperdiet ut et lacus.',
  'Praesent elementum nibh et vehicula convallis.',
  'Aliquam eu risus id lectus aliquet interdum.',
  'Phasellus vitae massa mollis, laoreet neque at, placerat elit.',
  'Integer sed lorem sit amet felis vulputate tempus.',
  'Mauris et quam ornare, varius leo sit amet, hendrerit sem.',
  'Duis lacinia metus eu rhoncus auctor.',
  'Fusce et lacus nec erat condimentum condimentum.',
  'Integer rhoncus neque eu tincidunt sollicitudin.',
  'Donec tincidunt odio lobortis risus tristique finibus.',
  'Nulla nec lacus mollis, commodo dolor et, congue erat.',
  'Curabitur in justo non lectus fermentum interdum.',
  'Sed ut nulla aliquam, fermentum urna ac, venenatis tellus.',
  'Nullam nec ligula ac nulla pellentesque fermentum eget condimentum quam.',
  'Maecenas gravida lorem sit amet nulla vehicula convallis.',
  'Duis sed neque vulputate, dapibus massa vel, consectetur nisl.',
  'Proin laoreet augue placerat odio varius fermentum.',
  'Etiam nec metus ut ligula laoreet hendrerit.',
  'Praesent vel tortor vestibulum, ornare tellus at, porttitor felis.',
  'Vestibulum sodales nunc ac lorem pretium ultricies.',
  'Morbi rutrum diam quis nibh facilisis condimentum.',
  'Quisque feugiat justo et odio porttitor hendrerit id imperdiet risus.',
  'Nulla interdum leo nec tellus lobortis, in tempor ipsum pretium.',
  'Nullam placerat erat at sagittis imperdiet.',
  'Proin aliquet dui eget lacus malesuada, sit amet semper justo viverra.',
  'Nam nec urna a nibh dignissim hendrerit sed vitae massa.',
  'Donec hendrerit tellus id dui lacinia finibus.',
  'Nam placerat ex vitae condimentum iaculis.',
  'Donec eu sem iaculis nulla venenatis egestas at vel sem.',
  'Aenean sodales eros in neque cursus congue.',
  'Aenean at dui sit amet nibh dignissim facilisis.',
  'Quisque vestibulum metus varius augue fermentum hendrerit.',
  'Etiam finibus nisi eget ligula sodales porta.',
  'Pellentesque quis massa ut odio egestas varius non vel sem.',
  'Mauris cursus tortor sit amet volutpat maximus.',
  'Proin posuere ipsum et pulvinar congue.',
  'Proin condimentum elit id mi condimentum interdum.',
  'Pellentesque eleifend orci id tincidunt convallis.',
  'Proin congue arcu a euismod volutpat.',
  'Duis eu libero vel nulla maximus mattis.',
  'Ut ut neque facilisis, tristique turpis ut, finibus sapien.',
  'Nunc dapibus odio vel lectus posuere, in suscipit mauris semper.',
  'Integer vitae nunc sollicitudin, imperdiet magna dapibus, elementum nisi.',
  'Aenean vel tellus scelerisque, accumsan lorem a, iaculis neque.',
  'Ut ultrices nisi vitae nulla maximus, a pharetra nisi commodo.',
  'Vestibulum quis risus tincidunt, mattis leo ut, ornare metus.',
  'Quisque congue orci vitae diam tincidunt, nec porta justo pharetra.',
  'Sed sed urna vitae leo accumsan ornare nec venenatis nisl.',
  'Phasellus varius odio ornare sapien varius, sed sodales nulla iaculis.',
  'Aenean bibendum nunc at erat convallis, id laoreet arcu lacinia.',
  'Phasellus pulvinar urna sit amet augue tempus accumsan.',
  'Nulla sit amet nulla sollicitudin, maximus nibh in, eleifend lacus.',
  'Vivamus eget nisl egestas, ultricies diam at, viverra massa.',
  'Vivamus ornare eros ac risus dapibus, vitae condimentum arcu vehicula.',
  'Donec sed erat dignissim, ullamcorper leo nec, facilisis lorem.',
  'Aliquam feugiat leo at posuere imperdiet.',
  'Donec in quam vitae justo malesuada rhoncus vitae eu tortor.',
  'Donec non ex eu metus scelerisque vulputate.',
  'Nam non risus gravida, venenatis ligula at, auctor sapien.',
  'Nunc sollicitudin magna eu urna fringilla, quis luctus felis scelerisque.',
  'Quisque interdum mauris at lorem ullamcorper, in fermentum purus pulvinar.',
  'Fusce vestibulum diam sed suscipit luctus.',
  'Nam pretium risus at nunc viverra posuere.',
  'Integer posuere nunc vitae eleifend placerat.',
  'Nam vel velit eu augue iaculis faucibus.',
  'Etiam fermentum nibh et magna dignissim lobortis.',
  'Pellentesque molestie ligula quis mauris finibus, quis dignissim felis egestas.',
  'Nulla a tellus ac est venenatis porta.',
  'Etiam porttitor augue nec iaculis lacinia.',
  'Duis at tellus vitae purus malesuada congue sit amet sit amet magna.',
  'Cras lacinia mi ut nisi pellentesque, eget venenatis arcu faucibus.',
  'Proin nec ex nec nisl posuere tempus sit amet a libero.',
  'Etiam quis sapien eu dolor dapibus vestibulum commodo eget tortor.',
  'Quisque auctor magna vitae est vehicula sagittis.',
  'Cras pharetra mauris sit amet lectus tincidunt dictum.',
  'Fusce lacinia risus a pretium fringilla.',
  'Nunc lacinia sem sit amet risus ullamcorper tempor.',
  'Suspendisse sit amet nibh quis felis vehicula pharetra.',
  'Pellentesque luctus augue vel mauris tincidunt hendrerit.',
  'Etiam ac turpis ut nulla imperdiet euismod a sed nisl.',
  'Praesent non ligula non lacus laoreet luctus tincidunt eu nunc.',
  'Curabitur non orci ut libero semper luctus vehicula in turpis.',
  'In nec justo ut tortor aliquet mattis sit amet ut neque.',
  'Mauris sagittis lorem dapibus molestie fringilla.',
  'Vestibulum sollicitudin urna et tellus pulvinar tristique.',
  'Mauris malesuada velit finibus tristique dictum.',
  'Maecenas a massa luctus, lobortis nisi vel, lacinia neque.',
  'Praesent ac sapien finibus, tristique orci ut, blandit turpis.',
  'Praesent tincidunt sem ut lectus maximus, vitae feugiat eros hendrerit.',
  'Nam egestas diam nec nulla egestas fringilla.',
  'Morbi commodo magna ut nunc porttitor, eu bibendum sem gravida.',
  'Proin at nunc a arcu semper venenatis.',
  'Phasellus cursus enim nec lectus sagittis accumsan.',
  'Sed eget ipsum ac ipsum molestie commodo vitae ut neque.',
  'Phasellus mollis urna et elit lobortis, sed tempor lectus consequat.',
  'Suspendisse eget nunc ac sapien dictum lobortis et nec lorem.',
  'Curabitur vehicula leo ac mi ullamcorper, eget dignissim metus efficitur.',
  'Sed interdum libero ac augue elementum, nec imperdiet mauris fringilla.',
  'Pellentesque id dui a mi maximus aliquam non non mauris.',
  'In vitae mi malesuada enim gravida ullamcorper.',
  'Nunc non ante ullamcorper, semper mi ut, viverra nisl.',
  'Aliquam vel ligula quis mauris luctus feugiat.',
  'Nulla mollis dui sed feugiat tincidunt.',
  'Pellentesque faucibus leo dignissim, placerat orci ut, semper sem.',
  'In sit amet mauris vel libero suscipit tincidunt.',
  'Cras egestas augue ac elit vestibulum, vulputate tempor arcu vehicula.',
  'Mauris rhoncus dui ut dui malesuada, non commodo neque vulputate.',
  'Cras et libero id elit eleifend placerat eget blandit ex.',
  'Ut tristique nunc mollis tortor pretium posuere.',
  'Suspendisse vulputate elit elementum, consequat lorem a, aliquet enim.',
  'Integer maximus nulla a erat convallis feugiat.',
  'Mauris ac lorem aliquet, ultrices diam ut, ultrices lacus.',
  'Proin at ex egestas, iaculis diam sit amet, imperdiet odio.',
  'Praesent fringilla metus eget lectus cursus, ac condimentum felis semper.',
  'Donec at mauris id lectus bibendum posuere.',
  'Suspendisse in velit vitae libero varius ullamcorper at id nisi.',
  'Sed ac lectus consequat, hendrerit lacus eget, vehicula purus.',
  'Curabitur fringilla metus vitae eros mollis rhoncus.',
  'Quisque a magna vel augue tincidunt ultricies.',
  'Aliquam euismod risus ut ligula fermentum, ac vestibulum neque venenatis.',
  'Nullam mollis arcu et lorem malesuada, ac maximus purus dignissim.',
  'In hendrerit orci eu elit sodales, ac posuere risus euismod.',
  'Maecenas quis tortor posuere justo eleifend rutrum sit amet at odio.',
  'Sed fermentum enim quis tincidunt imperdiet.',
  'Sed sodales arcu porta, tincidunt ipsum vel, rhoncus leo.',
  'Cras eget lorem sed ante efficitur ornare.',
  'Sed sed risus aliquet, volutpat magna quis, porta augue.',
  'Curabitur mollis mi id volutpat ultrices.',
  'Aenean accumsan augue in luctus consequat.',
  'Vestibulum volutpat odio eget pretium ultrices.',
  'Quisque ut ex convallis, semper urna sed, porta lectus.',
  'Cras sed magna sodales, efficitur nibh a, molestie leo.',
  'Etiam vel eros posuere, porttitor purus sit amet, auctor justo.',
  'Donec imperdiet diam non quam consequat, a tempor ex scelerisque.',
  'Fusce quis dui eget dui laoreet scelerisque.',
  'Pellentesque sit amet nibh vulputate, posuere lorem eu, vulputate mauris.',
  'Morbi a est ultricies ipsum vestibulum accumsan.',
  'Curabitur non neque aliquam, interdum elit vel, fermentum lectus.',
  'Vivamus porttitor mauris eu nisi eleifend varius.',
  'Aenean ac justo blandit, pretium leo sed, mattis mi.',
  'Nulla a arcu sodales, accumsan nulla ac, lacinia turpis.',
  'Fusce eget nisi pharetra, suscipit dolor id, facilisis sem.',
  'Sed tempus lacus vel ornare semper.',
  'Duis rutrum risus vel libero sodales, eu dapibus leo euismod.',
  'Vivamus pellentesque nisi quis arcu tincidunt varius.',
  'Nullam sagittis dolor sit amet neque consectetur tincidunt.',
  'Nunc venenatis eros et congue finibus.',
  'Integer porttitor ligula id quam dapibus iaculis.',
  'Duis ullamcorper augue id bibendum tempus.',
  'Curabitur ac leo posuere, interdum est at, bibendum metus.',
  'Proin sed nisi cursus, varius erat tempor, tincidunt libero.',
  'Morbi bibendum erat vitae quam consectetur lobortis.',
  'Donec feugiat sapien et finibus vulputate.',
  'Donec hendrerit ipsum quis porttitor tempor.',
  'Suspendisse pretium nisi sed lacus commodo laoreet.',
  'In ultricies velit vel tincidunt tempus.',
  'Integer egestas ante non sodales sollicitudin.',
  'Proin a ante sed nisl faucibus sodales volutpat et lorem.',
  'Donec efficitur ante vitae purus vestibulum, non dictum quam fringilla.',
  'Sed eget neque vel eros blandit semper.',
  'Fusce vel tortor at tellus sagittis bibendum.',
  'Praesent mattis odio in turpis euismod fringilla vel at mi.',
  'Aenean ullamcorper lacus in consequat posuere.',
  'In at massa et nisi volutpat vulputate.',
  'Praesent consectetur augue sit amet interdum convallis.',
  'Sed sed neque vel dolor convallis vehicula sit amet non magna.',
  'Cras quis risus molestie, aliquam ante id, tristique enim.',
  'Donec non sem auctor, eleifend risus iaculis, semper nulla.',
  'In gravida quam vitae sodales feugiat.',
  'Nullam vitae neque eget leo dignissim interdum a vitae eros.',
  'Proin vitae diam porta, tristique diam quis, convallis erat.',
  'Aenean pellentesque justo ac faucibus tempus.',
  'Aliquam tristique risus id magna eleifend, at porttitor justo facilisis.',
  'Sed et turpis eget velit dapibus auctor vel id odio.',
  'Aenean porttitor lorem non dignissim efficitur.',
  'Phasellus convallis arcu ac sem egestas, dictum convallis libero pellentesque.',
  'Nulla aliquam felis quis ex hendrerit, vitae gravida nisl sodales.',
  'Etiam fermentum purus a nulla varius semper.',
  'Fusce ac velit nec mauris suscipit ullamcorper a vel turpis.',
  'Suspendisse ac velit eget lectus aliquet vestibulum.',
  'Phasellus bibendum ligula eget viverra luctus.',
  'Etiam vel augue tristique, consequat leo nec, pharetra est.',
  'Maecenas semper sapien eget urna maximus mattis.',
  'Donec aliquam dolor in nulla vehicula tincidunt et eget libero.',
  'Nunc sollicitudin eros at felis condimentum vulputate.',
  'Aliquam porttitor ipsum in mollis consequat.',
  'Integer vel ante in leo ultricies porta.',
  'Vestibulum ac diam fermentum magna efficitur fermentum.',
  'Donec cursus mi sit amet nulla pellentesque, eu ornare nunc condimentum.',
  'Nulla tincidunt ligula vitae nibh facilisis iaculis.',
  'Vestibulum scelerisque augue nec mi finibus lacinia.',
  'Phasellus eget orci eget nisi cursus hendrerit.',
  'Duis bibendum ligula sit amet ligula porta, vitae pellentesque risus pulvinar.',
  'Mauris scelerisque nibh ut cursus rutrum.',
  'Vestibulum non leo sagittis, fringilla dui efficitur, sollicitudin libero.',
  'Aliquam tristique odio non arcu aliquam vulputate.',
  'Donec eu lacus laoreet, tempor orci et, pharetra turpis.',
  'Cras vel est pharetra, imperdiet leo sed, efficitur tortor.',
  'Proin vitae ligula vitae libero sodales dapibus.',
  'Curabitur gravida urna quis sem porttitor, ac aliquet velit molestie.',
  'Vivamus eu urna pulvinar, consequat neque eget, semper felis.',
  'Suspendisse non nisi tristique, faucibus ex at, dapibus nisi.',
  'Sed sed turpis lobortis, tincidunt lacus id, sagittis lacus.',
  'Etiam vitae mauris sed odio aliquam convallis.',
  'Etiam in leo eget ligula posuere efficitur et ac dui.',
  'Nulla nec dui ut odio rutrum fermentum.',
  'Sed in dolor non risus dictum luctus sed sed mi.',
  'Morbi luctus turpis quis risus fringilla maximus.',
  'Nullam a justo mattis elit volutpat bibendum in sit amet enim.',
  'Aliquam faucibus nulla id ultricies congue.',
  'Fusce a lectus vitae diam ultrices ultricies.',
  'Pellentesque molestie ante sagittis augue rutrum porttitor.',
  'Phasellus non tortor nec massa accumsan gravida cursus fermentum odio.',
  'Pellentesque commodo massa ut sapien laoreet, sollicitudin tincidunt nibh congue.',
  'Vestibulum cursus quam id nulla finibus, et mattis felis hendrerit.',
  'Aliquam vehicula mauris non lectus lobortis euismod ut ut elit.',
  'Etiam in lorem nec arcu porta volutpat.',
  'Pellentesque auctor orci id molestie malesuada.',
  'Sed dictum lacus vitae leo faucibus molestie.',
  'Duis non odio ac arcu commodo finibus eu quis ante.',
  'Sed vel nisl eget enim convallis vestibulum.',
  'Aliquam a ipsum at felis porttitor egestas.',
  'Proin mattis metus at lacus porta, vel rutrum justo porttitor.',
  'Aenean volutpat nibh eu urna bibendum aliquam.',
  'Nullam vel lorem vitae tortor iaculis dictum.',
  'Mauris vulputate eros non massa blandit vehicula.',
  'Pellentesque eu turpis convallis, bibendum tellus vel, mattis urna.',
  'Nullam pulvinar tellus eu aliquam condimentum.',
  'Nullam eleifend nisl a nisi rutrum ullamcorper.',
  'Duis a enim a elit faucibus eleifend.',
  'Etiam vitae velit in lectus vulputate pharetra dapibus at purus.',
  'Vestibulum vel lorem pulvinar metus porttitor mollis sed at orci.',
  'Aliquam faucibus nibh at tortor faucibus, id ultrices leo lacinia.',
  'Sed blandit felis a lectus consequat, quis mattis lorem finibus.',
  'Sed eget tortor at ipsum fermentum ultricies quis vel dui.',
  'Phasellus id nunc ullamcorper, posuere nisl sit amet, pretium elit.',
  'Nunc sit amet lorem id nulla ullamcorper laoreet vel et erat.',
  'Praesent consequat est vel mi aliquam scelerisque.',
  'Integer euismod ligula egestas justo vehicula, ac posuere ante auctor.',
  'In lobortis velit nec elit cursus, nec faucibus eros efficitur.',
  'Vestibulum in turpis vel justo tincidunt posuere at sit amet augue.',
  'Integer in elit bibendum, luctus lacus vel, efficitur lacus.',
  'Suspendisse nec nisi eu magna tempor venenatis.',
  'Pellentesque non risus nec enim hendrerit euismod.',
  'Nulla quis leo non libero scelerisque imperdiet vel non risus.',
  'Quisque consectetur magna laoreet odio aliquam fermentum.',
  'Nulla sit amet eros pulvinar, scelerisque nulla a, dapibus purus.',
  'Phasellus a nisl molestie, fermentum est at, lacinia lorem.',
  'Aenean vehicula metus ultrices, mollis felis at, lacinia odio.',
  'Praesent eget ligula eget purus semper congue vel eu orci.',
  'Duis lobortis turpis vitae quam iaculis dapibus.',
  'Nullam luctus leo nec est posuere, eget facilisis ligula efficitur.',
  'Phasellus sit amet nunc ullamcorper, elementum arcu eget, fringilla nisi.',
  'Cras bibendum urna in ligula eleifend accumsan.',
  'Sed accumsan libero sed diam condimentum, nec facilisis urna rhoncus.',
  'Sed ullamcorper sapien ut eleifend tristique.',
  'Nulla condimentum eros non euismod luctus.',
  'Cras faucibus augue vestibulum diam laoreet mollis.',
  'Ut sit amet tortor dictum, bibendum eros sed, efficitur ligula.',
  'In cursus ex in suscipit finibus.',
  'Phasellus vel nunc ut enim volutpat pulvinar vitae et lectus.',
  'Nam placerat lorem eget nunc aliquam pharetra eget nec nibh.',
  'Vestibulum sagittis ex sed tellus bibendum, ac molestie nulla mattis.',
  'Donec sit amet magna egestas, iaculis ex pharetra, elementum tellus.',
  'Vestibulum porta nibh in nunc dapibus, non dapibus dui sagittis.',
  'Cras et lorem pretium libero semper finibus.',
  'Maecenas a eros in urna pulvinar mattis.',
  'Aenean at mauris eget sem sodales sollicitudin.',
  'Integer fringilla ligula id ultricies venenatis.',
  'Cras ut lectus volutpat, pulvinar arcu in, rhoncus urna.',
  'Nunc eu urna nec libero sollicitudin dignissim.',
  'Proin mattis lectus vehicula eros feugiat, nec volutpat mi consequat.',
  'Nullam ut tellus congue, molestie odio tempus, placerat felis.',
  'Vestibulum non nunc vel eros sagittis viverra.',
  'Sed porttitor urna non leo commodo, sed vehicula tortor mollis.',
  'Nulla laoreet elit eget maximus laoreet.',
  'In suscipit nulla in tincidunt sollicitudin.',
  'Duis lacinia metus ut quam interdum, suscipit posuere magna pellentesque.',
  'Morbi suscipit eros sit amet nisi ultrices, vitae rutrum ligula sodales.',
  'Ut quis dui rhoncus neque vulputate imperdiet vel eu velit.',
  'Donec vitae augue luctus, gravida dolor non, bibendum mi.',
  'Morbi in diam id odio sodales elementum id at libero.',
  'Maecenas mattis arcu ut neque varius, eget maximus ligula dignissim.',
  'Maecenas interdum augue in interdum consequat.',
  'Vivamus at dolor tincidunt, efficitur ante ut, cursus dolor.',
  'Donec imperdiet purus eget libero euismod luctus.',
  'Sed gravida sem in pulvinar fringilla.',
  'Vivamus porta diam ac malesuada efficitur.',
  'Etiam a magna a nunc luctus consectetur eu id sem.',
  'In quis lacus imperdiet, tempor mauris ut, porta ipsum.',
  'Fusce semper nibh id eleifend tincidunt.',
  'Aenean vestibulum lectus eu ipsum consequat, et tempus nunc placerat.',
  'Quisque vel justo quis felis maximus tincidunt.',
  'Sed congue ante at sem consectetur suscipit.',
  'Cras lobortis leo vel quam sagittis, at pulvinar ligula gravida.',
  'Vivamus in neque eget orci sollicitudin vestibulum in non lorem.',
  'Suspendisse quis purus in leo cursus malesuada.',
  'Duis vel est sit amet justo consectetur bibendum sit amet eget leo.',
  'Aliquam quis ex venenatis, aliquam lectus vulputate, egestas sem.',
  'Maecenas volutpat felis nec nisl viverra, a dignissim lectus lacinia.',
  'In sit amet risus blandit, efficitur magna vel, pretium turpis.',
  'Nullam ultrices turpis in erat interdum, id accumsan mauris elementum.',
  'Integer sagittis nibh sed sapien ultrices accumsan.',
];

export default class Lorem {
  static sentence(): string {
    return UtilsService.getRandomElement(loremData);
  }
  
  static word(): string {
    const sentence = Lorem.sentence().split(' ').filter(s => s.length > 3);
    const word = UtilsService.getRandomElement(sentence);
    return word.replace(/[^a-zA-z]/, '');
  }
}
