import { Component, OnInit, Input } from '@angular/core';
import { ImpactTextEnum } from 'app/shared/enums/impact.enum';
import { Vendor } from '../../generator.model';

@Component({
  selector: 'cygov-new-vendor-settings',
  templateUrl: './new-vendor-settings.component.html',
  styleUrls: ['./new-vendor-settings.component.scss']
})
export class NewVendorSettingsComponent implements OnInit {
  @Input() vendor: Vendor;

  impacts = Object.values(ImpactTextEnum);

  constructor() { }

  ngOnInit() {
  }

  setDate({ target }) {
    this.vendor.dueDate = target.value;
  }

}
