import { Component, OnInit, Input } from '@angular/core';
import { ChartUtilsService } from '../chart-utils.service';
import { ScanChartData } from 'models/external-scan.model';
import { ChartCoordinates } from 'models/budget-chart.model';

@Component({
  selector: 'cygov-scan-chart',
  templateUrl: './scan-chart.component.html',
  styleUrls: ['./scan-chart.component.scss']
})
export class ScanChartComponent implements OnInit {
  // ScanChartData -> Radius Represents how far the point will be from the center of the Circle.
  // Range of Radius is '0 to 1' i.e 0 represents the point is in the center of the inner most circle.
  //                                 1 represents the point is in the edge of the outer most circle.

  // ScanChartData -> Angle Represents the angle of the point.
  // Range of Angle is '0 to 1' i.e 0 represents the point is at an angle of 0 degrees in the circle.
  //                                0.9 represents the point is at an angle of around 330 degrees in the circle.

  @Input() scanChartData: ScanChartData[];

  SVG_HEIGHT: number = 300;
  SVG_WIDTH: number = 450;
  SVG_VIEW_BOX: string;
  CENTER_X: number;
  CENTER_Y: number;
  CIRCLE_RADIUS_GAP: number = 20;
  INITIAL_RADIUS: number = 10;
  STARTING_ANGLE: number = -90;
  STRONG_TICS_LENGTH: number = 8;
  LIGHT_TICS_LENGTH: number = 2;
  OUTER_ARCS_ANGLE: number = 22;
  INNER_CIRCLES_RADIUS: number = 3;

  CIRCLES = Array(6)
    .fill(0)
    .map((x, i) => i + 1);

  STRONG_LINES = Array(12)
    .fill(0)
    .map(x => '');

  STRONG_TICS = Array(12)
    .fill(0)
    .map(x => '');

  LIGHT_TICS = Array(120)
    .fill(0)
    .map(x => '');

  OUTER_ARCS = Array(4)
    .fill(0)
    .map(x => '');

  circleCoordinates: ChartCoordinates[];

  ROTATE_PIE_PATH: string;

  constructor() {
    this.CENTER_X = this.SVG_WIDTH / 2;
    this.CENTER_Y = this.SVG_HEIGHT / 2;
    this.SVG_VIEW_BOX = `0 0 ${this.SVG_WIDTH} ${this.SVG_HEIGHT}`;
    this.circleCoordinates = [];
  }

  ngOnInit() {
    this.STRONG_LINES = this.STRONG_LINES.map((x, i) => {
      return this.describeStrongLines(
        this.CENTER_X,
        this.CENTER_Y,
        this.INITIAL_RADIUS + this.CIRCLES.length * this.CIRCLE_RADIUS_GAP,
        this.STARTING_ANGLE + i * 30
      );
    });
    this.STRONG_TICS = this.STRONG_TICS.map((x, i) => {
      return this.describeStrongTics(
        this.CENTER_X,
        this.CENTER_Y,
        this.INITIAL_RADIUS + this.CIRCLES.length * this.CIRCLE_RADIUS_GAP,
        this.INITIAL_RADIUS +
          this.CIRCLES.length * this.CIRCLE_RADIUS_GAP +
          this.STRONG_TICS_LENGTH,
        this.STARTING_ANGLE + i * 30
      );
    });
    this.LIGHT_TICS = this.LIGHT_TICS.map((x, i) => {
      return this.describeStrongTics(
        this.CENTER_X,
        this.CENTER_Y,
        this.INITIAL_RADIUS + this.CIRCLES.length * this.CIRCLE_RADIUS_GAP,
        this.INITIAL_RADIUS + this.CIRCLES.length * this.CIRCLE_RADIUS_GAP + this.LIGHT_TICS_LENGTH,
        this.STARTING_ANGLE + i * 3
      );
    });
    this.OUTER_ARCS = this.OUTER_ARCS.map((x, i) => {
      return ChartUtilsService.describeArc(
        this.CENTER_X,
        this.CENTER_Y,
        this.INITIAL_RADIUS +
          this.CIRCLES.length * this.CIRCLE_RADIUS_GAP +
          this.STRONG_TICS_LENGTH +
          5,
        this.STARTING_ANGLE * (i + 1),
        this.STARTING_ANGLE * (i + 1) + this.OUTER_ARCS_ANGLE
      );
    });
    this.circleCoordinates = this.scanChartData.map(item => {
      return this.describeCircleCoordinates(
        this.CENTER_X,
        this.CENTER_Y,
        item.radius * (this.INITIAL_RADIUS + this.CIRCLES.length * this.CIRCLE_RADIUS_GAP),
        this.STARTING_ANGLE + item.angle * 360
      );
    });
    this.ROTATE_PIE_PATH = this.describeRotatePath(
      this.CENTER_X,
      this.CENTER_Y,
      this.INITIAL_RADIUS + this.CIRCLES.length * this.CIRCLE_RADIUS_GAP,
      this.STARTING_ANGLE + 270
    );
  }

  private describeStrongLines(x, y, radius, angle) {
    const endLine = ChartUtilsService.polarToCartesian(x, y, radius, angle);
    return `M${x},${y} L${endLine.x},${endLine.y}`;
  }

  private describeStrongTics(x, y, innerRadius, outerRadius, angle) {
    const startLine = ChartUtilsService.polarToCartesian(x, y, innerRadius, angle);
    const endLine = ChartUtilsService.polarToCartesian(x, y, outerRadius, angle);
    return `M${startLine.x},${startLine.y} L${endLine.x},${endLine.y}`;
  }

  private describeCircleCoordinates(x, y, innerRadius, angle) {
    const startLine = ChartUtilsService.polarToCartesian(x, y, innerRadius, angle);
    return new ChartCoordinates(
      startLine.x.toString(),
      startLine.y.toString(),
      angle - this.STARTING_ANGLE
    );
  }

  private describeRotatePath(x: number, y: number, radius: number, angle: number): string {
    const start = ChartUtilsService.polarToCartesian(x, y, radius, angle + 90);
    const end = ChartUtilsService.polarToCartesian(x, y, radius, angle);
    const arcSweep = '0';
    const d = [
      'M',
      x,
      y,
      'L',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      arcSweep,
      0,
      end.x,
      end.y,
      'z'
    ].join(' ');
    return d;
  }
}
