import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Entity } from 'models/entity.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'app/shared/data.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'app/shared/utils.service';
import { AddVendorModalComponent } from './add-vendor-modal/add-vendor-modal.component';
import { AddCrbVendorModalComponent } from './add-crb-vendor-modal/add-crb-vendor-modal.component';

@Component({
  selector: 'cygov-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss'],
})
export class AddVendorComponent implements OnInit {
  @Output() reload = new EventEmitter<boolean>();
  entity: Entity;
  isCrbAdjustments = UtilsService.isCRB;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    try {
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.entity = entityId ? await this.dataService.getEntity(entityId) : null;
    } catch (e) {
      this.toastr.error('Error loading Entity');
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  openAddVendorModal() {
    const modalComponent = this.isCrbAdjustments
      ? AddCrbVendorModalComponent
      : AddVendorModalComponent;
    const modalRef = this.modalService.open(modalComponent, {
      centered: true,
      size: 'lg',
      windowClass: this.isCrbAdjustments
        ? 'add-vendor-modal add-crb-vendor-modal'
        : 'add-vendor-modal',
    });
    modalRef.componentInstance.entity = this.entity;
    modalRef.componentInstance.modalResult.subscribe((event: boolean) => {
      this.reload.emit(event);
      modalRef.close();
    });
  }
}
