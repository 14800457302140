import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cygov-loader',
  templateUrl: './cygov-loader.component.html',
  styleUrls: ['./cygov-loader.component.scss']
})
export class CygovLoaderComponent implements OnInit {
  @Input() cygovLoader: boolean;

  constructor() {}

  ngOnInit() {}
}
