import { Injectable } from '@angular/core';
import { StandardEnum } from '../enums/standard.enum';
import { UtilsService } from '../utils.service';
import { AgencyReference } from 'models/agency.model';
import { Qualification } from 'models/entity.model';


@Injectable({
  providedIn: 'root'
})
export class EntityStandardsService {
  static buildSubEntityStandardsQualify(agencyReference: AgencyReference): Qualification[] {
    if (
      !UtilsService.isDefined(agencyReference) ||
      !agencyReference.processAreaScoreMap
    ) {
      return [];
    }
    const standardList = Object.values(StandardEnum);

    return Object.entries(agencyReference.processAreaScoreMap)
      .filter(([key, _]) => standardList.includes(key.toLowerCase()))
      .map(([_, { name, isQualify }]) => ({ name, isQualify }));
  }

  static buildEntityStandardsQualify(agencies: AgencyReference[]): Qualification[] {
    const result = agencies.reduce((cur, agency) => {
      const subEntityQualify = EntityStandardsService.buildSubEntityStandardsQualify(
        agency
      );

      return subEntityQualify.reduce((res, standardQualify) => {
        if (!UtilsService.isDefined(res[standardQualify.name])) {
          res[standardQualify.name] = { ...standardQualify };
        }
        res[standardQualify.name].isQualify =
          res[standardQualify.name].isQualify && standardQualify.isQualify;
        return res;
      }, cur);
    }, {});
    return Object.values(result);
  }
}
