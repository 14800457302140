import { EventEmitter, Input, OnChanges } from '@angular/core';
import { Vendor } from 'models/vendor.model';
import { Component, OnInit, Output } from '@angular/core';
import { TableHeader } from 'models/table-header.model';
import { ThirdPartyService } from 'app/third-party/third-party.service';
import { Scores } from 'models/agency.model';

@Component({
  selector: 'cygov-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss'],
})
export class VendorListComponent implements OnInit, OnChanges {
  @Input() vendorList: Vendor[];
  @Output() vendorSelected = new EventEmitter<Vendor>();

  isLoading = false;
  showVendorDetail = false;
  selectedVendor: string = null;
  sortData: any;
  groupList: string[];

  constructor() {}

  ngOnInit() {
    this.groupList = ThirdPartyService.vendorGroupList(this.vendorList);
  }

  ngOnChanges() {
    if (this.sortData) {
      this.onSortChanged(this.sortData);
    }
  }

  toggleList(vendor: Vendor) {
    this.vendorSelected.emit(vendor);
  }

  calculateScore(vendor: Vendor) {
    return new Scores({ collection: vendor.scores.collection });
  }

  onSortChanged(sortData: TableHeader) {
    this.sortData = sortData;
    ThirdPartyService.sortArray(sortData, this.vendorList);
  }
}
