import { Component, OnInit } from '@angular/core';

import { GeneratorService } from './generator.service';
import { Shown } from './generator.model';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'cygov-generator',
  templateUrl: './generator.component.html',
  styleUrls: ['./generator.component.scss'],
})
export class GeneratorComponent implements OnInit {
  isShow: Shown = {
    entity: false,
    subEntity: false,
    user: false,
  };

  entityCreationOptions = [
    { name: 'Create New', value: 'create' },
    { name: 'Select Existing', value: 'select' },
  ];

  isCrbAdjustments = UtilsService.isCRB;

  constructor(public service: GeneratorService) {}

  ngOnInit() {}

  toggleContent(field: 'entity' | 'subEntity' | 'user') {
    this.isShow[field] = !this.isShow[field];
  }
}
