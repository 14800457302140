import { Component, OnInit, Input } from '@angular/core';
import { StandardEnum } from 'app/shared/enums/standard.enum';
import { UtilsService } from 'app/shared/utils.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Vendor } from 'models/vendor.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cygov-vendor-questionnaire',
  templateUrl: './vendor-questionnaire.component.html',
  styleUrls: ['./vendor-questionnaire.component.scss'],
})
export class QuestionnaireVendorComponent implements OnInit {
  @Input() vendor: Vendor;
  readinessQuestionnaires;

  headElements = ['Questionnaire Tag', 'Status', 'Critical Gaps', 'All Gaps', ''];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    // filter standards from vendorQuestionnaires
    const standardList = Object.keys(StandardEnum).map(key => StandardEnum[key]);
    this.readinessQuestionnaires = this.vendor.vendorDetails.questionnaires.filter(
      questionnaire => {
        let notStandard = true;
        standardList.forEach(standard => {
          if (standard.includes(questionnaire.tag)) {
            notStandard = false;
          }
        });
        return notStandard;
      }
    );
  }

  async goToVendorQuestionnaire() {
    this.toastr.info(`Loading Questionnaire`);
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.router.navigate([`third-party/${entityId}/questionnaire`], {
      queryParams: { vendorId: this.vendor.id },
    });
  }

  goToGaps(filter: string) {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    const queryParams =
      filter === 'all' ? { vendorId: this.vendor.id } : { filter, vendorId: this.vendor.id };

    this.router.navigate([`third-party/${entityId}/gaps/vendor`], {
      queryParams,
    });
  }
}
