import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { FileUploadCallback } from '../../generator.model';
import { GeneratorService } from '../../generator.service';

@Component({
  selector: 'cygov-generator-new-entity-settings',
  templateUrl: './new-entity-settings.component.html',
  styleUrls: ['./new-entity-settings.component.scss']
})
export class NewEntitySettingsComponent implements OnInit {

  constructor(
    public service: GeneratorService,
  ) { }

  ngOnInit() {
  }

}
