import { Pipe, PipeTransform } from '@angular/core';
/*
 * Normalize the risk Score to x.x format.
 *  2779.170166015625 to 2.7
 * Usage:
 * number | normalizeScore
 */
@Pipe({
  name: 'normalizeScore'
})
export class NormalizeScorePipe implements PipeTransform {
  transform(value: number): number {
    return value / 10000;
  }
}
