import { Vendor } from 'models/vendor.model';
import { Component, OnInit, Input } from '@angular/core';
import { ThirdPartyService } from 'app/third-party/third-party.service';
import { Scores } from 'models/agency.model';

@Component({
  selector: 'cygov-vendor-item',
  templateUrl: './vendor-item.component.html',
  styleUrls: ['./vendor-item.component.scss']
})
export class VendorItemComponent implements OnInit {
  @Input() vendor: Vendor;
  collectionHeight: number = 20;

  constructor() { }

  async ngOnInit() {
  }

  calculateScore(vendor: Vendor) {
    return new Scores({ collection: vendor.scores.collection });
  }

  getImpactText(impact: number) {
    return ThirdPartyService.getImpactText(impact);
  }
}
