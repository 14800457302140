import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/shared/auth.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'app/shared/data.service';
import { User, UserAffiliation } from 'models/user.model';
import { Entity } from 'models/entity.model';
import { AgencyReference, ShortRef } from 'models/agency.model';
import { RoleEnum } from 'app/shared/enums/role.enum';
import { UtilsService } from '../../utils.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-add-participant-modal',
  templateUrl: './add-participant-modal.component.html',
  styleUrls: ['./add-participant-modal.component.scss'],
})
export class AddParticipantModalComponent implements OnInit {
  @Input() survey: any;
  @Output() modalResult = new EventEmitter<User>();
  entity: Entity;
  subEntity: Entity;
  subEntityRefList: AgencyReference[] = [];
  newUser: User = new User({ role: RoleEnum.PARTICIPANT });

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {}

  async ngOnInit() {
    this.newUser.affiliateReference = this.newUser.affiliateReference || new UserAffiliation();
    this.newUser.affiliateReference.agencies =
      this.newUser.affiliateReference.agencies || [];

    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    const subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');

    try {
      if (await this.authService.hasPermission(RoleEnum.ENTITY_LEADER, entityId)) {
        this.entity = await this.dataService.getEntity(entityId);
        this.subEntityRefList = this.entity.agencies;
      } else if (
        await this.authService.hasPermission(RoleEnum.SUB_ENTITY_LEADER, entityId, subEntityId)
      ) {
        this.subEntity = await this.dataService.getSubEntity(subEntityId);
        this.subEntityRefList = [this.subEntity.details.reference];
        // sub entity leader can add new participant to his entity
        this.newUser.affiliateReference.agencies.push({...this.subEntity.details.reference});
      } else {
        this.toastr.error('Unauthorized');
      }
    } catch (e) {
      console.log('AddParticipantModalComponent - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  isValid(user: User) {
    let valid = true;
    // tslint:disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(user.email).toLowerCase())) {
      this.toastr.error('Email is incorrect');
      valid = false;
    }
    if (!user.name) {
      this.toastr.error('User name is required');
      valid = false;
    }

    if (!user.affiliateReference.agencies[0].id) {
      this.toastr.error('Please select sub entity');
      valid = false;
    }

    return valid;
  }

  emitUser(newUser: User, surveyName: string) {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    const entityName = this.entity
      ? this.entity.name
      : this.subEntity
      ? this.subEntity.details.stateReference.name
      : null;
    newUser.affiliateReference.states.push({...this.subEntity.details.stateReference});
    newUser.email = newUser.email.toLowerCase();

    if (surveyName) {
      newUser.questionSheet.name = surveyName;
    }
    if (!!newUser.affiliateReference.agencies.length) {
      const subEntityRef = this.subEntityRefList.find(
        subEntityRefItem => !!newUser.affiliateReference.agencies.filter(a => a.id === subEntityRefItem.id).length
      );
      newUser.affiliateReference.agencies[0].name = subEntityRef.name;
    }

    this.modalResult.emit(newUser);
  }
}
