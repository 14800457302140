import { ExternalScanCount } from '../../../../models/external-scan-count.model';
import { Component, OnInit, Input } from '@angular/core';
import { ScanChartData } from 'models/external-scan.model';

@Component({
  selector: 'cygov-external-scan-widget',
  templateUrl: './external-scan-widget.component.html',
  styleUrls: ['./external-scan-widget.component.scss']
})
export class ExternalScanWidgetComponent implements OnInit {
  @Input() externalScanCount: ExternalScanCount;
  @Input() scanChartData: ScanChartData[];

  constructor() {}

  ngOnInit() {}
}
