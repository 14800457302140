import { Injectable } from '@angular/core';
import { parse, ParseResult } from 'papaparse';
import {
  CompletionIfFields,
  Question,
  QuestionSheet,
  SurveyCore,
  Denied,
} from '../../models/agency.model';
import { UtilsService } from './utils.service';
import * as uuid from 'uuid';
import { Alert } from '../../models/entity.model';
import { AnswerEnum } from './enums/answer.enum';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ImpactTextEnum } from './enums/impact.enum';
import {
  GET_FILE,
  UPLOAD_ARTIFACT,
  DOWNLOAD_ARTIFACT,
  DELETE_ARTIFACT,
  UPLOAD_STATE_LOGO,
  USER_DOWNLOAD_AGENCY_DATA,
} from 'app/app.paths';
import { AuthService } from './auth.service';
import JSZip from 'jszip';

@Injectable()
export class FileService {
  static downloader(response, fileName) {
    const blob = new Blob([response]);
    const link: any = document.createElement('a');
    link.style.cssText = 'display: hidden';
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  static getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  static async fileToCsv(fileInput: any): Promise<any> {
    try {
      const results = await FileService.parseFile(fileInput.target.files[0]);
      return Promise.resolve(results);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  static parseFile(csv): Promise<ParseResult> {
    return new Promise((resolve, reject) => {
      parse(csv, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        delimiter: ',',
        encoding: 'UTF-8',
        complete(results, file) {
          resolve(results);
        },
        error(err) {
          console.log(err);
          reject(err);
        },
      });
    });
  }

  static mapDataToSurveyCore(
    fileData
  ): {
    surveyCore: SurveyCore;
    questionSheets: QuestionSheet[];
    completionByField: CompletionIfFields[];
  } {
    let completionByField: CompletionIfFields[] = [
      {
        name: 'TOP20',
        percentage: 0,
        completion: 0,
        numberOfQuest: 0,
        appearInSurveys: [],
      },
    ];
    let questionSheets: QuestionSheet[] = [];
    let surveyCore: SurveyCore = new SurveyCore({
      name: 'surveyCore',
      children: [],
      score: 0,
      id: uuid(),
    });
    let i = 1;

    try {
      fileData.forEach(element => {
        if (element.processArea) {
          element = FileService.lowercaseStrings(element);
          i++;
          const res: any = FileService.createQuestionSheet(
            questionSheets,
            completionByField,
            element
          );
          questionSheets = res.questionSheets;
          completionByField = res.completionByField;
          surveyCore = FileService.createSurveyCore(surveyCore, element, i);
        }
      });

      return {
        surveyCore: surveyCore,
        questionSheets: questionSheets,
        completionByField: completionByField,
      };
    } catch (e) {
      console.log(e);
    }
  }

  static mapDataToAlerts(reviewFile): Alert[] {
    const alerts = [];

    reviewFile.forEach(element => {
      if (element.alertType && element.alertContent) {
        const alert = new Alert({
          type: element.alertType.toLowerCase(),
          content: element.alertContent.toLowerCase(),
          priority: element.alertPriority ? parseInt(element.alertPriority) : 99,
        });
        alerts.push(alert);
      }
    });
    return alerts;
  }

  private static findOrPush(array, element, elementSearchField, i?) {
    let index = array.findIndex(
      elem => elem.name && elem.name.toLowerCase() === elementSearchField.toLowerCase()
    );
    if (index < 0) {
      array.push(element);
      index = array.length - 1;
      i++;
    }
    return {
      index: index,
      array: array,
      i: i,
    };
  }

  private static insertToSurvey(array, element, field, i) {
    const elementName = field === 'top20' ? 'top20' : element[field].toLowerCase();
    const elementToPush = new SurveyCore({
      name: elementName,
      target: field === 'processArea' || field === 'function' ? parseFloat(element.target) : null,
    });
    const res = FileService.findOrPush(array, elementToPush, elementName, i);
    return res.index;
  }

  private static createSurveyCore(core, element, i) {
    if (!element.questionTitle) {
      return;
    }

    const processAreaIndex = FileService.insertToSurvey(core.children, element, 'processArea', i);
    core.children[processAreaIndex].target =
      core.children[processAreaIndex].target || parseFloat(element.target);
    let functions = core.children[processAreaIndex].children;
    let functionIndex = FileService.insertToSurvey(functions, element, 'function', i);
    let categories = functions[functionIndex].children;
    let categoryIndex = FileService.insertToSurvey(categories, element, 'category', i);
    let subCategories = categories[categoryIndex].children;
    let subCategoryIndex = FileService.insertToSurvey(subCategories, element, 'subCategory', i);
    let controls = subCategories[subCategoryIndex].children;
    let controlNameIndex = FileService.insertToSurvey(controls, element, 'controlName', i);

    const question = FileService.questionFromCsvElm(element);

    const questionInSurvey = new SurveyCore({
      name: element.controlName ? element.controlName.toLowerCase() : null,
      questionId: element.questionId ? element.questionId : null,
      question: question,
      completion: 0,
      todo: element.todo ? element.todo : null,
      score: element.score ? parseInt(element.score) : 0,
      label: element.label ? element.label.toLowerCase() : null,
      description: element.description ? element.description : null,
      priority: element.sal ? element.sal.toLowerCase() : ImpactTextEnum.LOW,
      standards: element.standard ? [element.standard] : null,
      processArea: element.processArea ? element.processArea : null,
      function: element.function ? element.function : null,
      category: element.category ? element.category : null,
      subCategory: element.subCategory ? element.subCategory : null,
    });

    controls[controlNameIndex].children.push(questionInSurvey);

    if (question.isTop20) {
      const top20Index = FileService.insertToSurvey(core.children, element, 'top20', i);
      core.children[top20Index].target =
        core.children[top20Index].target || parseFloat(element.target);
      functions = core.children[top20Index].children;
      functionIndex = FileService.insertToSurvey(functions, element, 'function', i);
      categories = functions[functionIndex].children;
      categoryIndex = FileService.insertToSurvey(categories, element, 'category', i);
      subCategories = categories[categoryIndex].children;
      subCategoryIndex = FileService.insertToSurvey(subCategories, element, 'subCategory', i);
      controls = subCategories[subCategoryIndex].children;
      controlNameIndex = FileService.insertToSurvey(controls, element, 'controlName', i);

      const questionTop20InServey = {
        ...questionInSurvey,
        id: uuid(),
        question: { ...questionInSurvey.question },
      };
      controls[controlNameIndex].children.push(questionTop20InServey);
    }

    return core;
  }

  private static lowercaseStrings(element) {
    Object.keys(element).forEach(key => {
      if (key === 'questionTitle' || key === 'todo' || key === 'description') {
        return;
      }
      if (typeof element[key] === 'string') {
        if (key === 'questionTitle' || key === 'description') {
          return;
        }
        element[key] = element[key].toLowerCase();
      }
    });
    return element;
  }

  private static questionFromCsvElm(element) {
    const answers = [];
    const dontKnow =
      element.questionTitle && UtilsService.isHebrew(element.questionTitle)
        ? 'לא יודע לענות'
        : AnswerEnum.NOT_APPLICABLE;
    answers.push(dontKnow);
    Object.keys(element).forEach(key => {
      if (element[key] && key.includes('answer')) {
        answers.push(element[key]);
      }
    });
    // check if the question(element) is also in TOP20
    const top20Idx = Object.keys(element).find(key => key.includes('TOP20'));
    const isTop20 = element[top20Idx] || element[top20Idx] === 'TRUE';
    return new Question({
      id: element.questionId,
      name: element.questionTitle ? element.questionTitle : null,
      title: element.questionTitle ? element.questionTitle : null,
      answers: answers,
      selection: null,
      input: null,
      weight: element.weight ? parseFloat(element.weight) : 1,
      files: null,
      field: element.processArea,
      processArea: element.processArea,
      function: element.function,
      category: element.category,
      subCategory: element.subCategory,
      controlName: element.controlName,
      isTop20: isTop20,
      isArtifactsMandatory: element.isArtifactsMandatory,
      artifactsTag: element.artifactsTag,
      description: element.description,
      sal: element.sal ? element.sal.toLowerCase() : ImpactTextEnum.LOW,
      todo: element.todo ? element.todo : null,
      decline: new Denied(),
    });
  }

  private static createQuestionSheet(questionSheets, completionByField, element) {
    if (element.questionTitle) {
      const questionInQuestionnaire = FileService.questionFromCsvElm(element);

      let top20CompletionIndex = null;
      let fieldCompletionIndex = completionByField.findIndex(
        field => field.name === element.processArea
      );
      if (fieldCompletionIndex > -1) {
        completionByField[fieldCompletionIndex].numberOfQuest++;
      } else {
        completionByField.unshift({
          name: element.processArea,
          percentage: 0,
          completion: 0,
          numberOfQuest: 1,
          appearInSurveys: [],
        });
        fieldCompletionIndex = 0;
      }

      if (questionInQuestionnaire.isTop20) {
        top20CompletionIndex = completionByField.findIndex(
          field => field.name.toLowerCase() === 'TOP20'.toLowerCase()
        );
        completionByField[top20CompletionIndex].numberOfQuest++;
      }

      Object.keys(element).forEach(key => {
        if (key.includes('surveyName_')) {
          if (element[key] || element[key] === 'TRUE') {
            let surveyInFieldIndex = null;
            const surveyName = key.substring(11, key.indexOf('!%!')).trim();
            if (surveyName.includes('TOP20')) {
              fieldCompletionIndex = completionByField.findIndex(
                field => field.name.toLowerCase() === 'TOP20'.toLowerCase()
              );
              surveyInFieldIndex = completionByField[
                fieldCompletionIndex
              ].appearInSurveys.findIndex(survey => survey.name === surveyName);
              if (surveyInFieldIndex > -1) {
                completionByField[top20CompletionIndex].appearInSurveys[surveyInFieldIndex]
                  .numberOfQuest++;
              } else {
                completionByField[top20CompletionIndex].appearInSurveys.push({
                  name: surveyName,
                  numberOfUsers: 0,
                  numberOfQuest: 1,
                });
              }
            } else {
              surveyInFieldIndex = completionByField[
                fieldCompletionIndex
              ].appearInSurveys.findIndex(survey => survey.name === surveyName);
              if (surveyInFieldIndex > -1) {
                completionByField[fieldCompletionIndex].appearInSurveys[surveyInFieldIndex]
                  .numberOfQuest++;
              } else {
                completionByField[fieldCompletionIndex].appearInSurveys.push({
                  name: surveyName,
                  numberOfUsers: 0,
                  numberOfQuest: 1,
                });
              }
            }

            let sheetIndex = questionSheets.findIndex(sheet => sheet.name === surveyName);
            if (sheetIndex < 0) {
              questionSheets.push({
                name: surveyName,
                numberOfQuest: 0,
                numberOfUsers: 0,
                sheet: {},
                completion: 0,
                date: Date.now(),
              });
              sheetIndex = questionSheets.length - 1;
            }
            questionSheets[sheetIndex].numberOfQuest = questionSheets[sheetIndex].numberOfQuest + 1;
            questionSheets[sheetIndex].sheet = questionSheets[sheetIndex].sheet || {};
            questionSheets[sheetIndex].sheet[element.processArea] =
              questionSheets[sheetIndex].sheet[element.processArea] || {};
            questionSheets[sheetIndex].sheet[element.processArea][element.function] =
              questionSheets[sheetIndex].sheet[element.processArea][element.function] || {};
            questionSheets[sheetIndex].sheet[element.processArea][element.function][
              element.category
            ] =
              questionSheets[sheetIndex].sheet[element.processArea][element.function][
              element.category
              ] || {};
            if (element.controlName) {
              questionSheets[sheetIndex].sheet[element.processArea][element.function][
                element.category
              ][element.subCategory] =
                questionSheets[sheetIndex].sheet[element.processArea][element.function][
                element.category
                ][element.subCategory] || {};
              questionSheets[sheetIndex].sheet[element.processArea][element.function][
                element.category
              ][element.subCategory][element.controlName] =
                questionSheets[sheetIndex].sheet[element.processArea][element.function][
                element.category
                ][element.subCategory][element.controlName] || [];
              questionSheets[sheetIndex].sheet[element.processArea][element.function][
                element.category
              ][element.subCategory][element.controlName].push(questionInQuestionnaire);
            }
          }
        }
      });
    }

    return { questionSheets, completionByField };
  }

  constructor(private authService: AuthService, private http: HttpClient) { }
  /////////////////////////////////// File Handel ////////////////////////////////////////////

  getFile(filename: string) {
    return this.http
      .get(`${GET_FILE}/${filename}`, {
        responseType: 'blob',
      })
      .toPromise();
  }

  getExistingZip(file, zip = new JSZip()) {
    return new Promise((resolve, reject) => {
      this.downloadArtifact(file).subscribe(
        fileStream => {
          // console.log('getExistingZip - fileStream:', fileStream);
          zip.loadAsync(fileStream).then(zip => resolve(zip));
        },
        err => {
          console.log('getExistingZip - Error:', err);
          resolve(zip);
        });
    });
  }

  async uploadArtifact(artifactFile: File): Promise<any> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    const formData: any = new FormData();
    formData.append('file', artifactFile);

    try {
      return await this.http.post(UPLOAD_ARTIFACT, formData).toPromise();
    } catch (e) {
      console.log('uploadArtifact - Error:', e);
      return Promise.reject(e);
    }
  }

  downloadArtifact(artifactFile: any) {
    return this.http.post(DOWNLOAD_ARTIFACT, artifactFile, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
    });
  }

  async deleteArtifact(artifactFile: any): Promise<any> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      return await this.http.post(DELETE_ARTIFACT, artifactFile).toPromise();
    } catch (e) {
      console.log('deleteArtifact - Error:', e);
      return Promise.reject(e);
    }
  }

  async uploadStateLogo(logo: File): Promise<any> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    const formData: any = new FormData();
    formData.append('file', logo);

    try {
      return await this.http.post(UPLOAD_STATE_LOGO, formData).toPromise();
    } catch (e) {
      console.log('uploadArtifact - Error:', e);
      return Promise.reject(e);
    }
  }

  async downloadSubEntityData(subEntityId: string, surveyOrReview: any) {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const options: any = { responseType: 'arraybuffer' };
      const body = { agencyId: subEntityId, surveyOrReview };
      const response = await this.http.post(USER_DOWNLOAD_AGENCY_DATA, body, options).toPromise();
      return FileService.downloader(response['_body'], 'data.csv');
    } catch (e) {
      console.log(`downloadSubEntityData - Error: `, e);
      return Promise.reject(e);
    }
  }
}
