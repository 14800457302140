export enum StandardEnum {
  PCI_DSS = 'pci dss',
  FFIEC = 'ffiec',
  GDPR = 'gdpr',
  HIPAA = 'hipaa',
  // HITRUST = 'hitrust',
  ISO_27001 = 'iso 27001',
  CCPA = 'ccpa',
  // NIST_800_53 = 'nist 800-53',
  NIST_CSF = 'nist csf',
  NIST_800_46 = 'nist 800-46',
  NIST_800_82 = 'nist 800-82',
  NIST_800171 = 'nist 800-171',
  NIST_800_207 = 'nist 800-207',
  SOC_2 = 'soc 2',
  // AZURE_CLOUD = 'azure cloud',
  // AWS_CLOUD = 'aws cloud',
  NIST_COBIT_5 = 'nist cobit 5',
  // NERC = 'nerc',
  FERPA = 'ferpa',
  CMMC = 'cmmc',
  CSA = 'csa',
  GLBA = 'glba',
  NYDFS = 'NYDFS',
  NYDFS_MINIMAL = 'NYDFS MINIMAL',
}
