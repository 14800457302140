import { Injectable } from '@angular/core';

@Injectable()
export class ChartUtilsService {
  static polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = (angleInDegrees * Math.PI) / 180;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    };
  }

  static describeArc(x, y, radius, startAngle, endAngle) {
    const start = ChartUtilsService.polarToCartesian(x, y, radius, endAngle);
    const end = ChartUtilsService.polarToCartesian(x, y, radius, startAngle);
    const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';
    const d = [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      arcSweep,
      0,
      end.x,
      end.y
    ].join(' ');
    return d;
  }
}
