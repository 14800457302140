import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FollowUpModalComponent } from './follow-up-modal/follow-up-modal.component';
import { Vendor } from 'models/vendor.model';
import { FrequencyCheckEnum } from 'app/shared/enums/frequency-check.enum';
import { AccessLevelEnum } from 'app/shared/enums/accessLevel.enum';
import { StatusEnum } from 'app/shared/enums/status.enum';
import { FileService } from 'app/shared/file.service';
import { User } from 'models/user.model';
import { VendorService } from 'app/third-party/vendor.service';
import { AddUserModalComponent } from 'app/shared/add-user/add-user-modal/add-user-modal.component';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'app/shared/utils.service';
import { DataService } from 'app/shared/data.service';
import { ConfirmationModalComponent } from 'app/shared/confirmation-modal/confirmation-modal.component';
import { RoleEnum } from 'app/shared/enums/role.enum';
import { AuthService } from 'app/shared/auth.service';
import { Qualification } from 'models/entity.model';

class VendorUser extends User {
  pendingFlag?: boolean;
}

@Component({
  selector: 'cygov-vendor-overview',
  templateUrl: './vendor-overview.component.html',
  styleUrls: ['./vendor-overview.component.scss'],
})
export class VendorOverviewComponent implements OnInit {
  closeResult: string;

  @Input() vendor: Vendor;
  @Input() groupList: string[];
  @Output() update = new EventEmitter<void>();

  isShow: boolean = false;
  isShowUsers: boolean = false;
  isShowTime: boolean = false;
  isShowColec: boolean = false;
  isShowFollow: boolean = false;
  isShowStatus: boolean = false;

  isVendorReadonly: boolean = true;
  isTimelineReadonly: boolean = true;
  isCollectionReadonly: boolean = true;
  isFollowupReadonly: boolean = true;
  isApprovalReadonly: boolean = true;

  frequencyOptions = Object.keys(FrequencyCheckEnum).map(key => FrequencyCheckEnum[key]);
  accessLevels = Object.keys(AccessLevelEnum).map(key => AccessLevelEnum[key]);
  statusOptions = Object.keys(StatusEnum).map(key => StatusEnum[key]);
  StatusEnum = StatusEnum;
  isCrbAdjustments = UtilsService.isCRB;
  userListHeadElements = ['Name', 'Email', 'Role', 'Phone'];
  vendorUsers: VendorUser[] = [];
  loadingUserList: boolean = false;
  selectedGroup: string;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private vendorService: VendorService,
    private toastr: ToastrService,
    private dataService: DataService
  ) {}

  async ngOnInit() {
    if (this.vendor) {
      this.loadingUserList = true;
      const vendorUsers = (await this.vendorService.getVendorUsers(this.vendor.id)) || [];
      const isAdmin = await this.authService.hasPermission(RoleEnum.ADMIN);
      this.vendorUsers = !isAdmin
        ? vendorUsers.filter(user => user.role !== RoleEnum.PARTICIPANT)
        : vendorUsers;
      this.loadingUserList = false;
    }
  }

  async imgHandler(fileInput: any) {
    if (fileInput && fileInput.target.files[0]) {
      this.vendor.logo = (await FileService.getBase64(fileInput.target.files[0])) as string;
    }
  }

  toggleContent() {
    this.isShow = !this.isShow;
  }

  toggleContentUsers() {
    this.isShowUsers = !this.isShowUsers;
  }

  toggleContentTime() {
    this.isShowTime = !this.isShowTime;
  }

  toggleContentCollection() {
    this.isShowColec = !this.isShowColec;
  }

  toggleContentFollow() {
    this.isShowFollow = !this.isShowFollow;
  }

  toggleContentStatus() {
    this.isShowStatus = !this.isShowStatus;
  }

  toggleVendorReadonly() {
    this.isVendorReadonly = !this.isVendorReadonly;
  }

  toggleTimelineReadonly() {
    this.isTimelineReadonly = !this.isTimelineReadonly;
  }

  toggleCollectionReadonly() {
    this.isCollectionReadonly = !this.isCollectionReadonly;
  }

  toggleFollowupReadonly() {
    this.isFollowupReadonly = !this.isFollowupReadonly;
  }

  toggleApprovalReadonly() {
    this.isApprovalReadonly = !this.isApprovalReadonly;
  }

  reviewFollowUp() {
    this.modalService.open(FollowUpModalComponent, {
      centered: true,
      windowClass: 'follow-up',
    });
  }

  toTimestamp(dateStr: string) {
    return new Date(dateStr).getTime();
  }

  statusChanged(status: StatusEnum): void {
    this.vendor.vendorDetails.status = status;
    if (status === StatusEnum.APPROVED) {
      this.vendor.vendorDetails.approvalDate = Date.now();
    } else {
      this.vendor.vendorDetails.approvalDate = null;
    }
  }

  isApproved(status: StatusEnum) {
    return status === StatusEnum.APPROVED;
  }

  openAddUserModal() {
    const modalRef = this.modalService.open(AddUserModalComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'add-user-modal',
    });

    modalRef.componentInstance.modalResult.subscribe((event: User) => {
      this.addUser(event);
      modalRef.close();
    });
  }

  async addUser(newUser: VendorUser) {
    if (newUser) {
      try {
        newUser.role = RoleEnum.VENDOR_LEADER;
        newUser.affiliateReference.agencies.push({id: this.vendor.id, name: this.vendor.name});
        newUser.pendingFlag = true;
        this.vendorUsers.unshift(newUser);
        console.log('User: ', newUser);
        this.toastr.info('adding user...');
        await this.dataService.createNewUser(newUser);
        this.toastr.success('User added');
        this.vendorUsers[0].pendingFlag = false;
      } catch (e) {
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    }
  }

  openConfirmationModal(user: VendorUser) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'confirmation-modal',
    });
    modalRef.componentInstance.message = `Do you really want to delete ${user.name}?`;
    modalRef.componentInstance.modalResult.subscribe((event: boolean) => {
      if (event) {
        this.removeUser(user);
      }
      modalRef.close();
    });
  }

  async removeUser(user: VendorUser) {
    try {
      user.pendingFlag = true;
      await this.dataService.removeUser(user.id, user.affiliateReference.agencies[0].id);
      this.toastr.success(`${user.name} removed successfully`);
      this.vendorUsers = this.vendorUsers.filter(u => u.id !== user.id);
    } catch (e) {
      if (e.body === "user doesn't exist") {
        this.toastr.info("User didn't exist.. removing from view");
      } else {
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    }
  }

  updateVendor() {
    this.vendor.vendorDetails.group = this.vendor.vendorDetails.group.toLowerCase();
    console.log(this.vendor);
    this.update.emit();
  }

  calcCrbVendorImpact(): void {
    const pointForEachImport = 100 / this.vendor.vendorDetails.impactsQualify.length;
    const requiredImpacts: Qualification[] = this.vendor.vendorDetails.impactsQualify.filter(
      impact => impact.isQualify
    );

    this.vendor.vendorDetails.impact = Math.round(pointForEachImport * requiredImpacts.length);
  }
}
