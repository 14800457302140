import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Output() modalResult = new EventEmitter<boolean>();
  @Input() message: string = 'Are you sure?';
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
