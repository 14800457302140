import { Component, Input, OnInit } from '@angular/core';
import { Entity, Qualification } from '../../../models/entity.model';
import { EntityStandardsService } from './entity-standards.service';
import { AuthService } from 'app/shared/auth.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { AgencyReference } from 'models/agency.model';
import { RoleEnum } from '../enums/role.enum';
import { Vendor } from 'models/vendor.model';
import { UtilsService } from '../utils.service';
import { VendorService } from 'app/third-party/vendor.service';

@Component({
  selector: 'cygov-entity-standards',
  templateUrl: './entity-standards.component.html',
  styleUrls: ['./entity-standards.component.scss']
})
export class EntityStandardsComponent implements OnInit {
  @Input() entity: Entity;
  @Input() subEntityId: string;
  @Input() horizontalView: boolean = false;
  @Input() isVendor: boolean = false;
  standardsQualify: Qualification[] = [];

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private vendorService: VendorService
  ) { }

  async ngOnInit() {
    try {
      let subEntityRef = null;
      switch (true) {
        case this.isVendor && UtilsService.isDefined(this.subEntityId):
          const vendor: Vendor = await this.vendorService.getVendor(this.subEntityId);
          this.standardsQualify = vendor.vendorDetails.standardsQualify.length > 3 ?
            vendor.vendorDetails.standardsQualify.slice(0, 3) : vendor.vendorDetails.standardsQualify;
          break;

        case UtilsService.isDefined(this.subEntityId):
          if (this.entity) {
            subEntityRef = this.entity.agencies.find(ref => ref.id === this.subEntityId);
          } else {
            const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
            const subEntity: Entity = (await this.authService.hasPermission(
              RoleEnum.SUB_ENTITY_LEADER,
              entityId,
              this.subEntityId
            ))
              ? await this.dataService.getSubEntity(this.subEntityId)
              : null;
            subEntityRef =
              subEntity && subEntity.details.reference
                ? new AgencyReference(subEntity.details.reference)
                : null;
          }

          this.standardsQualify = EntityStandardsService.buildSubEntityStandardsQualify(
            subEntityRef
          );
          break;

        case UtilsService.isDefined(this.entity) && this.entity.agencies.length > 0:
          this.standardsQualify = EntityStandardsService.buildEntityStandardsQualify(
            this.entity.agencies
          );
          break;
      }
    } catch (e) {
      console.log('EntityStandardsComponent - Error: ', e);
    }
  }
}
