import { SelectModule } from './../select/select.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UiToolsComponent } from './ui-tools.component';
import { AddClientModalComponent } from './add-client-modal/add-client-modal.component';
import { AddSubEntityModalComponent } from './add-sub-entity-modal/add-sub-entity-modal.component';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { ToggleButtonModule } from '../toggle-button/toggle-button.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    UiToolsComponent,
    AddClientModalComponent,
    AddSubEntityModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SvgIconModule,
    NgbTooltipModule,
    ToggleButtonModule,
    CheckboxModule,
    NgSelectModule,
    SelectModule
  ],
  exports: [UiToolsComponent]
})
export class UiToolsModule { }
