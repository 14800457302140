import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoggerService {
  private static isDebug = environment.debug;

  public static log(...args: any[]): void {
    if (LoggerService.isDebug) {
      console.log(args);
    }
  }
  public static error(...args: any[]): void {
    if (LoggerService.isDebug) {
      console.error(args);
    }
  }
  public static setDebug(state: boolean): void {
    LoggerService.isDebug = state;
  }

}
