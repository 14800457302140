import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpParams,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap } from 'rxjs/operators';



@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  private async addConfig(request: HttpRequest<any>) {
    const user = this.authService.getCurrentUserSync();

    // Add Headers
    const headers: any = {};
    request.headers
      .keys()
      .forEach(key => (headers[key] = request.headers.getAll(key)));

    // Clickjacking protection
    headers['X-Frame-Options'] = 'DENY';
    headers['Content-Security-Policy'] = `frame-ancestors 'none'`;
    // X-Content-Type-Options is used to protect against MIME sniffing vulnerabilities
    headers['X-Content-Type-Options'] = 'nosniff';
    // X-XSS-Protection  Preventing Cross-Site Scripting Attacks
    headers['X-XSS-Protection'] = '1; mode=block';

    // Add Params
    const params: any = {};
    request.params
      .keys()
      .forEach(key => (params[key] = request.params.getAll(key)));
    params.domain = `${location.protocol}//${window.location.host}`;

    if (await this.authService.isAuthenticated()) {
      params.session = await this.authService.getCurrentSession();
    }

    if (user && user.id) {
      params.userId = user.id;
      params.stateId = request.params.has('stateId')
        ? request.params.get('stateId')
        : user.affiliateReference.states[0].id;
      params.agencyId = request.params.has('agencyId')
        ? request.params.get('agencyId')
        : user.affiliateReference.agencies[0].id;

      params.entityId = request.params.has('entityId')
        ? request.params.get('entityId')
        : user.affiliateReference.states[0].id;
      params.subEntityId = request.params.has('subEntityId')
        ? request.params.get('subEntityId')
        : user.affiliateReference.agencies[0].id;
    }
    return request.clone({
      headers: new HttpHeaders({ fromObject: headers }),
      params: new HttpParams({ fromObject: params }),
      withCredentials: true
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return fromPromise(this.addConfig(request)).pipe(
      switchMap(req => {
        return next.handle(req)
      })
    );
  }
}
