import { Component, ElementRef, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Entity } from '../../../models/entity.model';
import { NgxImageCompressService } from 'ngx-image-compress';
import { FileService } from 'app/shared/file.service';
import { UtilsService } from 'app/shared/utils.service';

export class FileType {
  name: string;
  data: any;
}

@Component({
  selector: 'cygov-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
})
export class EntityListComponent implements OnInit {
  newState: Entity;
  cachedStatesDetails: Entity[];
  displayStates: Entity[] = [];
  queryText: string = '';
  imageFile: any;
  alertsFile: FileType;
  page: any;
  selectedState = null;
  modalReference: any;

  logoFileSizeLimit = 1000000; // 1MB

  constructor(
    private dataService: DataService,
    private fileService: FileService,
    private modalService: NgbModal,
    private router: Router,
    private elem: ElementRef,
    private toastr: ToastrService,
    private imageCompress: NgxImageCompressService
  ) {}

  async ngOnInit(fromCache = true) {
    this.resetState();
    try {
      this.cachedStatesDetails = await this.dataService.getAllEntities(fromCache);
      this.displayStates = this.cachedStatesDetails;
    } catch (e) {
      console.log('EntityListComponent - Error: ', e);
    }
  }

  goToStateManagement(selectedState) {
    if (selectedState) {
      this.router.navigate([`admin/${selectedState.id}/sub-entity-list`]);
    } else {
      this.toastr.info('Please select entity from the list');
    }
  }

  selectState(state, i) {
    this.selectedState = {
      id: state.id,
      name: state.name,
      row: i,
    };
  }

  searchStates() {
    this.displayStates = this.cachedStatesDetails.filter(state => {
      return (
        !this.queryText ||
        state.name.toLocaleLowerCase().includes(this.queryText.toLocaleLowerCase())
      );
    });
  }

  async addState() {
    this.toastr.info('Adding State...');
    if (!this.isValid(this.newState)) {
      return;
    }
    try {
      this.newState.logo = (await FileService.getBase64(this.imageFile)) as string; // await this.uploadLogoToS3();
      this.newState.alerts = this.alertsFile
        ? FileService.mapDataToAlerts(this.alertsFile.data)
        : null;
      this.dataService.addEntity(this.newState).then(
        () => {
          this.toastr.success('State added successfully!');
          this.ngOnInit(false);
        },
        e => {
          this.toastr.error('Failed to create state');
          const message = UtilsService.msgFromError(e);
          this.toastr.error(message);
        }
      );
    } catch (e) {
      console.log('addState - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  async uploadLogoToS3() {
    if (this.imageFile) {
      try {
        const uploadedFile = await this.fileService.uploadStateLogo(this.imageFile);
        return uploadedFile.Location;
      } catch (e) {
        console.log('uploadLogoToS3 - Error: ', e);
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    }
  }

  resetState() {
    this.newState = new Entity();
  }

  async imgHandler(fileInput) {
    this.imageFile = fileInput.target.files[0] as any;
    if (this.imageFile.size > this.logoFileSizeLimit) {
      const uploadedSize = this.imageFile.size;
      console.log(this.imageFile.size);
      let image = (await FileService.getBase64(this.imageFile)) as string;
      const imageName = this.imageFile.name;

      const difference = uploadedSize - this.logoFileSizeLimit;
      const ratio = 100 - (difference / uploadedSize) * 100;

      this.imageCompress.compressFile(image, null, ratio, 100).then(result => {
        image = result;
        this.imageFile = this.dataURLtoBlob(image);
        this.imageFile.name = imageName;
      });
    }
  }

  dataURLtoBlob(dataurl) {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  isValid(entity: Entity) {
    let valid = true;
    if (!entity.name) {
      this.toastr.error('Entity name is required');
      valid = false;
    }
    if (!this.imageFile) {
      this.toastr.error('Entity logo is required');
      valid = false;
    }

    return valid;
  }

  removeState(selectedState) {
    if (selectedState) {
      this.toastr.info('Removing state');
      this.dataService
        .removeEntity(selectedState.id)
        .then(() => {
          setTimeout(() => {
            this.toastr.success('State removed successfully');
            this.ngOnInit(false);
          }, 3000);
        })
        .catch(e => {
          console.log(e);
          const message = UtilsService.msgFromError(e);
          this.toastr.error(message);
        });
    } else {
      this.toastr.info('Please select entity from the list');
    }
  }

  async generateFakeData(stateId) {
    this.closeModal();
    if (stateId) {
      try {
        this.toastr.info('Generating fake data...');
        await this.dataService.createFakeData(stateId);
        this.toastr.success('Fake data  added successfully!');
        this.ngOnInit(false);
      } catch (e) {
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    } else {
      console.log('generateFakeData: stateId is required');
      this.toastr.error('Please select state.');
    }
  }

  openModal(content) {
    this.modalReference = this.modalService.open(content);
  }

  closeModal() {
    this.modalReference.close();
  }

  downloadFile(alerts) {
    const dataAsString =
      'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(alerts));
    const link = document.createElement('a');
    link.setAttribute('href', dataAsString);
    link.setAttribute('download', 'scene.json');
    link.click();
  }

  fireUploadFile(id: string) {
    event.preventDefault();
    const inputElement: HTMLElement = this.elem.nativeElement.querySelector(id);
    inputElement.click();
  }

  async alertsHandler(event) {
    if (event.target.files && event.target.files.length) {
      try {
        const { data } = await FileService.fileToCsv(event);
        this.alertsFile = {
          data: data,
          name: event.target.files[0].name,
        };
        this.toastr.success('Survey file loaded');
      } catch (e) {
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    }
  }

  async replaceAlertsHandler(event) {
    if (event.target.files && event.target.files.length) {
      try {
        const { data } = await FileService.fileToCsv(event);
        const curState = this.cachedStatesDetails.find(({ id }) => id === this.selectedState.id);
        if (!curState) {
          this.toastr.error('Faild to update state. State not found');
          return;
        }
        curState.alerts = FileService.mapDataToAlerts(data);
        const newState = await this.dataService.updateEntity(curState);
        this.cachedStatesDetails = this.cachedStatesDetails.map(state => {
          if (state.id === curState.id) {
            return newState;
          }
          return state;
        });
        this.toastr.success('Survey file loaded');
      } catch (e) {
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    }
  }
}
