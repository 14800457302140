
export class EntityRevision {
  id: string;
  agencyId: string;
  agencyName: string;
  scores: any[];
  processAreaScoreMap: any;
  revisionDate: number;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.agencyId = obj.agencyId;
    this.agencyName = obj.agencyName;
    this.scores = obj.scores;
    this.processAreaScoreMap = obj.processAreaScoreMap;
    this.revisionDate = obj.revisionDate || Date.now();
  }
}

export class RevisionRef {
  revisionId: string;
  revisionDate: number;

  constructor(obj: any = {}) {
    this.revisionId = obj.revisionId;
    this.revisionDate = obj.revisionDate || Date.now();
  }
}
