import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { UtilsService } from '../utils.service';

@Directive({
  selector: '[cygovScoreBgColor]'
})
export class ScoreBgColorDirective implements OnChanges {

  @Input() score: number;

  constructor(private element: ElementRef,
              private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    const score: string = changes.score.currentValue;
    if (UtilsService.isDefined(score)) {
      this.addScoreClass(score);
    }
  }

  private addScoreClass(score) {
    let className = null;
    switch (true) {
      case (score <= 3.33):
        className = 'score-low-bg';
        break;
      case (score < 6.66):
        className = 'score-medium-bg';
        break;
      case (score >= 6.66):
        className = 'score-high-bg';
        break;
    }

    this.renderer.addClass(this.element.nativeElement, className);
  }

}
