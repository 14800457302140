import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as uuid from 'uuid';

import { GENERATOR_USERS } from '../../app.paths';
import * as Models from './generator.model';
import { FileService } from 'app/shared/file.service';
import { DataService, BulkData } from 'app/shared/data.service';
import { CollectionStatusEnum } from 'app/shared/enums/collection-status.enum';
import { UtilsService } from 'app/shared/utils.service';
import Lorem from './utils/lorem';
import { Entity } from 'models/entity.model';
import GeneratorHelpers from './generator.helpers';
import { ImpactTextEnum } from 'app/shared/enums/impact.enum';

const COLLECTION_STATUSES = Object.values(CollectionStatusEnum);

@Injectable({
  providedIn: 'root',
})
export class GeneratorService {
  options: Models.Options = {
    entity: {
      creation: 'create',
    },
    subEntity: {
      amount: 1,
    },
    vendor: {
      amount: 0,
    },
    user: {
      amount: 1,
    },
  };
  isCrbAdjustments = UtilsService.isCRB;

  entities: Entity[] = [];
  entity: Models.Entity = {
    id: uuid(),
    name: UtilsService.capitalizeFirstLetter(Lorem.word()),
    logo: '',
    alerts: [],
  };
  selectedEntity: Entity = null;

  subEntities: Models.SubEntity[] = [];
  subEntityStages = COLLECTION_STATUSES;

  vendors: Models.Vendor[] = [];

  users: Models.User[] = [];
  userData: Models.UserData[] = [];
  numberOfTries = 0;
  isLoading = false;

  constructor(
    private dataService: DataService,
    private toastr: ToastrService,
    private httpClient: HttpClient
  ) {
    this.isLoading = true;
    this.loadData().finally(() => (this.isLoading = false));
  }

  buildSubEntity(): Models.SubEntity {
    const prev = this.subEntities.length
      ? this.subEntities[this.subEntities.length - 1]
      : { survey: { data: {} }, dueDate: Date.now(), stage: CollectionStatusEnum.HAS_NOT_BEGUN };
    return {
      ...prev,
      id: uuid(),
      name: UtilsService.capitalizeFirstLetter(Lorem.word()),
    };
  }

  async buildVendor(): Promise<Models.Vendor> {
    const prev = this.vendors.length
      ? this.vendors[this.vendors.length - 1]
      : { dueDate: Date.now(), impact: ImpactTextEnum.HIGH, artifacts: [], certifications: [] };
    const poc = await this.buildCommonUser();
    const participant = await this.buildCommonUser();
    const artifacts = GeneratorHelpers.artifactsStub();
    const certifications = GeneratorHelpers.certificationsStub();
    return {
      ...prev,
      id: uuid(),
      name: UtilsService.capitalizeFirstLetter(Lorem.word()),
      poc,
      participant,
      artifacts,
      certifications,
    };
  }

  async loadUserData(amount = 1000): Promise<Models.UserData[]> {
    if (this.userData.length > 0) {
      return this.userData;
    }
    if (this.numberOfTries > 3) {
      this.toastr.error("Server can't generate random users please try later");
      return Array(amount).fill({ email: '', name: '' });
    }
    this.numberOfTries += 1;
    try {
      this.isLoading = true;
      const result = <Models.UserData[]>(
        await this.httpClient.get(GENERATOR_USERS(amount)).toPromise()
      );
      this.numberOfTries = 0;
      this.isLoading = false;
      return result;
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
      this.isLoading = false;
      return [];
    }
  }

  async buildCommonUser(): Promise<Models.UserData> {
    if (this.userData.length === 0) {
      this.userData = await this.loadUserData();
    }
    return this.userData.pop();
  }

  buildCrbUser({ name, id }: Models.SubEntity): Models.UserData {
    const withoutSpacialChars = name.replace(/[^a-zA-Z0-9] /g, '');
    return {
      name: `${name} Participant`,
      email: `${withoutSpacialChars.split(' ').join('-')}-Participant-${id}@example.com`,
    };
  }

  async buildUser(subEntity: Models.SubEntity, questionSheetName: string): Promise<Models.User> {
    const { name, email } = await (this.isCrbAdjustments
      ? this.buildCrbUser(subEntity)
      : this.buildCommonUser());
    return {
      id: uuid(),
      name,
      email,
      subEntityId: subEntity.id,
      questionSheetName,
    };
  }

  async loadData(fromCache = true) {
    this.entities = await this.dataService.getAllEntities(fromCache);
    this.userData = await this.loadUserData();
    this.subEntities = [this.buildSubEntity()];
    this.setUsersAmount(1);
  }

  async loadHandler({ target }, callback: Models.FileUploadCallback): Promise<any> {
    const { files = [] } = target;
    const [firstFile] = files;
    try {
      const result = await callback(firstFile);
      this.toastr.success('File was uploaded successfully');
      return result;
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
      return null;
    }
  }

  logoLoader: Models.FileUploadCallback = async (file: File) => {
    const result = <string>await FileService.getBase64(file);
    this.entity.logo = result;
    return result;
  };

  alertsLoader: Models.FileUploadCallback = async (file: File) => {
    const { data } = await FileService.parseFile(file);
    const result = FileService.mapDataToAlerts(data);
    this.entity.alerts = result;
    return result;
  };

  surveyLoader: Models.FileUploadCallback = async (file: File) => {
    const { data: dataFile } = await FileService.parseFile(file);
    return {
      dataFile,
      data: FileService.mapDataToSurveyCore(dataFile),
      filename: file.name,
    };
  };

  setSelectedEntity(selectedId: string) {
    this.selectedEntity = this.entities.find(({ id }) => id === selectedId);
  }

  setSubEntitiesAmount(amount: number) {
    if (amount === this.options.subEntity.amount) {
      return;
    }
    this.options.subEntity.amount = amount;
    if (amount <= this.subEntities.length) {
      this.subEntities = this.subEntities.slice(0, amount);
    } else {
      const add: Models.SubEntity[] = Array(amount - this.subEntities.length)
        .fill(null)
        .map(() => this.buildSubEntity());
      this.subEntities = this.subEntities.concat(add);
    }
    this.setUsersAmount(this.options.user.amount);
  }

  async setVendorsAmount(amount: number) {
    if (amount === this.options.vendor.amount) {
      return;
    }
    this.options.vendor.amount = amount;
    if (amount <= this.vendors.length) {
      this.vendors = this.vendors.slice(0, amount);
    } else {
      const add: Models.Vendor[] = await Promise.all(
        Array(amount - this.vendors.length)
          .fill(null)
          .map(() => this.buildVendor())
      );
      this.vendors = this.vendors.concat(add);
    }
  }

  async setUsersAmount(amount: number): Promise<void> {
    this.options.user.amount = amount;
    const promises = this.subEntities.flatMap((subEntity: Models.SubEntity) => {
      const { questionSheets = [] } = subEntity.survey.data;
      const mapper = ({ name }) =>
        Array(amount)
          .fill(null)
          .map(() => this.buildUser(subEntity, name));
      const subEntityUsers = questionSheets.flatMap(mapper);
      return subEntityUsers;
    });
    this.users = await Promise.all(promises);
  }

  subEntityQuestionnaries(subEntityId: string) {
    const subEntity = this.subEntities.find(({ id }) => id === subEntityId);
    if (subEntity) {
      return subEntity.survey.data.questionSheets.map(({ name }) => name);
    }
    return [];
  }

  async getSurveyData(): Promise<any[]> {
    const filePath: string = `${window.location.origin}/assets/files/vendor-survey.csv`;
    const file = await this.httpClient.get(filePath, { responseType: 'text' }).toPromise();
    const { data } = await FileService.parseFile(file);
    return data;
  }

  async buildData(): Promise<BulkData> {
    const entity =
      this.options.entity.creation === 'create' ? new Entity(this.entity) : this.selectedEntity;
    const subEntities = this.subEntities.map(GeneratorHelpers.subEntityMapper(entity));
    let vendors = [];
    if (this.vendors.length > 0) {
      const surveyData = await this.getSurveyData();
      vendors = this.vendors.map(GeneratorHelpers.vendorMapper(entity, surveyData));
    }
    const users = this.users.map(GeneratorHelpers.userMapper(entity, this.subEntities));
    return {
      entity,
      subEntities,
      vendors,
      users,
      options: this.options,
    };
  }

  async generateEntity() {
    this.toastr.info('Generator started!');
    try {
      const body = await this.buildData();
      await this.dataService.bulkCreate(body);
      this.toastr.success('Generator finished');
    } catch (e) {
      console.log(e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  deleteEntity() {
    console.log('Deleting...');
  }
}
