import { Component, OnInit, Input } from '@angular/core';
import { GeneratorService } from '../../generator.service';
import { SubEntity } from '../../generator.model';

@Component({
  selector: 'cygov-new-sub-entity-settings',
  templateUrl: './new-sub-entity-settings.component.html',
  styleUrls: ['./new-sub-entity-settings.component.scss']
})
export class NewSubEntitySettingsComponent implements OnInit {
  @Input() subEntity: SubEntity;

  constructor(
    public service: GeneratorService,
  ) { }

  ngOnInit() { }

  async loadSurveyCore(event) {
    this.subEntity.survey = await this.service.loadHandler(event, this.service.surveyLoader);
    // ToDo: move next line logic inside service
    this.service.setUsersAmount(this.service.options.user.amount);
  }

  setDate({ target }) {
    this.subEntity.dueDate = target.value;
  }
}
