import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DataService } from '../shared/data.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../models/user.model';
import { Entity } from '../../models/entity.model';
import { EntityStandardsService } from '../shared/entity-standards/entity-standards.service';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { RoleEnum } from '../shared/enums/role.enum';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'cygov-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NgbDropdownConfig]
})
export class HeaderComponent implements OnInit, OnDestroy {
  notificationCount: number = 3;
  user: User;
  entity: Entity = null;
  subEntity: Entity = null;
  routeChangedSubscription: any;
  complianceQualification: string;
  showEntitySummary = false;
  isAdmin: boolean = false;
  loading: boolean = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private toastr: ToastrService,
    config: NgbDropdownConfig
  ) {
    config.placement = 'bottom-right';
  }

  async ngOnInit() {
    this.subscribeToRouteChange();
    try {
      this.user = await this.authService.getCurrentUser();
      await this.updateEntities();
      this.loading = false;
    } catch (e) {
      console.log(e);
      this.toastr.error('HeaderComponent Error');
      this.loading = false;
    }
  }

  async updateEntity(entityId: string) {
    this.entity = entityId ? await this.dataService.getEntity(entityId) : null;
    this.complianceQualification = this.entity ? this.getComplianceQualification(this.entity) : null;
  }
  async updateSubEntity(subEntityId: string) {
    this.subEntity = subEntityId ? await this.dataService.getSubEntity(subEntityId) : null;
  }
  async updateEntities() {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    const subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
    switch (true) {
      case await this.authService.hasPermission(RoleEnum.ADMIN, entityId):
        this.isAdmin = true;
        await this.updateEntity(entityId);
        await this.updateSubEntity(subEntityId);
        break;
      case await this.authService.hasPermission(RoleEnum.ENTITY_LEADER, entityId):
        await this.updateEntity(entityId);
        await this.updateSubEntity(subEntityId);
        break;
      case await this.authService.hasPermission(RoleEnum.SUB_ENTITY_LEADER, entityId, subEntityId):
        await this.updateSubEntity(subEntityId);
        break;
      default:
        break;
    }
  }

  subscribeToRouteChange() {
    this.routeChangedSubscription = this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.updateEntities();
        this.showEntitySummary = !(val.url.includes('clients')
          || val.url.includes('upperdeck') || val.url.includes('third-party'));
      }
    });
  }

  getComplianceQualification(entity: Entity): string {
    const standardsQualify = EntityStandardsService.buildEntityStandardsQualify(entity.agencies);
    const { qualifyCounter, totalCounter } = standardsQualify.reduce((cur, { isQualify }) => ({
      totalCounter: cur.totalCounter + 1,
      qualifyCounter: cur.qualifyCounter + 1 * (isQualify ? 1 : 0)
    }), { qualifyCounter: 0, totalCounter: 0 });
    return `${qualifyCounter} / ${totalCounter}`;
  }

  signOut() {
    this.toastr.info('Logging out...');
    this.authService
      .logOut()
      .then(() => {
        this.toastr.success('Logged out');
        this.router.navigate(['/login']);
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Log out failed');
      });
  }

  goHomeByRole() {
    this.authService.navigateByRole();
  }

  goToEntityList() {
    if (this.user.role === RoleEnum.ADMIN) {
      this.router.navigate([`admin/entity-list`]);
    } else {
      this.toastr.error('Unauthorized');
    }
  }

  goToBinaryEdge() {
    if (this.isAdmin) {
      this.router.navigate(['binary-edge']);
    }
  }

  goToGenerator() {
    if (this.isAdmin) {
      this.router.navigate(['admin/generator']);
    }
  }

  goToFindings() {
    if (this.isAdmin) {
      this.router.navigate(['findings']);
    }
  }

  ngOnDestroy(): void {
    this.entity = null;
    this.subEntity = null;
    if (this.routeChangedSubscription) {
      this.routeChangedSubscription.unsubscribe();
    }
  }
}
