import { Component, OnInit } from '@angular/core';

import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-follow-up-modal',
  templateUrl: './follow-up-modal.component.html',
  styleUrls: ['./follow-up-modal.component.scss']
})
export class FollowUpModalComponent implements OnInit {
  items = [
    {
      checked: true
    },
    {
      checked: false
    },
    {
      checked: false
    },
    {
      checked: true
    }
  ];
  closeResult: string;
  modalReference: NgbModalRef;
  constructor(private modalService: NgbModal) {}

  ngOnInit() {
  }

  open(content) {

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
