import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'cutStr' })
export class CutStrPipe implements PipeTransform {
  constructor() {}

  transform(value: string, numberOfChar: number): string {
    if (value.length > numberOfChar) {
      return value.substring(0, numberOfChar).concat('...')
    }
    return value;
  }
}
