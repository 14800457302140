import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { User } from '../../../models/user.model';
import { Observable } from 'rxjs';
import { RoleEnum } from '../enums/role.enum';

@Injectable()
export class AdminRoleGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('canActivate: canActivateChild was called');
    return this.checkUserRole();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('canActivateChild: canActivateChild was called');
    return this.checkUserRole();
  }

  async checkUserRole() {
    try {
      const user: User = await this.authService.getCurrentUser();
      if (user && user.role === RoleEnum.ADMIN) {
        return true;
      } else {
        console.log('checkUserRole - Admin: Unauthorized');
        await this.router.navigate(['/login']);
        return Promise.reject(false);
      }
    } catch (e) {
      console.log('UserRoleGuard - Error: ', e);
      await this.router.navigate(['/login']);
      return Promise.reject(false);
    }
  }
}
