import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { StatusCount } from 'models/status-count.model';

@Component({
  selector: 'cygov-status-widget',
  templateUrl: './status-widget.component.html',
  styleUrls: ['./status-widget.component.scss']
})
export class StatusWidgetComponent implements OnInit {
  @Input() statusCount: StatusCount;

  constructor() {}

  ngOnInit() {}
}
