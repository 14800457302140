import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUserModalComponent } from './add-user-modal/add-user-modal.component';
import { AddParticipantModalComponent } from './add-participant-modal/add-participant-modal.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { FormsModule } from '@angular/forms';
import { SelectModule } from '../select/select.module';

@NgModule({
  declarations: [AddUserModalComponent, AddParticipantModalComponent],
  imports: [CommonModule, SvgIconModule, FormsModule, SelectModule],
  exports: [AddUserModalComponent, AddParticipantModalComponent],
  entryComponents: [AddUserModalComponent, AddParticipantModalComponent],
})
export class AddUserModule {}
