import { QuestionSheet, ShortRef } from './agency.model';
import { RoleEnum } from '../app/shared/enums/role.enum';
import { DocTypeEnum } from '../app/shared/enums/docType.enum';
import { TableEnum } from '../app/shared/enums/table.enum';
import * as uuid from 'uuid';

export class User {
  id: string;
  name: string;
  email: string;
  details: UserDetails;
  role: RoleEnum;
  affiliateReference: UserAffiliation;
  questionSheet: QuestionSheet;
  token: Token;
  resetPasswordToken: Token;

  phone: string;
  position: string;
  surveyId: string;
  entityPermissionList: string[]; // entityId list
  password: string;

  createdAt: number;
  createdBy: string;
  modifiedAt: number;
  modifiedBy: string;
  docType: DocTypeEnum;
  tableName: TableEnum;

  constructor(obj: any = {}) {
    this.id = obj.id || uuid();
    this.name = obj.name;
    this.email = obj.email;
    this.details = new UserDetails(obj.details);
    this.role = obj.role;
    this.affiliateReference = new UserAffiliation(obj.affiliateReference);
    this.questionSheet = new QuestionSheet(obj.questionSheet);
    this.token = new Token(obj.token);
    this.resetPasswordToken = obj.resetPasswordToken;

    this.phone = obj.phone;
    this.position = obj.position;
    this.surveyId = obj.surveyId;
    this.entityPermissionList = obj.entityPermissionList;
    this.password = obj.password;

    this.createdAt = obj.createdAt || Date.now();
    this.createdBy = obj.createdBy;
    this.modifiedAt = obj.modifiedAt || Date.now();
    this.modifiedBy = obj.modifiedBy;
    this.docType = obj.docType || DocTypeEnum.USER;
    this.tableName = obj.tableName;
  }
}

export interface StatisticUser extends User {
  numberOfAnswers: number;
  numberOfUserQuestions: number;
}

export class UserDetails {
  name: string;
  email: string;
  position?: string;
  subDepartment?: string;
  phone?: string;

  constructor(obj: any = {}) {
    this.name = obj.name;
    this.email = obj.email;
    this.position = obj.position;
    this.subDepartment = obj.subDepartment;
    this.phone = obj.phone;
  }
}

export class UserAffiliation {
  agency?: ShortRef;
  state?: ShortRef;
  agencies?: ShortRef[];
  states?: ShortRef[];

  constructor(obj: any = {}) {
    this.agency = obj.agency || new ShortRef();
    this.states = obj.state || new ShortRef();
    this.agencies = obj.agencies || [new ShortRef()];
    this.states = obj.states || [new ShortRef()];
  }
}

export class Token {
  key: string;
  session: string;
  expiration: number;
  lastRequest: number;

  constructor(obj: any = {}) {
    this.key = obj.key;
    this.session = obj.session;
    this.expiration = obj.expiration;
    this.lastRequest = obj.lastRequest;
  }
}
