import { Component, OnInit, Input } from '@angular/core';

import { GeneratorService } from '../../generator.service';
import { User } from '../../generator.model';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'cygov-new-user-settings',
  templateUrl: './new-user-settings.component.html',
  styleUrls: ['./new-user-settings.component.scss'],
})
export class NewUserComponent implements OnInit {
  @Input() user: User;

  isCrbAdjustments = UtilsService.isCRB;

  constructor(public service: GeneratorService) {}

  ngOnInit() {}
}
