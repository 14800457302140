import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'app/shared/data.service';
import { AgencyReference, ShortRef } from 'models/agency.model';
import { Entity } from 'models/entity.model';
import { User, UserAffiliation } from 'models/user.model';
import { RoleEnum } from '../../enums/role.enum';
import { AuthService } from '../../auth.service';
import { UtilsService } from '../../utils.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss'],
})
export class AddUserModalComponent implements OnInit {
  entity: Entity;
  subEntity: Entity;
  subEntityRefList: AgencyReference[];
  @Output() modalResult = new EventEmitter<User>();
  currentUser: User;
  newUser: User = new User({ role: null });
  roleOptions: string[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {}

  async ngOnInit() {
    this.currentUser = await this.authService.getCurrentUser();
    this.newUser.affiliateReference = this.newUser.affiliateReference || new UserAffiliation();
    this.newUser.affiliateReference.agencies =
      this.newUser.affiliateReference.agencies || [];

    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    const subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
    this.roleOptions = this.populateRoleOptions();
    try {
      switch (true) {
        case await this.authService.hasPermission(RoleEnum.ENTITY_LEADER, entityId):
          this.entity = await this.dataService.getEntity(entityId);
          this.subEntityRefList = this.entity.agencies;
          this.newUser.role = this.router.url.includes('third-party')
            ? RoleEnum.VENDOR_LEADER
            : null;
          break;
        case await this.authService.hasPermission(
          RoleEnum.SUB_ENTITY_LEADER,
          entityId,
          subEntityId
        ):
          this.subEntity = await this.dataService.getSubEntity(subEntityId);
          this.subEntityRefList = [this.subEntity.details.reference];
          // sub entity leader can add new users in the same role
          this.newUser.role = this.router.url.includes('third-party')
            ? RoleEnum.VENDOR_LEADER
            : RoleEnum.SUB_ENTITY_LEADER;
          this.newUser.affiliateReference.agencies.push({...this.subEntity.details.reference});
          break;
        default:
          this.toastr.error('Unauthorized');
          break;
      }
    } catch (e) {
      console.log('AddUserModalComponent - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  isValid(user: User) {
    let valid = true;
    // tslint:disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(user.email).toLowerCase())) {
      this.toastr.error('Email is incorrect');
      valid = false;
    }
    if (!user.name) {
      this.toastr.error('User name is required');
      valid = false;
    }
    if (!user.role) {
      this.toastr.error('User role is required');
      valid = false;
    }
    if (user.role === RoleEnum.SUB_ENTITY_LEADER && !user.affiliateReference.agencies[0].id) {
      this.toastr.error('Please select sub entity');
      valid = false;
    }

    return valid;
  }

  emitUser(newUser: User) {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    const entityName = this.entity
      ? this.entity.name
      : this.subEntity
      ? this.subEntity.details.stateReference.name
      : null;
    newUser.affiliateReference.states.push({...this.subEntity.details.stateReference});
    newUser.email = newUser.email.toLowerCase();
    if (newUser.role === RoleEnum.SUB_ENTITY_LEADER && newUser.affiliateReference.agencies[0].id) {
      const subEntityRef = this.subEntityRefList.find(
        subEntityRefItem => !!newUser.affiliateReference.agencies.filter(a => a.id === subEntityRefItem.id).length
      );
      newUser.affiliateReference.agencies[0].name = subEntityRef.name;
    }

    this.modalResult.emit(newUser);
  }

  showSubEntityList(newUserRole) {
    return newUserRole === RoleEnum.SUB_ENTITY_LEADER && this.subEntityRefList;
  }

  populateRoleOptions(): string[] {
    const options: string[] = [];

    switch (this.currentUser.role) {
      case RoleEnum.ADMIN:
        options.push(RoleEnum.ADMIN);
        options.push(RoleEnum.MSSP);
        options.push(RoleEnum.ENTITY_LEADER);
        options.push(RoleEnum.SUB_ENTITY_LEADER);
        break;
      case RoleEnum.MSSP:
        options.push(RoleEnum.MSSP);
        options.push(RoleEnum.ENTITY_LEADER);
        options.push(RoleEnum.SUB_ENTITY_LEADER);
        break;
      case RoleEnum.ENTITY_LEADER:
        options.push(RoleEnum.ENTITY_LEADER);
        options.push(RoleEnum.SUB_ENTITY_LEADER);
        break;
      case RoleEnum.SUB_ENTITY_LEADER:
        options.push(RoleEnum.SUB_ENTITY_LEADER);
        break;
    }
    return options;
  }
}
