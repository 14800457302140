export enum ArtifactsEnum {
  DOCUMENTS_PENTEST = 'penetration testing',
  DOCUMENTS_POLICY = 'information security policy',
  SCANS_NETWORK = 'recent vulnerabilities scans',
  INSURANCE_CYBER = 'cyber insurance policy',

  COMPLIANCE_PCI_DSS = 'pci dss',
  COMPLIANCE_FFIEC = 'ffiec',
  COMPLIANCE_GDPR = 'gdpr',
  COMPLIANCE_HIPAA = 'hipaa',
  COMPLIANCE_HITRUST = 'hitrust',
  COMPLIANCE_ISO_27001 = 'iso 27001',
  COMPLIANCE_CCPA = 'ccpa',
  COMPLIANCE_NIST_800_53 = 'nist 800_53',
  COMPLIANCE_NIST_CSF = 'nist csf',
  COMPLIANCE_NIST_800171 = 'nist 800171',
  COMPLIANCE_SOC_2 = 'soc 2',
  COMPLIANCE_AZURE_CLOUD = 'azure cloud',
  COMPLIANCE_AWS_CLOUD = 'aws cloud',
  COMPLIANCE_NERC = 'nerc',
}
