import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { parseDomain, fromUrl } from 'parse-domain';

@Injectable()
export class UtilsService {
  static get isCRB(): boolean {
    if (window.location.origin.includes('localhost')) {
      return true;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    return subDomains.length ? subDomains.pop().includes('crb') : false;
  }

  static getStringEnum(enumnum) {
    const fields = [];
    Object.keys(enumnum).forEach(key => {
      if (key !== key.toUpperCase()) {
        fields.push(key);
      }
    });
    return fields;
  }

  // help function to get an array instead of tree
  static getArrayFromNestedObject(object: any) {
    const arr = [];
    const traverseSurvey = sheet => {
      Object.keys(sheet).forEach(key => {
        if (sheet[key].answers) {
          arr.push(sheet[key]);
        } else {
          traverseSurvey(sheet[key]);
        }
      });
    };

    traverseSurvey(object);
    return arr;
  }

  static isHebrew(str) {
    return str.match(/[\u0590-\u05FF]+/g);
  }

  static capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static isDefined(variable) {
    return !(typeof variable === 'undefined' || variable === null);
  }

  static isEmpty(variable: string): boolean {
    return !this.isDefined(variable) || variable === '' || variable === 'null';
  }

  /**
   * Returns a random number between min (inclusive) and max (exclusive)
   */
  static getRandomNumber(min: number, max: number, toFixed: number): number {
    let randomNum: any = Math.random() * (max - min) + min;
    if (!isNaN(toFixed)) {
      randomNum = randomNum.toFixed(toFixed);
    }
    return Number(randomNum);
  }

  static getRandomElement(arr: any[]) {
    const index = UtilsService.getRandomNumber(0, arr.length - 1, 0);
    return arr[index];
  }

  static getRandomBoolean() {
    return UtilsService.getRandomElement([true, false]);
  }

  /**
   * Sort array by property.
   * @param array
   * @param prop: string
   * @return sorted array
   */
  static sortByProp(arr, prop) {
    return arr.slice().sort((a, b) => {
      return a[prop] < b[prop] ? -1 : 1;
    });
  }

  /**
   * Group array to object by property.
   * @param array
   * @param prop: string
   * @return grouped object
   */
  static groupBy<T>(arr: T[], prop: string): object {
    // tslint:disable-line
    return arr.reduce((memo, x) => {
      if (!memo[x[prop]]) {
        memo[x[prop]] = [];
      }
      memo[x[prop]].push(x);
      return memo;
    }, {});
  }

  /**
   * generate the object from entries
   * Temporary until Microsoft doesn't support ES2019 in TS
   * The source code: http://2ality.com/2019/01/object-from-entries.html
   * @param iterable list of pairs key-value
   */
  static fromEntries(iterable): { [propName: string]: string } {
    const result = {};
    for (const [key, value] of iterable) {
      let coercedKey;
      if (typeof key === 'string' || typeof key === 'symbol') {
        coercedKey = key;
      } else {
        coercedKey = String(key);
      }
      Object.defineProperty(result, coercedKey, {
        value,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
    return result;
  }

  static stopBubbling(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  static getRouteParam(child: ActivatedRouteSnapshot, key: string) {
    if (child) {
      if (child.params[key]) {
        return child.params[key];
      } else if (child.queryParams[key]) {
        return child.queryParams[key];
      } else {
        return this.getRouteParam(child.firstChild, key);
      }
    } else {
      return null;
    }
  }

  static msgFromError(e): string {
    let message = 'Internal Server Error';
    if (typeof e === 'string') {
      message = e;
    } else if (e.errors) {
      message = `Errors: \n ${e.errors.map(error => error.message).join('\n')}`;
    } else if (e.status === 0) {
      message = 'Server down. Please connect support.';
    } else if (!e.message.includes('Internal Server Error')) {
      message = e.message;
    } else if (!e.error.includes('Internal Server Error')) {
      message = e.error;
    }

    return message;
  }
}
