import { ErrorHandler, Injectable, Injector } from '@angular/core';

@Injectable()
export class CygovErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error) {
    error = error ? error : 'error is undefined';
    const message = error.message ? error.message : error.toString();
    // we can log in external logger. ex: loggly
    console.error(`CygovErrorHandler: ${message}`);
    throw error;
  }
}
