import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Vendor } from 'models/vendor.model';
import { Component, OnInit } from '@angular/core';
import { ThirdPartyService } from '../third-party.service';
import { VendorService } from '../vendor.service';
import { User } from 'models/user.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cygov-all-vendor-list',
  templateUrl: './all-vendor-list.component.html',
  styleUrls: ['./all-vendor-list.component.scss'],
})
export class AllVendorListComponent implements OnInit {
  user: User;
  vendors: Vendor[];
  showDetails: boolean = false;
  vendor: Vendor;
  toggleBarText: string = 'Vender risk dashboard';

  sortBy: string;
  filterBy: string;
  searchText: string;
  vendorId: string;
  cachedVendorList: Vendor[];
  isCrbAdjustments = UtilsService.isCRB;
  groupList: string[];

  constructor(
    private thirdPartyService: ThirdPartyService,
    private vendorService: VendorService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    await this.loadVendorList();
    this.groupList = ThirdPartyService.vendorGroupList(this.vendors);
  }

  async loadVendorList(fromCache = true): Promise<void> {
    try {
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.vendorId = UtilsService.getRouteParam(this.route.root.snapshot, 'vendorId');
      this.vendors = await this.vendorService.getVendorList(entityId, fromCache);
      this.cachedVendorList = [...this.vendors];

      if (this.vendorId) {
        this.vendor = this.vendors.filter(obj => {
          return obj.id === this.vendorId;
        })[0];
        this.showDetails = true;
      }
    } catch (e) {
      console.log(e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  toggleVendorList(isExpanded: boolean) {
    if (isExpanded) {
      this.toggleBarText = 'Vendor risk dashboard';
    } else {
      this.toggleBarText = 'Show all vendors';
    }
    this.thirdPartyService.toggleVendorList(isExpanded);
    this.showDetails = false;
  }

  onVendorSelected(vendor: Vendor) {
    this.showDetails = !this.showDetails;
    this.vendor = vendor;
  }

  onCloseVendorDetail() {
    this.showDetails = !this.showDetails;
    this.vendor = null;
  }

  onVendorRemoved(vendorId: string) {
    this.vendors = this.vendors.filter(vendor => vendor.id !== vendorId);
  }

  async applyFilters() {
    let vendors = [...this.cachedVendorList];

    if (!UtilsService.isEmpty(this.searchText)) {
      vendors = ThirdPartyService.filterVendorListByText(vendors, this.searchText);
    }
    if (!UtilsService.isEmpty(this.filterBy)) {
      vendors = ThirdPartyService.filterVendorList(vendors, this.filterBy);
    }
    if (!UtilsService.isEmpty(this.sortBy)) {
      vendors = ThirdPartyService.sortVendorList(vendors, this.sortBy);
    }
    this.vendors = vendors;
  }

  onFilterChange(filteredVendorList: Vendor[]) {
    this.vendors = filteredVendorList;
  }
  onSearchTextChange(filteredVendorList: Vendor[]) {
    this.vendors = filteredVendorList;
  }
}
