import { Component, OnInit } from '@angular/core';
import GeneratorHelpers from '../../generator.helpers';

@Component({
  selector: 'cygov-header-vendor-settings',
  templateUrl: './header-vendor-settings.component.html',
  styleUrls: ['./header-vendor-settings.component.scss']
})
export class HeaderComponent implements OnInit {
  public artifacts = GeneratorHelpers.artifactsStub().map(({ name }) => name);
  public certificates = GeneratorHelpers.certificationsStub().map(({ name }) => name);

  constructor() { }

  ngOnInit() {
  }

}
