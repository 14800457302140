import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'cygov-total-vendor-widget',
  templateUrl: './total-vendor-widget.component.html',
  styleUrls: ['./total-vendor-widget.component.scss']
})
export class TotalVendorWidgetComponent implements OnInit {
  @Input() totalVendors: number = 0;

  constructor() { }

  ngOnInit() {
  }

}
