export const environment = {
  production: false,
  debug: true,
  name: 'dev',
  region: 'us-east-1',
  backendUrl: 'https://backend.cygovdev.com',
  captchKey: '6Le2iGkUAAAAAKtPwG6Lif-10ZJs3kFNsVr8Ob4L',
  sessionTimeout: 24 * 60 * 60 * 1000, // 24 hours
  disable1rdPartyModule: false,
  disable3rdPartyModule: false,
  disableBoardModule: false,
  disableTrainingCtr: false,
  useCognitoAuth: false,
  mediaBucket: 'v2-cygov-media',
  awsAmplify: {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:6d33d3d1-b3ce-49b6-90b9-117da192c289',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_6OO4KLl8A',
    aws_user_pools_web_client_id: '3l7pt36blfvd5rvkp5lfegrc32',
    oauth: {},
  },
};
