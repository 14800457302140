import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FileService } from 'app/shared/file.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Vendor, Questionnaire } from 'models/vendor.model';
import { Entity, Qualification, DefaultSetting } from 'models/entity.model';
import { NgForm } from '@angular/forms';
import { AccessLevelEnum } from 'app/shared/enums/accessLevel.enum';
import { UtilsService } from 'app/shared/utils.service';
import { VendorService } from '../../vendor.service';
import { ImpactTextEnum, ImpactEnum } from 'app/shared/enums/impact.enum';
import { CrbVendorConstant } from './crb-vendor.constant';

@Component({
  selector: 'cygov-add-crb-vendor-modal',
  templateUrl: './add-crb-vendor-modal.component.html',
  styleUrls: ['./add-crb-vendor-modal.component.scss'],
})
export class AddCrbVendorModalComponent implements OnInit {
  @Input() entity: Entity;
  @Output() modalResult = new EventEmitter<boolean>();
  isLoading: boolean = true;
  vendor: Vendor = new Vendor();
  mandatoryArtifacts: Qualification[];
  selectedSurvey: ImpactTextEnum;
  selectedDueDate;
  dueDateOptions: number[] = [30, 60, 90];
  accessLevels = Object.keys(AccessLevelEnum).map(key => AccessLevelEnum[key]);
  surveys = Object.keys(ImpactTextEnum).map(key => ImpactTextEnum[key]);
  standardOptions: Qualification[];
  impactOptions: Qualification[];

  constructor(
    private fileService: FileService,
    private vendorService: VendorService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.loadDefaultSetting(this.entity.defaultSetting);
  }

  loadDefaultSetting(defaultSetting: DefaultSetting) {
    // Default setting contains default standards, mandatory artifacts, dueDate\time, frequency
    const dueDate = defaultSetting.dueDateInDays || this.dueDateOptions[0];
    this.selectedDueDate = dueDate ? this.dateAsNgbDateStruct(dueDate) : null;
    this.setTimeline();

    this.vendor.vendorDetails.timeline.frequency = defaultSetting.frequency;
    this.selectedSurvey = defaultSetting.surveyLevel;
    this.vendor.vendorDetails.accessLevel = defaultSetting.accessLevel;
    this.vendor.vendorDetails.privacyData = defaultSetting.privacyData;

    // default entity standards and artifacts - predetermined by MSSP
    this.standardOptions =
      defaultSetting.standards ||
      Object.values(CrbVendorConstant.compliances).map(standard => ({
        isQualify: false,
        name: standard,
      }));

    this.mandatoryArtifacts =
      defaultSetting.artifacts ||
      Object.values(CrbVendorConstant.artifacts).map(name => ({
        isQualify: true,
        name,
      }));

    this.impactOptions =
      defaultSetting.impacts ||
      CrbVendorConstant.impacts.map(name => ({
        isQualify: true,
        name,
      }));

    this.calcCrbVendorImpact();
  }

  isValid(ngForm: NgForm) {
    let valid = true;
    if (!ngForm.value.name) {
      this.toastr.error('Vendor name is required');
      valid = false;
    }
    if (!ngForm.value.primaryPocName) {
      this.toastr.error('Vendor POC name is required');
      valid = false;
    }
    if (!ngForm.value.primaryPocMail) {
      this.toastr.error('Vendor POC email is required');
      valid = false;
    } else if (ngForm.value.primaryPocMail.toLowerCase().indexOf('gmail') !== -1) {
      this.toastr.error('Company email only');
      valid = false;
    }

    return valid;
  }

  resetForm() {
    this.vendor = new Vendor();
    this.loadDefaultSetting(this.entity.defaultSetting);
  }

  async addVendor() {
    this.toastr.info(`Adding ${this.vendor.name}!`);
    try {
      // add more data to vendor
      this.vendor.details.reference.id = this.vendor.id;
      this.vendor.details.reference.name = this.vendor.name;
      this.vendor.details.reference.date = new Date(this.vendor.details.reference.date).getTime();
      this.vendor.details.stateReference.id = this.entity.id;
      this.vendor.details.stateReference.name = this.entity.name;

      this.vendor.vendorDetails.impact = this.calcCrbVendorImpact();
      this.vendor.vendorDetails.impactsQualify = this.impactOptions;
      this.vendor.vendorDetails.primaryPoc.email = this.vendor.vendorDetails.primaryPoc.email.toLowerCase();

      if (!this.vendor.vendorDetails.domain) {
        this.vendor.vendorDetails.domain = this.vendor.vendorDetails.primaryPoc.email
          .split('@')
          .pop();
      }

      const requiredStandards: Qualification[] = this.standardOptions.filter(
        standard => standard.isQualify
      );
      this.vendor.vendorDetails.standardsQualify = requiredStandards.map(standard => {
        standard.isQualify = false;
        return standard;
      });

      // load/add survey
      const file = await this.fileService.getFile('crb-vendor-survey.csv');
      const { data } = await FileService.parseFile(file);

      const surveyData = this.removeUnMandatoryArtifacts(data, this.vendor);
      this.vendor.vendorDetails.questionnaires = this.initQuestionnaires(surveyData);

      this.vendor = await this.vendorService.addVendor(this.vendor, surveyData);
      this.toastr.success(`${this.vendor.name} added successfully!`);
      this.modalResult.emit(true);
      this.resetForm();
    } catch (e) {
      console.log('addVendor - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  calcCrbVendorImpact() {
    const pointForEachImport = 100 / this.impactOptions.length;
    const requiredImpacts: Qualification[] = this.impactOptions.filter(impact => impact.isQualify);

    this.vendor.vendorDetails.impact = Math.round(pointForEachImport * requiredImpacts.length);

    switch (true) {
      case this.vendor.vendorDetails.impact <= ImpactEnum.LOW:
        this.selectedSurvey = ImpactTextEnum.LOW;
        break;
      case this.vendor.vendorDetails.impact > ImpactEnum.LOW &&
        this.vendor.vendorDetails.impact <= ImpactEnum.MEDIUM:
        this.selectedSurvey = ImpactTextEnum.MEDIUM;
        break;
      case this.vendor.vendorDetails.impact > ImpactEnum.MEDIUM &&
        this.vendor.vendorDetails.impact <= ImpactEnum.HIGH:
        this.selectedSurvey = ImpactTextEnum.HIGH;
        break;
      default:
        this.selectedSurvey = ImpactTextEnum.VERY_HIGH;
        break;
    }

    return this.vendor.vendorDetails.impact;
  }

  initQuestionnaires(surveyData) {
    const questionnaires = [];
    const processAreaMap = {};
    surveyData.forEach(el => {
      const processArea = el.processArea.toLowerCase();
      if (!processAreaMap[processArea]) {
        processAreaMap[processArea] = true;
        const questionnaire = new Questionnaire({ tag: processArea });
        questionnaires.push(questionnaire);
      }
    });

    return questionnaires;
  }

  removeUnMandatoryArtifacts(surveyData, vendor: Vendor) {
    const filteredSurvey = [];
    // update mandatory artifacts
    const isIncludes = (artifactType: string, controlName: string) => {
      let boolToReturn = false;
      artifactType = artifactType.toLowerCase();
      if (artifactType.includes('certifications')) {
        artifactType = artifactType.split('_').pop();
        boolToReturn = !!vendor.vendorDetails.standardsQualify.find(
          standard => standard.name === CrbVendorConstant.compliances[controlName]
        );
      } else {
        artifactType = artifactType.toUpperCase();
        boolToReturn = !!this.mandatoryArtifacts.find(artifact => {
          return artifact.isQualify && artifact.name === CrbVendorConstant.artifacts[controlName];
        });
      }

      return boolToReturn;
    };

    surveyData.forEach(el => {
      if (el.artifactsTag && el.controlName && isIncludes(el.artifactsTag, el.controlName)) {
        el.isArtifactsMandatory = true;
      }
      if (!el.artifactsTag || el.isArtifactsMandatory) {
        filteredSurvey.push(el);
      }
    });

    return filteredSurvey;
  }

  setTimeline() {
    const date: Date = this.getDueDate();
    this.vendor.vendorDetails.timeline.collectionDate = date.getTime();
    this.vendor.vendorDetails.timeline.nextReviewDate = date.getTime();
  }

  getDueDate() {
    return new Date(
      this.selectedDueDate.year,
      this.selectedDueDate.month - 1,
      this.selectedDueDate.day
    );
  }

  dateAsNgbDateStruct(days) {
    days = Number(days);
    const date = new Date();
    date.setDate(date.getDate() + days);

    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }
}
