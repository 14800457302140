import { SelectModule } from './../shared/select/select.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityListComponent } from './entity-list/entity-list.component';
import { SubEntityListComponent } from './sub-entity-list/sub-entity-list.component';
import { AdminRoutes } from './admin.routes';
import { AdminComponent } from './admin.component';
import { UserListComponent } from './user-list/user-list.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../shared/pipes/pipes.module';
import { HeaderModule } from '../header/header.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { SvgIconModule } from '../shared/svg-icon/svg-icon.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CygovLoaderModule } from '../shared/cygov-loader/cygov-loader.module';
import { AdminRoleGuard } from 'app/shared/guards/admin-role.guard';
import { SubEntityLeaderRoleGuard } from 'app/shared/guards/sub-entity-leader-role.guard';
import { EntityLeaderRoleGuard } from 'app/shared/guards/entity-leader-role.guard';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { GeneratorComponent } from './generator/generator.component';
import { NewEntitySettingsComponent } from './generator/entity-settings/new/new-entity-settings.component';
import { SubEntitySettingsComponent } from './generator/sub-entity-settings/sub-entity-settings.component';
import { ExistEntitySettingsComponent } from './generator/entity-settings/exist/exist-entity-settings.component';
import { NewSubEntitySettingsComponent } from './generator/sub-entity-settings/new/new-sub-entity-settings.component';
import { UserSettingsComponent } from './generator/user-settings/user-settings.component';
import { NewUserComponent } from './generator/user-settings/new/new-user-settings.component';
import { CriticalAlertsModule } from 'app/shared/critical-alerts/critical-alerts.module';
import { VendorSettingsComponent } from './generator/vendor-settings/vendor-settings.component';
import { NewVendorSettingsComponent } from './generator/vendor-settings/new/new-vendor-settings.component';
import { HeaderComponent } from './generator/vendor-settings/header/header-vendor-settings.component';
import { ToggleButtonModule } from 'app/shared/toggle-button/toggle-button.module';
import { CheckboxModule } from 'app/shared/checkbox/checkbox.module';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AddUserModule } from 'app/shared/add-user/add-user.module';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutes,
    FormsModule,
    NgxPaginationModule,
    PipesModule,
    HeaderModule,
    SvgIconModule,
    NgbModule,
    CygovLoaderModule,
    VirtualScrollerModule,
    AddUserModule,
    CriticalAlertsModule,
    ToggleButtonModule,
    CheckboxModule,
    SelectModule,
  ],
  declarations: [
    AdminComponent,
    EntityListComponent,
    SubEntityListComponent,
    UserListComponent,
    GeneratorComponent,
    NewEntitySettingsComponent,
    ExistEntitySettingsComponent,
    SubEntitySettingsComponent,
    NewSubEntitySettingsComponent,
    UserSettingsComponent,
    NewUserComponent,
    VendorSettingsComponent,
    NewVendorSettingsComponent,
    HeaderComponent,
  ],
  exports: [],
  providers: [
    AdminRoleGuard,
    EntityLeaderRoleGuard,
    SubEntityLeaderRoleGuard,
    NgxImageCompressService,
  ],
})
export class AdminModule {}
