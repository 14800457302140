import { Component, OnInit, OnDestroy } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { AuthService } from './shared/auth.service';
import { NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RoleEnum } from './shared/enums/role.enum';
import { RouterExtService } from './shared/router-external.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cygov-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean = false;
  showSidenav: boolean = false;
  routeChangedSubscription: Subscription;

  constructor(
    private routerExtService: RouterExtService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    // check if user is authenticate in every route change
    this.routeChangedSubscription = this.routerExtService.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.authService
          .getCurrentUser(false)
          .then(user => {
            this.isAuthenticated = !!user;
            // Hide Sidenav to participants
            this.showSidenav = user && user.role !== RoleEnum.PARTICIPANT;
          })
          .catch(() => {
            this.isAuthenticated = false;
          });
      }
    });
  }

  ngOnInit() {
    // https://github.com/DethAriel/ng-recaptcha/issues/123#issuecomment-426112101
    RecaptchaComponent.prototype.ngOnDestroy = function () {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    };
  }

  ngOnDestroy(): void {
    this.routeChangedSubscription.unsubscribe();
  }
}
