import { FrequencyCheckEnum } from 'app/shared/enums/frequency-check.enum';
import { ImpactTextEnum, ImpactEnum } from '../../shared/enums/impact.enum';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Entity, DefaultSetting, Qualification } from 'models/entity.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'app/shared/data.service';
import { ToastrService } from 'ngx-toastr';
import { CrbVendorConstant } from '../add-vendor/add-crb-vendor-modal/crb-vendor.constant';

@Component({
  selector: 'cygov-crb-default-setting-modal',
  templateUrl: './crb-default-setting-modal.component.html',
  styleUrls: ['./crb-default-setting-modal.component.scss'],
})
export class CrbDefaultSettingModalComponent implements OnInit {
  @Input() entity: Entity;
  @Output() modalResult = new EventEmitter<void>();
  isLoading: boolean = true;
  dueDateOptions: number[] = [30, 60, 90, 120];
  defaultDueDate: number;
  frequencyOptions = Object.keys(FrequencyCheckEnum).map(key => FrequencyCheckEnum[key]);

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.entity.defaultSetting = this.initDefaultSetting(this.entity.defaultSetting);
    this.calcCrbVendorImpact();
    this.isLoading = false;
  }

  initDefaultSetting(existingDefaults: DefaultSetting): DefaultSetting {
    const defaults: DefaultSetting = new DefaultSetting(existingDefaults);
    defaults.dueDateInDays =
      defaults.dueDateInDays || this.dueDateOptions[this.dueDateOptions.length - 1];
    this.setDate(defaults.dueDateInDays);

    defaults.standards =
      defaults.standards ||
      Object.values(CrbVendorConstant.compliances).map(standard => ({
        isQualify: false,
        name: standard,
      }));

    defaults.artifacts =
      defaults.artifacts ||
      Object.values(CrbVendorConstant.artifacts).map(name => ({
        isQualify: true,
        name,
      }));

    defaults.impacts =
      defaults.impacts ||
      CrbVendorConstant.impacts.map(name => ({
        isQualify: true,
        name,
      }));

    return defaults;
  }

  async saveDefaultSetting(entity: Entity) {
    try {
      this.entity = await this.dataService.updateEntity(entity);
      this.toastr.success('Default setting saved successfully!');
      this.modalResult.emit();
    } catch (e) {
      console.log('saveDefaultSetting - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  setDate(days: number) {
    const date = new Date();
    this.defaultDueDate = date.setDate(date.getDate() + +days);
  }

  calcCrbVendorImpact() {
    const pointForEachImport = 100 / this.entity.defaultSetting.impacts.length;
    const requiredImpacts: Qualification[] = this.entity.defaultSetting.impacts.filter(
      opt => opt.isQualify
    );

    const impact = Math.round(pointForEachImport * requiredImpacts.length);

    switch (true) {
      case impact <= ImpactEnum.LOW:
        this.entity.defaultSetting.surveyLevel = ImpactTextEnum.LOW;
        break;
      case impact > ImpactEnum.LOW && impact <= ImpactEnum.MEDIUM:
        this.entity.defaultSetting.surveyLevel = ImpactTextEnum.MEDIUM;
        break;
      default:
        this.entity.defaultSetting.surveyLevel = ImpactTextEnum.HIGH;
        break;
    }
  }
}
