import { Router, ActivatedRoute, Params } from '@angular/router';
import { Vendor } from 'models/vendor.model';
import { HeatMap, HeatMapCoordinate, EmitHeatMap } from 'models/heatmap.model';
import { UtilsService } from 'app/shared/utils.service';

import { Component, OnInit, Input } from '@angular/core';
import { VendorService } from '../vendor.service';
import { ThirdPartyService } from '../third-party.service';
import { ImpactCount } from 'models/impact-count.model';
import { ExternalScanCount } from 'models/external-scan-count.model';
import { StatusCount } from 'models/status-count.model';
import { ScanChartData } from 'models/external-scan.model';
import { HeatmapSectionEnum } from 'app/shared/enums/heatmap-sections.enum';

@Component({
  selector: 'cygov-third-party-overview',
  templateUrl: './third-party-overview.component.html',
  styleUrls: ['./third-party-overview.component.scss'],
})
export class ThirdPartyOverviewComponent implements OnInit {
  vendors: Vendor[];
  isLoading: boolean = true;

  riskAverage: number = 0;
  target: number = 0;
  totalVendors: number = 0;
  impactCount: ImpactCount = new ImpactCount();
  externalScanCount: ExternalScanCount;
  scanChartData: ScanChartData[];
  statusCount: StatusCount;
  expandHeatMap: boolean = false;
  sortBy: string;
  filterBy: string;
  searchText: string;

  heatMapData: HeatMap[] = [];
  heatMapCoordinates: HeatMapCoordinate[] = [];
  filteredVendors: Vendor[] = [];
  sortColumn: string = 'riskScore';
  sortDirection: string = 'asc';
  heatmapSections: HeatmapSectionEnum;
  isCrbAdjustments = UtilsService.isCRB;

  MIN_X = 95.5;
  MIN_Y = 50;
  CHART_WIDTH = 743;
  CHART_HEIGHT = 532;

  constructor(
    private vendorService: VendorService,
    private thirdPartyService: ThirdPartyService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.heatmapSections = this.thirdPartyService.getHeatmapDefaultView();
  }

  ngOnInit() {
    this.getVendorList();
  }

  async getVendorList(fromCache = true) {
    try {
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.vendors = await this.vendorService.getVendorList(entityId, fromCache);

      this.riskAverage = ThirdPartyService.getRiskScoreAverage(this.vendors);

      this.totalVendors = ThirdPartyService.getTotalVendors(this.vendors);

      this.impactCount = ThirdPartyService.getImpactCounts(this.vendors);

      this.target = ThirdPartyService.getTarget(this.vendors);

      this.externalScanCount = new ExternalScanCount();
      ThirdPartyService.getExternalScanCounts(this.vendors, this.externalScanCount);

      this.statusCount = new StatusCount();
      ThirdPartyService.getStatusCounts(this.vendors, this.statusCount);

      this.getHeatMapData();

      // Remove this after adding it to the API
      this.scanChartData = [
        {
          radius: 0.1,
          angle: 0.05,
        },
        {
          radius: 0.8,
          angle: 0.5,
        },
        {
          radius: 0.4,
          angle: 0.9,
        },
        {
          radius: 0.6,
          angle: 0.5,
        },
        {
          radius: 0.3,
          angle: 0.6,
        },
        {
          radius: 0.9,
          angle: 0.4,
        },
        {
          radius: 0.7,
          angle: 0.2,
        },
        {
          radius: 0.7,
          angle: 0.75,
        },
        {
          radius: 0.6,
          angle: 0.9,
        },
        {
          radius: 0.8,
          angle: 1,
        },
        {
          radius: 0.1,
          angle: 0.9,
        },
        {
          radius: 0.2,
          angle: 0.6,
        },
      ];

      this.isLoading = false;
    } catch (e) {
      console.log('Error While calling Vendor Api', e);
      this.isLoading = false;
    }
  }

  getHeatMapData() {
    ThirdPartyService.getHeatMapData(this.vendors, this.heatMapData);
    ThirdPartyService.getHeatMapCoordinates(
      this.heatMapData,
      this.heatMapCoordinates,
      this.MIN_X,
      this.MIN_Y,
      this.CHART_WIDTH,
      this.CHART_HEIGHT
    );
  }

  openExtendedHeatMap(value: boolean) {
    this.expandHeatMap = value;
  }

  onSearchTextChange(event) {
    this.searchText = event;
    this.applyFilters();
  }

  onFilterChange(event) {
    this.filterBy = event;
    this.applyFilters();
  }

  onSortChange(event) {
    this.sortBy = event;
    this.applyFilters();
  }

  applyFilters() {
    let vendors = this.vendors;
    if (!UtilsService.isEmpty(this.searchText)) {
      vendors = ThirdPartyService.filterVendorListByText(vendors, this.searchText);
    }
    if (!UtilsService.isEmpty(this.filterBy)) {
      vendors = ThirdPartyService.filterVendorList(vendors, this.filterBy);
    }
    if (!UtilsService.isEmpty(this.sortBy)) {
      vendors = ThirdPartyService.sortVendorList(vendors, this.sortBy);
    }
  }

  showExtendedHeatMap(event: EmitHeatMap) {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.thirdPartyService.setHeatmapDefaultView(event.sections);
    if (event.navigateToRiskManagement) {
      const queryParams: Params = {
        minImpact: event.minImpact,
        maxImpact: event.maxImpact,
        minProbability: event.minProbability,
        maxProbability: event.maxProbability,
      };

      this.router.navigate([`third-party/${entityId}/heat-map`], {
        queryParams: queryParams,
      });
    }
  }
}
