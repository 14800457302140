import { Component, OnInit } from '@angular/core';
import { AgencyReference } from '../../../models/agency.model';
import { DataService } from '../../shared/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FileService } from '../../shared/file.service';
import { Entity } from '../../../models/entity.model';
import { FileType } from '../entity-list/entity-list.component';
import { UtilsService } from 'app/shared/utils.service';
import { Location } from '@angular/common';
import { CollectionStatusEnum } from 'app/shared/enums/collection-status.enum';

class PresentAgency extends AgencyReference {
  fileName?: string;
}

@Component({
  selector: 'cygov-sub-entity-list',
  templateUrl: './sub-entity-list.component.html',
  styleUrls: ['./sub-entity-list.component.scss'],
})
export class SubEntityListComponent implements OnInit {
  entity: Entity;
  surveyFile: FileType;
  selectedAgencyName: string;
  selectedAgencyId: string;
  stages: string[] = Object.keys(CollectionStatusEnum).map(key => CollectionStatusEnum[key]);
  agenciesReference: PresentAgency[];
  displayAgencies: PresentAgency[];
  queryText: string = '';
  selectedRow: Number;
  page;
  newSubEntity: Entity;

  constructor(
    private dataService: DataService,
    private fileService: FileService,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    private toastr: ToastrService
  ) {}

  async ngOnInit(fromCache?) {
    this.newSubEntity = new Entity();
    this.newSubEntity.details.reference.stage = null;
    this.surveyFile = null;

    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.agenciesReference = this.agenciesReference || [];

    try {
      this.entity = await this.dataService.getEntity(entityId, fromCache);
      this.agenciesReference = this.entity.agencies;
      this.displayAgencies = this.agenciesReference;
    } catch (e) {
      console.log('SubEntityListComponent - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  async downloadFile(agencyId: string, surveyOrReview) {
    try {
      const agency: Entity = await this.dataService.getSubEntity(agencyId);
      const jsonToDownload = surveyOrReview ? agency.surveyCore : agency.review;
      const dataAsString =
        'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(jsonToDownload));
      const link = document.createElement('a');
      link.setAttribute('href', dataAsString);
      link.setAttribute('download', 'scene.json');
      link.click();

      await this.fileService.downloadSubEntityData(agencyId, surveyOrReview);
      console.log('downloadAgencyData succeeded');
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  goToAgencyManagement() {
    this.router.navigate([`admin/${this.entity.id}/${this.selectedAgencyId}/user-list`]);
  }

  async fileHandler(event) {
    if (event.target.files && event.target.files.length) {
      try {
        const { data } = await FileService.fileToCsv(event);
        // handel survey file
        this.surveyFile = {
          data: data,
          name: event.target.files[0].name,
        };
        this.toastr.success('Survey file loaded');
      } catch (e) {
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    }
  }

  selectAgency(agencyName, agencyId, i) {
    this.selectedAgencyName = agencyName;
    this.selectedAgencyId = agencyId;
    this.selectedRow = i;
  }

  searchAgencies() {
    this.displayAgencies = this.agenciesReference.filter(agencyRef => {
      return (
        !this.queryText ||
        agencyRef.name.toLocaleLowerCase().includes(this.queryText.toLocaleLowerCase())
      );
    });
  }

  async addAgency(newSubEntity) {
    this.toastr.info('adding agency...');
    newSubEntity.details.reference.id = newSubEntity.id;
    newSubEntity.details.reference.name = newSubEntity.name;
    newSubEntity.details.reference.date = new Date(newSubEntity.details.reference.date).getTime();
    newSubEntity.details.stateReference.id = this.entity.id;
    newSubEntity.details.stateReference.name = this.entity.name;

    try {
      await this.dataService.addSubEntity(newSubEntity, this.surveyFile.data);
      this.toastr.success('Agency added successfully!');
      await this.ngOnInit(false);
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  validateSubEntity(newSubEntity) {
    let isValid: boolean = true;
    if (!newSubEntity.name) {
      this.toastr.error('Name is required');
      isValid = false;
    }
    if (!newSubEntity.details.reference.date) {
      this.toastr.error('Please select date');
      isValid = false;
    }
    if (!newSubEntity.details.reference.stage) {
      this.toastr.error('Please select stage');
      isValid = false;
    }
    if (!this.surveyFile) {
      this.toastr.error('Survey file is required');
      isValid = false;
    }
    return isValid;
  }

  async removeAgency() {
    try {
      this.toastr.info('Removing agency...');
      await this.dataService.removeSubEntity(this.selectedAgencyId, this.entity.id);
      this.toastr.success('Agency removed successfully');
      await this.ngOnInit(false);
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  toTimestamp(dateStr: string) {
    return new Date(dateStr).getTime();
  }
}
