import { TableEnum } from '../app/shared/enums/table.enum';
import { DocTypeEnum } from '../app/shared/enums/docType.enum';
import { Question, SurveyCore } from './agency.model';

export class SecurityControl extends SurveyCore {
  /** from Agency model */
  // id: any;
  // name: string;
  // questionId: string;            // deprecated
  // question: Question;            // deprecated - moved into questions array
  // completion: number;            // deprecated
  // todo: string;                  // deprecated - moved into Question
  // jiraId: string;                // deprecated - moved into Question
  // score: number;                 // deprecated
  // label: string;
  // description: string;
  // priority: ImpactTextEnum;
  // standards: string[];
  // processArea: string;
  // function: string;
  // category: string;
  // subCategory: string;
  // overRideScore?: number;
  // docStackId: string;
  // children: SurveyCoreChildren;

  weight: number;
  questions: Question[];

  createdAt: number;
  createdBy: string;
  modifiedAt: number;
  modifiedBy: string;
  docType: DocTypeEnum;
  tableName: TableEnum;

  constructor(obj: any = {}) {
    super(obj);
    this.weight = obj.weight;
    this.questions = obj.questions;

    this.createdAt = obj.createdAt || Date.now();
    this.createdBy = obj.createdBy;
    this.modifiedAt = obj.modifiedAt || Date.now();
    this.modifiedBy = obj.modifiedBy;
    this.docType = obj.docType || DocTypeEnum.CONTROL;
    this.tableName = obj.tableName;
  }
}
