import { FrequencyCheckEnum } from 'app/shared/enums/frequency-check.enum';
import { ImpactTextEnum } from '../../shared/enums/impact.enum';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Entity, DefaultSetting } from 'models/entity.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StandardEnum } from 'app/shared/enums/standard.enum';
import { DataService } from 'app/shared/data.service';
import { ArtifactsEnum } from 'app/shared/enums/artifacts.enum';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'cygov-default-setting-modal',
  templateUrl: './default-setting-modal.component.html',
  styleUrls: ['./default-setting-modal.component.scss'],
})
export class DefaultSettingModalComponent implements OnInit {
  @Input() entity: Entity;
  @Output() modalResult = new EventEmitter<void>();

  dueDateOptions: number[] = [30, 60, 90, 120];
  frequencyOptions = Object.keys(FrequencyCheckEnum).map(key => FrequencyCheckEnum[key]);
  impactOptions = Object.keys(ImpactTextEnum).map(key => ImpactTextEnum[key]);
  standardList = [
    StandardEnum.PCI_DSS,
    StandardEnum.FFIEC,
    StandardEnum.GDPR,
    StandardEnum.HIPAA,
    StandardEnum.ISO_27001,
    StandardEnum.FERPA,
  ];
  defaultDueDate: number;

  constructor(
    private dataService: DataService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.entity.defaultSetting = this.initDefaultSetting(this.entity.defaultSetting);
  }

  initDefaultSetting(existingDefaults: DefaultSetting): DefaultSetting {
    const defaults: DefaultSetting = new DefaultSetting(existingDefaults);
    defaults.dueDateInDays =
      defaults.dueDateInDays || this.dueDateOptions[this.dueDateOptions.length - 1];
    this.setDate(defaults.dueDateInDays);
    defaults.standards =
      defaults.standards ||
      this.standardList.map(standard => {
        return {
          isQualify: false,
          name: standard,
        };
      });

    const artifactsWithOutCompliance = Object.keys(ArtifactsEnum).filter(
      key => !key.includes('COMPLIANCE')
    );

    defaults.artifacts =
      defaults.artifacts ||
      artifactsWithOutCompliance.map(key => {
        return {
          isQualify: false,
          name: ArtifactsEnum[key],
        };
      });

    return defaults;
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  async saveDefaultSetting(entity: Entity) {
    this.closeModal();
    try {
      this.entity = await this.dataService.updateEntity(entity);
      this.toastr.success('Default setting saved successfully!');
    } catch (e) {
      console.log('saveDefaultSetting - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  setDate(days: number) {
    const date = new Date();
    this.defaultDueDate = date.setDate(date.getDate() + +days);
  }
}
