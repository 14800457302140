import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ElementRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Entity } from 'models/entity.model';
import { EntityCard } from 'app/clients/clients.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { RoleEnum } from '../enums/role.enum';
import { UtilsService } from '../utils.service';
import { ImpactTextEnum, ImpactEnum } from '../enums/impact.enum';
import { ThirdPartyService } from 'app/third-party/third-party.service';

@Component({
  selector: 'cygov-ui-tools',
  templateUrl: './ui-tools.component.html',
  styleUrls: ['./ui-tools.component.scss'],
})
export class UiToolsComponent implements OnInit, OnChanges {
  @Input() inputCollection: any;
  @Output() onAddEntity = new EventEmitter<Entity>();
  @Output() onAddSubEntity = new EventEmitter<Entity>();
  @Output() onViewChanged = new EventEmitter<boolean>();
  @Output() onSearchChanged = new EventEmitter<EntityCard>();
  @Output() onSortChanged = new EventEmitter<EntityCard>();
  @Output() onFilterChanged = new EventEmitter<EntityCard>();

  queryText: string;
  sortBy: string = null;
  prevSortBy: string = null;
  sortOptions: string[] = ['name', 'score', 'target'];
  filterBy: string = null;
  selectedGroupFilter: string = null;
  filterOptions: string[] = ['low', 'medium', 'high'];
  vendorListFilterOptions = [
    { id: 2, name: 'High', groupType: 'Impact' },
    { id: 3, name: 'Medium', groupType: 'Impact' },
    { id: 4, name: 'Low', groupType: 'Impact' },
    { id: 5, name: 'Approved', groupType: 'Status' },
    { id: 6, name: 'Pending', groupType: 'Status' },
    { id: 7, name: 'In Progress', groupType: 'Status' },
    { id: 8, name: 'Denied', groupType: 'Status' },
    { id: 9, name: 'With PII data', groupType: 'Privacy' },
    { id: 10, name: 'Without PII data', groupType: 'Privacy' },
  ];
  vendorListGroupFilterOptions;
  selectedVendorListFilters: string[] = [];
  cardView: boolean = true;
  cachedCollection: any;
  displayCollection: any;
  modalReference: any;
  showAddClient: boolean = false;
  showAddMPL: boolean = false;
  isCrbAdjustments: boolean = false;
  showLayoutBtns: boolean = true;
  isVendorsTable: boolean = false;

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputCollection.currentValue) {
      // update cachedCollection and change the inputCollection reference
      this.cachedCollection = this.displayCollection = changes.inputCollection.currentValue.map(
        item => item
      );
      if (this.isVendorsTable) {
        this.vendorListGroupFilterOptions = ThirdPartyService.vendorGroupList(
          this.displayCollection
        );
      }
    }
  }

  async ngOnInit() {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');

    this.isCrbAdjustments = UtilsService.isCRB;
    this.showAddMPL =
      UtilsService.isCRB &&
      this.router.url.includes('multi-entity') &&
      (await this.authService.hasPermission(RoleEnum.ENTITY_LEADER, entityId));

    this.showAddClient = this.router.url.includes('clients');
    this.showLayoutBtns = !this.router.url.includes('third-party');
    this.isVendorsTable = this.router.url.includes('vendors');

    if (this.inputCollection) {
      // change the inputCollection reference
      this.cachedCollection = this.displayCollection = this.inputCollection.map(item => item);
    }

    if (this.isVendorsTable) {
      this.vendorListGroupFilterOptions = ThirdPartyService.vendorGroupList(this.displayCollection);
    }
  }

  sortChanged(sortBy) {
    if (sortBy !== this.prevSortBy) {
      this.prevSortBy = sortBy;
      switch (sortBy) {
        case 'name':
          this.displayCollection = this.displayCollection.sort((a, b) => {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          });
          break;
        case 'score':
          this.displayCollection = this.displayCollection.sort((a, b) => {
            return a.scores.total > b.scores.total ? 1 : b.scores.total > a.scores.total ? -1 : 0;
          });
          break;
        case 'target':
          this.displayCollection = this.displayCollection.sort((a, b) => {
            return a.scores.target > b.scores.target
              ? 1
              : b.scores.target > a.scores.target
              ? -1
              : 0;
          });
          break;
      }
    }

    this.onSortChanged.emit(this.displayCollection);
  }

  filterChanged(filterBy, type) {
    if (this.isVendorsTable) {
      this.vendorListFilterChanged(filterBy, type);
    } else {
      this.displayCollection = this.cachedCollection.map(item => item);
      switch (filterBy) {
        case 'low':
          this.displayCollection = this.displayCollection.filter(item => item.scores.total <= 3.33);
          break;
        case 'medium':
          this.displayCollection = this.displayCollection.filter(
            item => item.scores.total > 3.33 && item.scores.total < 6.66
          );
          break;
        case 'high':
          this.displayCollection = this.displayCollection.filter(item => item.scores.total >= 6.66);
          break;
        default:
          if (type === 'search' && filterBy) {
            this.displayCollection = this.displayCollection.filter(item => {
              return item.name.toLowerCase().includes(this.queryText.toLowerCase());
            });
          }
          if (type === 'group' && filterBy) {
            this.displayCollection = this.displayCollection.filter(item => {
              if (filterBy.toLowerCase() === 'general') {
                return (
                  UtilsService.isDefined(item.vendorDetails.group) ||
                  item.vendorDetails.group === filterBy.toLowerCase()
                );
              } else {
                return item.vendorDetails.group === filterBy.toLowerCase();
              }
            });
          }
      }

      if (this.sortBy) {
        this.sortChanged(this.sortBy);
      } else {
        this.onSearchChanged.emit(this.displayCollection);
      }
    }
  }

  vendorListFilterChanged(filterBy, type) {
    this.displayCollection = this.cachedCollection.map(item => item);

    this.selectedVendorListFilters.forEach(element => {
      const selectedFilter = this.vendorListFilterOptions.filter(item => {
        return item.id === +element;
      })[0];

      switch (selectedFilter.groupType) {
        case 'Impact':
          this.filterCollectionOnImpact(selectedFilter);
          break;
        case 'Status':
          this.filterCollectionOnStatus(selectedFilter);
          break;
        case 'Privacy':
          this.filterCollectionOnPrivacy(selectedFilter);
          break;
      }
    });

    if (type === 'search' && filterBy) {
      this.displayCollection = this.displayCollection.filter(item => {
        return item.name.toLowerCase().includes(this.queryText.toLowerCase());
      });
    }
    if (type === 'group' && filterBy) {
      this.displayCollection = this.displayCollection.filter(item => {
        if (filterBy.toLowerCase() === 'general') {
          return (
            UtilsService.isDefined(item.vendorDetails.group) ||
            item.vendorDetails.group === filterBy.toLowerCase()
          );
        } else {
          return item.vendorDetails.group === filterBy.toLowerCase();
        }
      });
    }

    if (this.queryText) {
      this.displayCollection = this.displayCollection.filter(item => {
        return item.name.toLowerCase().includes(this.queryText.toLowerCase());
      });
    }

    this.onFilterChanged.emit(this.displayCollection);
  }

  filterCollectionOnImpact(selectedFilter) {
    switch (selectedFilter.name.toLowerCase()) {
      case ImpactTextEnum.LOW:
        this.displayCollection = this.displayCollection.filter(
          item => item.vendorDetails.impact <= ImpactEnum.LOW
        );
        break;
      case ImpactTextEnum.MEDIUM:
        this.displayCollection = this.displayCollection.filter(
          item =>
            item.vendorDetails.impact <= ImpactEnum.MEDIUM &&
            item.vendorDetails.impact >= ImpactEnum.LOW
        );
        break;
      case ImpactTextEnum.HIGH:
        this.displayCollection = this.displayCollection.filter(
          item => item.vendorDetails.impact >= ImpactEnum.MEDIUM
        );
        break;
      default:
        break;
    }
  }

  filterCollectionOnStatus(selectedFilter) {
    this.displayCollection = this.displayCollection.filter(
      item => item.vendorDetails.status === selectedFilter.name.toLowerCase()
    );
  }

  filterCollectionOnPrivacy(selectedFilter) {
    const withPrivacyData = selectedFilter.name.toLowerCase().indexOf('without') === -1;
    this.displayCollection = this.displayCollection.filter(item =>
      withPrivacyData ? item.vendorDetails.privacyData : !item.vendorDetails.privacyData
    );
  }

  viewChanged(isCard) {
    // emit only if there was a change
    if (isCard !== this.cardView) {
      this.cardView = isCard;
      this.onViewChanged.emit(isCard);
    }
  }

  openModal(content: ElementRef) {
    this.modalReference = this.modalService.open(content);
  }

  closeModal() {
    this.modalReference.close();
  }

  addEvent(type: string, event: Entity) {
    if (event) {
      return type === 'entity' ? this.onAddEntity.emit(event) : this.onAddSubEntity.emit(event);
    }
  }
}
