import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ThreatDetails } from 'models/vendor.model';

@Component({
  selector: 'cygov-scan-details',
  templateUrl: './scan-details.component.html',
  styleUrls: ['./scan-details.component.scss'],
})
export class ScanDetailsComponent implements OnInit {
  @Input() threatDetails: ThreatDetails[] = [];
  @Output() modalResult = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
