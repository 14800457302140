import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

import {
  DOWNLOAD_OVERALL_REPORT,
  DOWNLOAD_CRB_REPORT,
  DOWNLOAD_CSV_REPORT,
  DOWNLOAD_MPL_REPORT,
  DOWNLOAD_VENDOR_REPORT,
} from '../app.paths';

export type ThemeValues = 'dark' | 'light';
export type ThemeSelector = {
  [propName in ThemeValues]: string;
};

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  async generateOverallReport(stateId: string, theme: ThemeValues = 'dark'): Promise<Blob> {
    if (await this.authService.isSessionExpired()) {
      throw new Error('Session is expired');
    }
    return this.http
      .post(
        DOWNLOAD_OVERALL_REPORT,
        { stateId, options: { theme } },
        { observe: 'body', responseType: 'blob' }
      )
      .toPromise();
  }

  async generateCRBReport(subEntityId: string, theme: ThemeValues = 'dark'): Promise<Blob> {
    if (await this.authService.isSessionExpired()) {
      throw new Error('Session is expired');
    }
    return this.http
      .post(
        DOWNLOAD_CRB_REPORT,
        { subEntityId, options: { theme } },
        { observe: 'body', responseType: 'blob' }
      )
      .toPromise();
  }

  async generateCSVReport(subEntityId: string, userId: string): Promise<Blob> {
    if (await this.authService.isSessionExpired()) {
      throw new Error('Session is expired');
    }
    return this.http
      .post(DOWNLOAD_CSV_REPORT, { subEntityId, userId }, { observe: 'body', responseType: 'blob' })
      .toPromise();
  }

  async generateMPLReport(subEntityId: string): Promise<Blob> {
    if (await this.authService.isSessionExpired()) {
      throw new Error('Session is expired');
    }
    return this.http
      .post(DOWNLOAD_MPL_REPORT, { subEntityId }, { observe: 'body', responseType: 'blob' })
      .toPromise();
  }

  async generateVendorReport(vendorId: string): Promise<Blob> {
    if (await this.authService.isSessionExpired()) {
      throw new Error('Session is expired');
    }
    return this.http
      .post(DOWNLOAD_VENDOR_REPORT, { vendorId }, { observe: 'body', responseType: 'blob' })
      .toPromise();
  }
}
