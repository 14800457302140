import {
  Component,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Entity } from 'models/entity.model';
import { FileType } from 'app/admin/entity-list/entity-list.component';
import { FileService } from 'app/shared/file.service';

@Component({
  selector: 'cygov-add-client-modal',
  templateUrl: './add-client-modal.component.html',
  styleUrls: ['./add-client-modal.component.scss']
})
export class AddClientModalComponent implements OnInit {
  @Output() modalResult = new EventEmitter<Entity>();
  newEntity: Entity = new Entity();
  imageFile: FileType;

  constructor(private toastr: ToastrService) { }

  ngOnInit() { }

  async imgHandler(fileInput: any) {
    if (fileInput && fileInput.target.files[0]) {
      this.imageFile = {
        name: fileInput.target.files[0].name,
        data: await FileService.getBase64(fileInput.target.files[0])
      };
    } else {
      this.imageFile = null;
    }
  }

  isValid(entity: Entity) {
    let valid = true;
    if (!entity.name) {
      this.toastr.error('Entity name is required');
      valid = false;
    }
    if (!this.imageFile) {
      this.toastr.error('Entity logo is required');
      valid = false;
    }

    return valid;
  }

  async emitEntity(entity: Entity) {
    entity.logo =
      this.imageFile && this.imageFile.data ? this.imageFile.data : null;
    this.modalResult.emit(entity);
  }
}
