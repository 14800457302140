import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Vendor } from 'models/vendor.model';
import { ThirdPartyService } from 'app/third-party/third-party.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from 'app/shared/utils.service';
import { TableHeader, TableHeaderState } from 'models/table-header.model';
import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';
import { ImpactTextEnum } from 'app/shared/enums/impact.enum';

@Component({
  selector: 'cygov-vendor-table',
  templateUrl: './vendor-table.component.html',
  styleUrls: ['./vendor-table.component.scss'],
})
export class VendorTableComponent implements OnInit {
  @Input() vendors: Vendor[];
  @Output() onSortChanged = new EventEmitter<any>();

  headers: TableHeader[];
  headerState: TableHeaderState;
  sortDirections = SortDirectionEnum;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private thirdPartyService: ThirdPartyService
  ) {
    this.headerState = new TableHeaderState();
    this.headerState.setInitialHeaders(this.getHeadersData());
  }

  ngOnInit() {}

  getHeadersData() {
    return [
      {
        value: 'name',
        class: 'company-name',
        name: 'Company Name',
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'vendorDetails.impact',
        class: 'impact',
        name: 'Impact',
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'vendorDetails.probability',
        class: 'extScan',
        name: 'Probability',
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'scores.total',
        class: 'riskScore',
        name: 'Gap Assessment',
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
    ];
  }

  toggleVendorList(isExpanded) {
    this.thirdPartyService.toggleVendorList(isExpanded);
  }

  getImpactText(impact: number) {
    return ThirdPartyService.getImpactText(impact);
  }

  getImpactTextClass(impact: number) {
    let impactClass = ThirdPartyService.getImpactText(impact);
    if (impactClass === ImpactTextEnum.VERY_HIGH) {
      impactClass = 'very-high';
    }
    return impactClass;
  }

  onHeaderClicked(header: TableHeader) {
    this.headerState.setHeaderState(header);
    this.onSortChanged.emit(header);
  }

  async navigateToVendors() {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    await this.router.navigate([`third-party/${entityId}/vendors`]);
  }

  showVendorDetail(vendorId: string) {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.router.navigate([`third-party/${entityId}/vendors`], {
      queryParams: { vendorId },
    });
  }
}
