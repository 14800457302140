import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Route } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MsspRoleGuard } from './shared/guards/mssp-role.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { AdminRoleGuard } from './shared/guards/admin-role.guard';
import { EntityLeaderRoleGuard } from './shared/guards/entity-leader-role.guard';
import { SubEntityLeaderRoleGuard } from './shared/guards/sub-entity-leader-role.guard';

// How to work properly with angular router:
// https://stackoverflow.com/questions/44864303/send-data-through-routing-paths-in-angular

const routes: Route[] = [
  {
    path: '',
    loadChildren: './auth/auth.module#AuthModule',
  },
  {
    path: 'clients',
    loadChildren: 'app/clients/clients.module#ClientsModule',
  },
  {
    path: 'first-party',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':entityId',
        pathMatch: 'full',
        redirectTo: ':entityId/upperdeck',
      },
      {
        path: ':entityId/upperdeck',
        canActivate: [EntityLeaderRoleGuard],
        loadChildren: 'app/upperdeck/upperdeck.module#UpperdeckModule',
      },
      {
        path: ':entityId/multi-entity',
        canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: 'app/multi-entity/multi-entity.module#MultiEntityModule',
      },
      {
        path: ':entityId/pillars',
        canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: 'app/pillars/pillars.module#PillarsModule',
      },
      {
        path: ':entityId/break-down',
        canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: 'app/break-down/break-down.module#BreakDownModule',
      },
      {
        path: ':entityId/remediation',
        canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: 'app/remediation/remediation.module#RemediationModule',
      },
      {
        path: ':entityId/alerts',
        loadChildren: 'app/alerts/alerts.module#AlertsModule',
      },
      {
        path: ':entityId/attack-simulator',
        loadChildren: 'app/attack-simulator/attack-simulator.module#AttackSimulatorModule',
      },
      {
        path: ':entityId/questionnaire-management',
        canActivate: [SubEntityLeaderRoleGuard],
        loadChildren:
          'app/questionnaire-management/questionnaire-management.module#QuestionnaireManagementModule',
      },
      {
        path: ':entityId/questionnaire',
        loadChildren: 'app/questionnaire/questionnaire.module#QuestionnaireModule',
      },
    ],
  },
  {
    path: 'third-party',
    canActivate: [AuthGuard],
    loadChildren: 'app/third-party/third-party.module#ThirdPartyModule',
  },
  {
    path: 'vendor',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':entityId',
        pathMatch: 'full',
        redirectTo: ':entityId/questionnaire',
      },
      {
        path: ':entityId/questionnaire',
        loadChildren: 'app/questionnaire/questionnaire.module#QuestionnaireModule',
      },
      {
        path: ':entityId/gaps',
        loadChildren: 'app/remediation/remediation.module#RemediationModule',
      },
    ],
  },
  {
    path: 'board',
    canActivate: [AuthGuard],
    loadChildren: 'app/board/board.module#BoardModule',
  },
  {
    path: 'binary-edge',
    canActivate: [AuthGuard],
    loadChildren: 'app/binary-edge/binary-edge.module#BinaryEdgeModule',
  },
  {
    path: 'findings',
    canActivate: [AuthGuard],
    loadChildren: 'app/findings/findings.module#FindingsModule',
  },
  {
    path: 'training-center',
    canActivate: [AuthGuard],
    loadChildren: 'app/training-center/training-center.module#TrainingCenterModule',
  },
  {
    path: 'admin',
    canActivate: [AdminRoleGuard],
    loadChildren: 'app/admin/admin.module#AdminModule',
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutesModule {}
