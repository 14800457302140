export class CrbVendorConstant {
  public static artifacts = {
    CRB1: 'Information Security Policy',
    CRB2: 'Procedure for background screening',
    CRB3: 'BCP and DRP policies',
    CRB4: 'Data retention and destruction requirements',
    CRB5: 'Cyber insurance policies',
    CRB6: 'SSAE 18 reports for the past year',
    CRB7: 'SSAE 18 evidence and reports of vendors',
    CRB8: 'Technology risk assessments and risk management programs',
    CRB9: 'Latest technology risk assessments reports',
    CRB10: 'Penetration testing results',
    CRB11: 'BCP and DRP testing schedules and results',
    CRB12: 'Critical Infrastructure physical security guidelines',
    CRB13: 'Non-Disclosure Agreement',
    CRB18: 'Bank Service Company Act',
  };

  public static compliances = {
    CRB14: 'pci dss',
    CRB15: 'ffiec',
    CRB16: 'gdpr',
    CRB17: 'iso 27001',
  };

  public static impacts = [
    'Lost sales and income',
    'Negative cash flows resulting from delayed sales or income',
    'Increased expenses',
    'Regulatory fines',
    'Contractual penalties or loss of contractual bonuses',
    'Customer dissatisfaction or defection',
    'Delay executing business plan or strategic initiative',
  ];

  public static artifactsQualification = () => {
    return Object.values(CrbVendorConstant.artifacts).map(name => ({
      isQualify: false,
      name,
    }));
  };
}
