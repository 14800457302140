import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../shared/data.service';
import { AuthService } from 'app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AgencyDetails, QuestionSheet } from '../../../models/agency.model';
import { User } from '../../../models/user.model';
import { RoleEnum } from '../../shared/enums/role.enum';
import { Entity } from '../../../models/entity.model';
import { UtilsService } from 'app/shared/utils.service';
import { Location } from '@angular/common';

class DisplayUsers extends User {
  completion: number;
}

@Component({
  selector: 'cygov-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  queryText: string;
  affiliation: any;
  agencyDetails: AgencyDetails;
  selectedUser: User;
  selectedRow: number;
  questionSheetNames: string[] = [];
  roles = Object.keys(RoleEnum).map(k => RoleEnum[k]);
  newUser: User;
  users: User[];
  displayUsers: DisplayUsers[] = [];
  page;
  isUserAdding = false;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private router: Router,
    public location: Location,
    private toastr: ToastrService
  ) {}

  async ngOnInit(fromCache = true) {
    try {
      this.newUser = new User();
      this.newUser.role = null;
      const subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
      const agency: Entity = await this.dataService.getSubEntity(subEntityId, fromCache);
      this.agencyDetails = agency.details;
      this.affiliation = {
        state: {...this.agencyDetails.stateReference},
        agency: {...this.agencyDetails.reference},
      };
      this.questionSheetNames = agency.details.sheetsNames;
      this.users = await this.dataService.getUserList(agency.users);
      if (this.users && this.users.length) {
        // change the list reference
        this.displayUsers = this.users.map((user: DisplayUsers) => {
          user.completion =
            (user.questionSheet.completion / user.questionSheet.numberOfQuest) * 100;
          return user;
        });
      } else {
        this.displayUsers = [];
      }
    } catch (e) {
      console.log('UserListComponent - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  selectUser(user, i) {
    this.selectedUser = user;
    this.selectedRow = i;
  }

  searchUsers() {
    if (this.queryText && this.queryText.length > 1) {
      const displayUsers = this.users.filter(user =>
        user.email.toLowerCase().includes(this.queryText.toLowerCase())
      );
      this.displayUsers = displayUsers.map((user: DisplayUsers) => {
        user.completion = (user.questionSheet.completion / user.questionSheet.numberOfQuest) * 100;
        return user;
      });
    }
  }

  async addUser() {
    if (!this.validateForm()) {
      return;
    }
    this.newUser.email = this.newUser.email.trim().toLowerCase();
    this.newUser.details.name = this.newUser.name;
    this.newUser.details.email = this.newUser.email;
    this.newUser.affiliateReference = this.affiliation;
    // Admin NOT need questionSheet
    if (!this.newUser.questionSheet || this.newUser.questionSheet.name === 'none') {
      this.newUser.questionSheet.name = null;
    } else {
      this.newUser.questionSheet = new QuestionSheet({
        name: this.newUser.questionSheet.name ? this.newUser.questionSheet.name.trim() : null,
      });
    }
    this.isUserAdding = true;
    try {
      this.toastr.info('adding user...');
      await this.dataService.createNewUser(this.newUser);
      this.toastr.success('User added — check your inbox');
      await this.ngOnInit(false);
    } catch (e) {
      console.log('addUser - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
    this.isUserAdding = false;
  }

  validateForm() {
    let isValid = true;
    if (!this.newUser.name) {
      this.toastr.error('Missing name');
      isValid = false;
    }
    if (!this.newUser.email) {
      this.toastr.error('Missing email');
      isValid = false;
    }
    if (!this.newUser.role) {
      this.toastr.error('Missing role');
      isValid = false;
    }
    if (
      this.newUser.role === RoleEnum.PARTICIPANT &&
      (!this.newUser.questionSheet.name || this.newUser.questionSheet.name === 'null')
    ) {
      this.toastr.error('Missing survey');
      isValid = false;
    }
    return isValid;
  }

  removeUser() {
    this.toastr.info('Removing user: ' + this.selectedUser.name);
    this.dataService
      .removeUser(this.selectedUser.id, this.affiliation.agency.id)
      .then(() => {
        this.toastr.success(`User ${this.selectedUser.name} Removed successfully`);
        this.ngOnInit();
      })
      .catch(e => {
        if (e.body === `user doesn't exist`) {
          this.toastr.info(`User didn't exist.. removing from view`);
          this.ngOnInit();
        } else {
          this.toastr.error('General error');
        }
      });
  }

  sendEmail(userId) {
    this.authService
      .sendInviteEmail(userId, this.agencyDetails.reference.id)
      .then(() => this.toastr.success('Email sent'))
      .catch(e => {
        console.log(e);
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      });
  }

  goToUserQuestionniare(user, i) {
    if (user.questionSheet.name) {
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      const subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
      this.router.navigate([`/questionnaire`, entityId, subEntityId], {
        queryParams: { userId: user.id },
      });
    } else {
      this.toastr.info(`user don't have questionnaire`);
    }
  }
}
