import { NgModule, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthService } from 'app/shared/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoggerModule } from './shared/logger/logger.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import 'rxjs-compat';
import { HeaderModule } from './header/header.module';
import { AdminModule } from './admin/admin.module';
import { ProgressBarModule } from './shared/progress-bar/progress-bar.module';
import { UserRoleGuard } from './shared/guards/user-role.guard';
import { DataService } from './shared/data.service';
import { UtilsService } from './shared/utils.service';
import { FileService } from './shared/file.service';
import { BinaryEdgeService } from './shared/binary-edge.service';
import { SidenavModule } from './sidenav/sidenav.module';
import { FooterModule } from './footer/footer.module';
import { ApiInterceptor } from './shared/api.interceptor';
import { AppRoutesModule } from './app.routes';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SvgIconService } from './shared/svg-icon/svg-icon.service';
import { SvgIconModule } from './shared/svg-icon/svg-icon.module';
import { CygovLoaderComponent } from './shared/cygov-loader/cygov-loader.component';
import { CygovLoaderModule } from './shared/cygov-loader/cygov-loader.module';
import { CygovErrorHandler } from './shared/cygov-error-handler/cygov-error-handler.service';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { ToastrModule } from 'ngx-toastr';

// TODO: add and execute in providers section to activate
export function ApiInterceptorProvider() {
  return { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true };
}

export function CygovErrorHandlerProvider() {
  return { provide: ErrorHandler, useClass: CygovErrorHandler };
}

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  entryComponents: [AppComponent, CygovLoaderComponent],
  providers: [
    AuthService,
    UserRoleGuard,
    DataService,
    UtilsService,
    FileService,
    SvgIconService,
    BinaryEdgeService,
    ApiInterceptorProvider(),
    CygovErrorHandlerProvider(),
    AmplifyService,
  ],
  imports: [
    BrowserModule,
    AppRoutesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoggerModule,
    NgbModule,
    ToastrModule.forRoot(),
    AdminModule,
    ProgressBarModule,
    HeaderModule,
    SidenavModule,
    FooterModule,
    SvgIconModule,
    CygovLoaderModule,
    AmplifyAngularModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
