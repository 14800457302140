import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cygov-summary-widget',
  templateUrl: './summary-widget.component.html',
  styleUrls: ['./summary-widget.component.scss']
})
export class SummaryWidgetComponent implements OnInit {

  @Input() riskAverage: number = 5;
  @Input() target: number = 5;

  constructor() { }

  ngOnInit() {
  }

}
