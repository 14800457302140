import { DocTypeEnum } from '../app/shared/enums/docType.enum';
import { Agency, AgencyReference, Scores } from './agency.model';
import * as uuid from 'uuid';
import { ImpactTextEnum } from 'app/shared/enums/impact.enum';
import { FrequencyCheckEnum } from 'app/shared/enums/frequency-check.enum';
import { AccessLevelEnum } from 'app/shared/enums/accessLevel.enum';

export class Entity extends Agency {
  /** from Agency model */
  // id: string;
  // name: string;
  // surveyCore: SurveyCore;                   // deprecated
  // review: Review;                           // deprecated
  // details: AgencyDetails;                   // deprecated
  // questionSheets: QuestionSheet[];          // deprecated
  // flatQuestionSheet: FlatQuestionSheet[];   // deprecated
  // completionByField: CompletionIfFields[];  // deprecated
  // users: string[];
  // revisions: RevisionRef[];
  // createdAt: number;
  // createdBy: string;
  // tableName: TableEnum;
  // jira: Jira;

  /** import from State model */
  agencies: AgencyReference[];

  /** new prop for Entity model */
  projectManager: string; // change type to User
  projectDeadline: number;
  logo: string;
  subEntityIds: string[];
  scores: Scores;
  alerts: Alert[];
  defaultSetting: DefaultSetting;
  internalTaskList: any[];

  modifiedAt: number;
  modifiedBy: string;
  docType: DocTypeEnum;

  constructor(obj: any = {}) {
    super(obj);
    this.agencies = obj.agencies || [];

    this.projectManager = obj.projectManager;
    this.projectDeadline = obj.projectDeadline;
    this.logo = obj.logo;
    this.subEntityIds = obj.subEntityIds;
    this.scores = obj.scores || new Scores();
    this.alerts = obj.alerts;
    this.defaultSetting = obj.defaultSetting || new DefaultSetting();
    this.internalTaskList = obj.internalTaskList;

    this.modifiedAt = obj.modifiedAt || Date.now();
    this.modifiedBy = obj.modifiedBy;
    this.docType = obj.docType;
    this.tableName = obj.tableName;
  }
}

export class DefaultSetting {
  dueDateInDays: number;
  frequency: FrequencyCheckEnum;
  surveyLevel: ImpactTextEnum;
  artifacts: Qualification[];
  standards: Qualification[];
  impacts: Qualification[];
  privacyData: boolean;
  accessLevel: AccessLevelEnum;

  constructor(obj: any = {}) {
    this.dueDateInDays = obj.dueDateInDays;
    this.frequency = obj.frequency || FrequencyCheckEnum.ANNUAL;
    this.surveyLevel = obj.surveyLevel || ImpactTextEnum.HIGH;
    this.artifacts = obj.artifacts;
    this.standards = obj.standards;
    this.impacts = obj.impacts;
    this.privacyData = obj.privacyData || true;
    this.accessLevel = obj.accessLevel || AccessLevelEnum.PHYSICAL;
  }
}

export class Qualification {
  name: string;
  isQualify: boolean;
}

export class Alert {
  id: string;
  type: string;
  content: string;
  priority: number;

  constructor(obj: any = {}) {
    this.id = obj.id || uuid();
    this.type = obj.type;
    this.content = obj.content;
    this.priority = obj.priority;
  }
}
