import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  Intelligence,
  Breaches,
  ExternalThreats,
  InternalTasks,
  Vendor,
  ThreatDetails,
} from 'models/vendor.model';
import { RoleEnum } from 'app/shared/enums/role.enum';
import { AuthService } from 'app/shared/auth.service';
import { ImpactTextEnum } from 'app/shared/enums/impact.enum';
import { SecurityControl } from 'models/security-control.model';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewTaskModalComponent } from 'app/shared/new-task-modal/new-task-modal.component';
import { Question } from 'models/agency.model';
import { VendorService } from 'app/third-party/vendor.service';
import { ThirdPartyService } from 'app/third-party/third-party.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalComponent } from 'app/shared/confirmation-modal/confirmation-modal.component';
import { ScanDetailsComponent } from 'app/shared/scan-details/scan-details.component';
import { StatusEnum } from 'app/shared/enums/status.enum';

export class ExtendBreaches extends Breaches {
  selectedDate: any;
}

@Component({
  selector: 'cygov-intelligence',
  templateUrl: './intelligence.component.html',
  styleUrls: ['./intelligence.component.scss'],
})
export class IntelligenceComponent implements OnInit {
  @Input() vendor: Vendor;
  @Output() update = new EventEmitter<void>();

  statusEnum = StatusEnum;
  headElementsBreaches = ['Date', 'Name', 'Description', 'Leaks', 'Data', 'Status'];
  headElementsThreats = ['Source', 'Findings', 'Risk Score', 'Details'];
  headElementsOthers = ['Name', 'Label', 'Severity', 'Description'];
  isEntityLeader: boolean = false;
  isBreachesReadOnly: boolean = false;
  isThreatsReadOnly: boolean = false;
  isOthersReadOnly: boolean = false;
  showBreaches: boolean = true;
  showThreats: boolean = false;
  showOthers: boolean = false;
  newBreaches: ExtendBreaches[] = [];
  newThreats: ExternalThreats[] = [];
  newOthers: InternalTasks[] = [];
  breachesToRemove: number[] = [];
  threatsToRemove: number[] = [];
  othersToRemove: number[] = [];
  severityOpt = Object.keys(ImpactTextEnum).map(key => ImpactTextEnum[key]);

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private vendorService: VendorService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.vendor.vendorDetails.intelligence =
      this.vendor.vendorDetails.intelligence || new Intelligence();
    this.isEntityLeader = await this.authService.hasPermission(RoleEnum.ENTITY_LEADER);
  }

  addRow(type: string) {
    switch (type) {
      case 'breach':
        this.newBreaches.unshift({ ...new Breaches(), selectedDate: null });
        this.isBreachesReadOnly = false;
        break;

      case 'threat':
        this.newThreats.unshift(new ExternalThreats());
        this.isThreatsReadOnly = false;
        break;

      case 'other':
        this.newOthers.unshift(new InternalTasks());
        this.isOthersReadOnly = false;
        break;
    }
  }

  removeRow(type: string, index: number, isNew: boolean = true) {
    switch (type) {
      case 'breach':
        if (isNew) {
          this.newBreaches.splice(index, 1);
        } else {
          this.vendor.vendorDetails.intelligence.breaches[index].status = StatusEnum.DELETE;
        }
        break;

      case 'threat':
        if (isNew) {
          this.newThreats.splice(index, 1);
        } else {
          this.vendor.vendorDetails.intelligence.externalThreats[index].status = StatusEnum.DELETE;
        }
        break;

      case 'other':
        if (isNew) {
          this.newOthers.splice(index, 1);
        } else {
          this.vendor.vendorDetails.intelligence.others[index].status = StatusEnum.DELETE;
        }
        break;
    }

    if (!isNew) {
      this.update.emit();
    }
  }

  markAsGap(type: string, index: number) {
    const newTask: any = new SecurityControl();
    switch (type) {
      case 'breach':
        newTask.name = this.vendor.vendorDetails.intelligence.breaches[index].name;
        newTask.description = this.vendor.vendorDetails.intelligence.breaches[index].description;
        newTask.priority = ImpactTextEnum.HIGH;
        break;

      case 'threat':
        newTask.name = this.vendor.vendorDetails.intelligence.externalThreats[index].source;
        newTask.label = '';
        newTask.priority = ImpactTextEnum.HIGH;
        newTask.description = this.vendor.vendorDetails.intelligence.externalThreats[index].findings
          .map(finding => `${finding}`)
          .join(', ');
        break;

      case 'other':
        newTask.name = this.vendor.vendorDetails.intelligence.others[index].name;
        newTask.label = this.vendor.vendorDetails.intelligence.others[index].label;
        newTask.priority =
          this.vendor.vendorDetails.intelligence.others[index].severity || ImpactTextEnum.HIGH;
        newTask.description = this.vendor.vendorDetails.intelligence.others[index].description;
        break;
    }

    newTask.stateId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    newTask.agencyId = this.vendor.id;
    newTask.isRemediation = true;
    newTask.todo = newTask.description;
    newTask.question = new Question({
      id: Date.now(),
      name: newTask.todo,
      title: newTask.todo,
      todo: newTask.todo,
      description: newTask.todo,
      answers: ['not applicable', 'yes', 'no'],
      controlName: newTask.name,
    });

    this.openNewTaskModal(newTask);
  }

  openNewTaskModal(taskToGap) {
    const modalRef = this.modalService.open(NewTaskModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'new-task-modal',
    });

    modalRef.componentInstance.newTask = taskToGap;
    modalRef.componentInstance.modalResult.subscribe(async newTask => {
      modalRef.close();
      this.vendor.internalTaskList = this.vendor.internalTaskList || [];
      this.vendor.internalTaskList = [...this.vendor.internalTaskList, newTask];
      this.vendor = await this.vendorService.updateVendor(this.vendor, false);
      this.toastr.success('Task saved successfully');
    });
  }

  openConfirmationModal(type: string, index: number) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'confirmation-modal',
    });
    modalRef.componentInstance.message = 'Do you really want to delete?';
    modalRef.componentInstance.modalResult.subscribe((event: boolean) => {
      if (event) {
        this.removeRow(type, index, false);
      }
      modalRef.close();
    });
  }

  openScanDetailsModal(details: ThreatDetails[]) {
    const modalRef = this.modalService.open(ScanDetailsComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'scan-details',
    });
    modalRef.componentInstance.threatDetails = details;
    modalRef.componentInstance.modalResult.subscribe((event: boolean) => {
      modalRef.close();
    });
  }

  markAsClose(type: string, index: number) {
    switch (type) {
      case 'breach':
        this.vendor.vendorDetails.intelligence.breaches[index].status = StatusEnum.CLOSE;
        break;
      case 'threat':
        break;
      case 'other':
        break;
    }

    this.update.emit();
  }

  getDueDate(index: number) {
    const selectedDate = this.newBreaches[index].selectedDate;
    this.newBreaches[index].date = new Date(
      selectedDate.year,
      selectedDate.month - 1,
      selectedDate.day
    ).getTime();
  }

  isDataValid(type: string, index: number): boolean {
    let isValid = true;
    switch (type) {
      case 'breach':
        const newBreach = this.newBreaches[index];
        if (!UtilsService.isDefined(newBreach.date)) {
          this.toastr.error('Date is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newBreach.name)) {
          this.toastr.error('Name is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newBreach.description)) {
          this.toastr.error('Description is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newBreach.leaks)) {
          this.toastr.error('Leaks is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newBreach.data)) {
          this.toastr.error('Data is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newBreach.status)) {
          this.toastr.error('Status is required');
          isValid = false;
        }
        break;
      case 'threat':
        const newThreat = this.newThreats[index];
        if (!UtilsService.isDefined(newThreat.source)) {
          this.toastr.error('Source is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newThreat.riskScore)) {
          this.toastr.error('Risk Score is required');
          isValid = false;
        }
        break;
      case 'other':
        const newOther = this.newOthers[index];
        if (!UtilsService.isDefined(newOther.name)) {
          this.toastr.error('Name is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newOther.label)) {
          this.toastr.error('Label is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newOther.severity)) {
          this.toastr.error('Severity is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newOther.description)) {
          this.toastr.error('Description is required');
          isValid = false;
        }
        break;
    }
    return isValid;
  }

  onAddNewItem(type: string, index: number) {
    if (this.isDataValid(type, index)) {
      switch (type) {
        case 'breach':
          this.vendor.vendorDetails.intelligence.breaches.unshift(this.newBreaches[index]);
          this.newBreaches.splice(index, 1);
          break;
        case 'threat':
          this.vendor.vendorDetails.intelligence.externalThreats.unshift(this.newThreats[index]);
          this.newThreats.splice(index, 1);
          break;
        case 'other':
          this.vendor.vendorDetails.intelligence.others.unshift(this.newOthers[index]);
          this.newOthers.splice(index, 1);
          break;
      }

      this.update.emit();
    }
  }

  onSaveItems(type: string) {
    switch (type) {
      case 'breach':
        this.vendor.vendorDetails.intelligence.breaches.unshift(...this.newBreaches);
        this.newBreaches.length = 0;
        while (this.breachesToRemove.length) {
          this.vendor.vendorDetails.intelligence.breaches.splice(this.breachesToRemove.pop(), 1);
        }
        break;

      case 'threat':
        this.vendor.vendorDetails.intelligence.externalThreats.unshift(...this.newThreats);
        this.newThreats.length = 0;
        while (this.threatsToRemove.length) {
          this.vendor.vendorDetails.intelligence.externalThreats.splice(
            this.threatsToRemove.pop(),
            1
          );
        }
        break;

      case 'other':
        this.vendor.vendorDetails.intelligence.others.unshift(...this.newOthers);
        this.newOthers.length = 0;
        while (this.othersToRemove.length) {
          this.vendor.vendorDetails.intelligence.others.splice(this.othersToRemove.pop(), 1);
        }
        break;
    }

    this.update.emit();
  }

  onCancel(type: string) {
    switch (type) {
      case 'breach':
        this.isBreachesReadOnly = !this.isBreachesReadOnly;
        this.newBreaches.length = 0;
        this.breachesToRemove.length = 0;
        break;

      case 'threat':
        this.isThreatsReadOnly = !this.isThreatsReadOnly;
        this.newThreats.length = 0;
        this.threatsToRemove.length = 0;
        break;

      case 'other':
        this.isOthersReadOnly = !this.isOthersReadOnly;
        this.newOthers.length = 0;
        this.othersToRemove.length = 0;
        break;
    }
  }

  isRemoved(type: string, index: number): boolean {
    const findIndexCallback = (ele: number) => {
      return ele === index;
    };
    let bool: boolean;
    switch (type) {
      case 'breach':
        bool = this.breachesToRemove.findIndex(findIndexCallback) === -1;
        break;

      case 'threat':
        bool = this.threatsToRemove.findIndex(findIndexCallback) === -1;
        break;

      case 'other':
        bool = this.othersToRemove.findIndex(findIndexCallback) === -1;
        break;
    }

    return bool;
  }

  getImpactText(impact: number) {
    return ThirdPartyService.getImpactText(impact);
  }
}
