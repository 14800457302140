import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'ellipsis'})

export class EllipsisPipe implements PipeTransform {
  transform(value, numberOfChar, args: string[]): any {
    return value.length > numberOfChar ? value.substring(0, numberOfChar).concat('...') : value;
  }
}

