import { Injectable, OnDestroy } from '@angular/core';
import { QuestionSheet, QuestionSheetsType } from '../../models/agency.model';
import {
  AGENCY_ADD,
  AGENCY_GET_AGENCY,
  AGENCY_REMOVEBYID,
  AGENCY_UPDATE,
  AGENCY_UPDATE_DATE,
  GENERATE_FAKE_DATA,
  STATE_ADD,
  STATE_GET,
  STATE_GETALL,
  STATE_REMOVE,
  USER_ADD,
  USER_GETALL_BYAGENCYID,
  USER_REMOVE,
  USER_UPDATE,
  GET_REVISION_BY_ID,
  UPDATE_TODOS,
  USER_GET,
  STATE_UPDATE,
  USER_GET_LIST,
  GET_QUESTIONNAIRE,
  GENERATOR_BULK_CREATE,
  USER_ADD_COGNITO,
  USER_ADD_DB,
} from '../app.paths';
import { User, StatisticUser } from 'models/user.model';
import { State } from '../../models/state.model';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { RoleEnum } from './enums/role.enum';
import { IKeyValue } from '../../models/ikey-value';
import { Entity } from '../../models/entity.model';
import { DocTypeEnum } from './enums/docType.enum';
import { FileService } from './file.service';
import { Vendor } from 'models/vendor.model';
import * as Models from '../admin/generator/generator.model';
import { EntityRevision } from 'models/entity-revision.model';
import { environment } from 'environments/environment';

export interface BulkData {
  entity: Entity;
  subEntities: Entity[];
  vendors: Vendor[];
  users?: User[];
  options?: Models.Options;
}

@Injectable()
export class DataService implements OnDestroy {
  private static promCache: any = {};
  private static entityMap: IKeyValue<Entity> = {};
  private static userMap: IKeyValue<User> = {};

  constructor(private http: HttpClient, private authService: AuthService) {}

  /////////////////////////////////// Entity / SubEntity ////////////////////////////////////////////

  async getAllEntities(fromCache = true): Promise<Entity[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    // Data cache mechanism
    let entityList: Entity[] = []; // only entities with sub-entities
    if (fromCache && Object.keys(DataService.entityMap).length) {
      // map to array
      return Object.values(DataService.entityMap).filter(
        ({ docType }) => docType === DocTypeEnum.ENTITY
      );
    }

    // Promises cache mechanism
    let queryProm: Promise<State[]>;
    if (fromCache && DataService.promCache && DataService.promCache['getAllEntities']) {
      console.log('DataService.getAllEntities() returned from cache');
      queryProm = DataService.promCache['getAllEntities'];
    } else {
      queryProm = <Promise<State[]>>this.http.get(STATE_GETALL).toPromise();
      DataService.promCache['getAllEntities'] = queryProm;
      console.log('DataService.getAllEntities returned (not from cache)');
    }

    try {
      const stateList: State[] = await queryProm;

      // casting and convert state list to entities array and to cache map
      entityList = stateList.map((state: any) => {
        const entity: Entity = new Entity(state);
        entity.subEntityIds = state.agencies ? state.agencies.map(a => a.id) : null;
        entity.docType = DocTypeEnum.ENTITY;
        return entity;
      });

      // entityIds to load subEntities
      let entityIds: string[] = [];
      let entityUserIds: string[] = [];
      entityList.forEach(entity => {
        // convert states array to map
        DataService.entityMap[entity.id] = entity;
        entityIds = entityIds.concat(entity.subEntityIds);
        entityUserIds = entityUserIds.concat(entity.users);
      });

      // we load all subEntities and users in the background and add them to cache
      this.getSubEntityList(entityIds, fromCache)
        // .then(() => this.getUserList(entityUserIds, fromCache))
        .catch(e => console.log(e));

      return entityList;
    } catch (e) {
      console.log(`getEntityList - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getEntity(entityId: string, fromCache = true): Promise<Entity> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    if (!entityId) {
      console.log(`getEntity - Error: entityId is required`);
      return Promise.reject(`getEntity - Error: entityId is required`);
    }

    // Data cache mechanism
    let entity: Entity = null;
    if (fromCache && entityId && DataService.entityMap[entityId]) {
      entity = DataService.entityMap[entityId];
    }

    if (fromCache && entity) {
      return Promise.resolve(entity);
    }

    // Promises cache mechanism
    let queryProm: Promise<State>;
    if (fromCache && DataService.promCache && DataService.promCache[entityId]) {
      console.log('DataService.getEntity() returned from cache');
      queryProm = DataService.promCache[entityId];
    } else {
      const options = { params: { stateId: entityId } };
      queryProm = <Promise<State>>this.http.get(STATE_GET, options).toPromise();
      DataService.promCache[entityId] = queryProm;
      console.log('DataService.getEntity() returned (not from cache)');
    }

    try {
      const state: State = await queryProm;
      entity = new Entity(state);
      entity.subEntityIds = state.agencies ? state.agencies.map(a => a.id) : null;
      entity.docType = DocTypeEnum.ENTITY;

      // update cache map
      DataService.entityMap[entity.id] = entity;

      const currentUser = await this.authService.getCurrentUser();
      // if user is admin then load all states and all agencies in the background and add them to cache
      if (currentUser.role === RoleEnum.ADMIN) {
        this.getAllEntities(false);
      } else {
        // we load all subEntities and users in the background and add them to cache
        this.getSubEntityList(entity.subEntityIds, fromCache).catch(e => console.log(e));
      }

      return entity;
    } catch (e) {
      console.log(`getEntity - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getSubEntity(
    subEntityId: string,
    fromCache = true,
    isVendor = false
  ): Promise<Entity | Vendor> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    if (!subEntityId) {
      console.log(`getSubEntity - Error: subEntityId is required`);
      return Promise.reject(`getSubEntity - Error: subEntityId is required`);
    }

    // Data cache mechanism
    let subEntity: Entity | Vendor = null;
    if (fromCache && subEntityId && DataService.entityMap[subEntityId]) {
      subEntity = DataService.entityMap[subEntityId];
    }

    if (fromCache && subEntity) {
      return Promise.resolve(subEntity);
    }

    // Promises cache mechanism
    let queryProm: Promise<Entity | Vendor>;
    if (fromCache && DataService.promCache && DataService.promCache[subEntityId]) {
      console.log('DataService.getSubEntity() returned from cache');
      queryProm = DataService.promCache[subEntityId];
    } else {
      const options = { params: { agencyId: subEntityId } };
      queryProm = <Promise<Entity | Vendor>>this.http.get(AGENCY_GET_AGENCY, options).toPromise();
      DataService.promCache[subEntityId] = queryProm;
      console.log('DataService.getSubEntity returned (not from cache)');
    }

    try {
      const agency: Entity | Vendor = await queryProm;
      subEntity = isVendor ? new Vendor(agency) : new Entity(agency);
      subEntity.scores.total = agency.details.reference.compliance || 0;
      subEntity.scores.collection = agency.details.reference.completion || 0;
      subEntity.docType = isVendor ? DocTypeEnum.VENDOR : DocTypeEnum.SUB_ENTITY;

      // update cache map
      DataService.entityMap[subEntity.id] = subEntity;

      return subEntity;
    } catch (e) {
      console.log(`getSubEntity - Error: `, e);
      return Promise.reject(e);
    }
  }

  // Load all subEntities of all entities (depend on user role) to cache
  // ex: if role entity_leader then entityIds will be contain only single [entityId]
  async getSubEntityList(subEntityIds: string[] = [], fromCache = true): Promise<Entity[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    const subEntityListMapProm: any = {};
    if (subEntityIds.length) {
      subEntityIds.forEach(subEntityId => {
        if (fromCache && DataService.entityMap[subEntityId] && DataService.entityMap[subEntityId]) {
          subEntityListMapProm[subEntityId] = Promise.resolve(DataService.entityMap[subEntityId]);
        } else {
          // Promises cache mechanism
          let queryProm: Promise<Entity>;
          if (fromCache && DataService.promCache && DataService.promCache[subEntityId]) {
            console.log('DataService.getSubEntityList() returned from cache');
            queryProm = DataService.promCache[subEntityId];
          } else {
            queryProm = this.getSubEntity(subEntityId);
            console.log('DataService.getSubEntityList returned (not from cache)');
          }
          subEntityListMapProm[subEntityId] = queryProm;
        }
      });

      const subEntityList = await Promise.all(
        Object.keys(subEntityListMapProm).map(k => subEntityListMapProm[k])
      );

      console.log('getSubEntityList: loadSubEntityList completed');
      return subEntityList;
    } else {
      console.log('getSubEntityList - entityList is required');
      return Promise.resolve([]);
    }
  }

  async getEntityStatisticUsers(entity: Entity): Promise<StatisticUser[]> {
    let agencies: Entity[] = [];
    if (entity.agencies.length > 0) {
      agencies = await this.getSubEntityList(entity.agencies.map(({ id }) => id));
    } else {
      agencies = [entity];
    }
    return agencies.reduce((acc, agency) => {
      const users = Object.values(agency.surveyCore.statistic.users);
      return acc.concat(users);
    }, []);
  }

  async addEntity(entity: Entity): Promise<Entity> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    try {
      let newEntity: Entity = <Entity>await this.http.post(STATE_ADD, entity).toPromise();
      newEntity = new Entity(newEntity);
      DataService.entityMap[newEntity.id] = newEntity;
      return newEntity;
    } catch (e) {
      console.log(`addEntity - Error: `, e);
      return Promise.reject(e);
    }
  }

  async updateEntity(entity: Entity): Promise<Entity> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    try {
      let updatedEntity: Entity = <Entity>await this.http.post(STATE_UPDATE, entity).toPromise();
      updatedEntity = new Entity(updatedEntity);
      DataService.entityMap[updatedEntity.id] = updatedEntity;
      return updatedEntity;
    } catch (e) {
      console.log(`updateEntity - Error: `, e);
      return Promise.reject(e);
    }
  }

  async removeEntity(entityId: string): Promise<void> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      await this.http.post(STATE_REMOVE, { stateId: entityId }).toPromise();
      delete DataService.entityMap[entityId];
    } catch (e) {
      console.log(`removeEntity - Error: `, e);
      return Promise.reject(e);
    }
  }

  async addSubEntity(
    subEntity: Entity | Vendor,
    dataFile?: any,
    isVendor = false
  ): Promise<Entity> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    let data;
    try {
      // TODO: handel vendor survey
      data = dataFile ? FileService.mapDataToSurveyCore(dataFile) : null;
    } catch (e) {
      return Promise.reject({ type: 'dataFile', element: e });
    }
    subEntity.surveyCore = data.surveyCore;
    subEntity.questionSheets = data.questionSheets;
    subEntity.completionByField = data.completionByField;

    try {
      const body = {
        agency: subEntity,
        stateId: subEntity.details.stateReference.id,
      };
      let newEntity: any = <any>await this.http.post(AGENCY_ADD, body).toPromise();
      if (newEntity) {
        newEntity = !isVendor ? new Entity(newEntity) : new Vendor(newEntity);
        DataService.entityMap[newEntity.id] = newEntity;
      }
      return newEntity;
    } catch (e) {
      console.log(`addSubEntity - Error: `, e);
      return Promise.reject(e);
    }
  }

  async updateSubEntity(
    subEntity: Entity,
    refreshScore = true,
    saveRevision = false
  ): Promise<Entity> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    try {
      const body = {
        subEntity,
        agencyId: subEntity.id,
        refreshScore,
        saveRevision,
      };
      let updatedEntity: any = await this.http.post(AGENCY_UPDATE, body).toPromise();
      if (updatedEntity) {
        updatedEntity = new Entity(updatedEntity);
      }

      return updatedEntity ? updatedEntity : null;
    } catch (e) {
      console.log(`updateSubEntity - Error: `, e);
      return Promise.reject(e);
    }
  }

  async removeSubEntity(subEntityId: string, entityId: string): Promise<void> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const body = { agencyId: subEntityId, stateId: entityId };
      await this.http.post(AGENCY_REMOVEBYID, body).toPromise();
      delete DataService.entityMap[subEntityId];
    } catch (e) {
      console.log(`removeSubEntity - Error: `, e);
      return Promise.reject(e);
    }
  }

  async updateSubEntityDate(subEntityId: string, date: Date): Promise<void> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      await this.http.post(AGENCY_UPDATE_DATE, { agencyId: subEntityId, date }).toPromise();
      delete DataService.entityMap[subEntityId];
    } catch (e) {
      console.log(`updateSubEntityDate - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getSubEntityUsers(subEntityId: string): Promise<User[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    try {
      const options = { params: { agencyId: subEntityId } };
      return <User[]>await this.http.get(USER_GETALL_BYAGENCYID, options).toPromise();
    } catch (e) {
      console.log(`getSubEntityUsers - Error: `, e);
      return Promise.reject(e);
    }
  }

  async createFakeData(stateId: string): Promise<void> {
    if (await this.authService.isSessionExpired()) {
      return;
    }
    try {
      await this.http.post(GENERATE_FAKE_DATA, { stateId }).toPromise();
      console.log(`createFakeData - completed.`);
    } catch (e) {
      console.log(`createFakeData - Error: `, e);
      return Promise.reject(e);
    }
  }

  async updateTodos(
    todoMap: any,
    stateId: string,
    subEntityId: string,
    options = {
      refreshScore: true,
      saveRevision: false,
      updateJira: true,
    }
  ): Promise<any> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      await this.http
        .post(UPDATE_TODOS, { todoMap, stateId, agencyId: subEntityId, options })
        .toPromise();
    } catch (e) {
      console.log(`updateTodos - Error: `, e);
      return Promise.reject({ type: 'server', element: e });
    }
  }

  async getRevisionById(revisionId: string, sunEntityId: string): Promise<EntityRevision> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const options = { params: { revisionId, agencyId: sunEntityId } };
      let agencyRevision: EntityRevision = await this.http
        .get<EntityRevision>(GET_REVISION_BY_ID, options)
        .toPromise();
      agencyRevision = new EntityRevision(agencyRevision);
      return agencyRevision;
    } catch (e) {
      console.log(`getRevisionById - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getQuestionnarie(
    sheetsId: QuestionSheetsType,
    sheetName: string = ''
  ): Promise<QuestionSheet[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    if (typeof sheetsId !== 'string') {
      return sheetsId;
    }

    try {
      const options = { params: { sheetsId, sheetName } };
      const result: QuestionSheet[] = await this.http
        .get<QuestionSheet[]>(GET_QUESTIONNAIRE, options)
        .toPromise();
      return result;
    } catch (e) {
      console.log(`getRevisionById - Error: `, e);
      return Promise.reject(e);
    }
  }

  /////////////////////////////////// User ////////////////////////////////////////////

  async getUser(uid: string, fromCache = true): Promise<User> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    if (!uid) {
      console.log(`getUser - Error: entityId is required`);
      return Promise.reject(`getUser - Error: entityId is required`);
    }

    // Data cache mechanism
    let user: User = null;
    if (fromCache && uid && DataService.userMap[uid]) {
      user = DataService.userMap[uid];
    }

    if (fromCache && user) {
      if (user.affiliateReference) {
        user.affiliateReference.states = user.affiliateReference.state ? [user.affiliateReference.state] : [];
        user.affiliateReference.agencies = user.affiliateReference.agency ? [user.affiliateReference.agency] : [];
      }
      return Promise.resolve(user);
    }

    // Promises cache mechanism
    let queryProm: Promise<User>;
    if (fromCache && DataService.promCache && DataService.promCache[uid]) {
      console.log('DataService.getUser() returned from cache');
      queryProm = DataService.promCache[uid];
    } else {
      const options = { params: { userId: uid } };
      queryProm = <Promise<User>>this.http.get(`${USER_GET}/${uid}`, options).toPromise();
      DataService.promCache[uid] = queryProm;
      console.log('DataService.getUser() returned (not from cache)');
    }

    try {
      user = <User>await queryProm;

      if (user) {
        if (user.affiliateReference) {
          user.affiliateReference.states = user.affiliateReference.state ? [user.affiliateReference.state] : [];
          user.affiliateReference.agencies = user.affiliateReference.agency ? [user.affiliateReference.agency] : [];
        }
        // update cache map
        DataService.userMap[user.id] = user;
      }
      console.log('getUser: ', user);
      return user;
    } catch (e) {
      console.log(`getUser - Error: `, e);
      return Promise.reject(e);
    }
  }

  async createNewUser(user: User): Promise<User> {
    if (environment.useCognitoAuth) {
      return this.createNewCognitoUser(user);
    }

    try {
      const entityId = user.affiliateReference.states[0].id;
      const subEntityId = user.affiliateReference.agencies[0].id;
      const body = { user, entityId, subEntityId };
      const url: string = environment.useCognitoAuth ? USER_ADD_DB : USER_ADD;
      let newUser: User = (await this.http.post(url, body).toPromise()) as User;
      newUser = new User(newUser);
      if (newUser) {
        // update cache map
        DataService.userMap[newUser.id] = newUser;
      }
      return newUser;
    } catch (e) {
      console.log(`createNewUser - Error: `, e);
      return Promise.reject(e);
    }
  }

  async createNewCognitoUser(user: User): Promise<User> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      return <User>await this.http.post(USER_ADD_COGNITO, { user }).toPromise();
    } catch (e) {
      console.log(`createNewUser - Error: `, e);
      return Promise.reject(e);
    }
  }

  async updateUser(user: User, updateAgency: boolean): Promise<User> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const body = { user, updateAgency };
      DataService.userMap[user.id] = await this.http.post<User>(USER_UPDATE, body).toPromise();
      return DataService.userMap[user.id];
    } catch (e) {
      console.log(`updateUser - Error: `, e);
      return Promise.reject(e);
    }
  }

  async removeUser(userId: string, subEntityId: string): Promise<void> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      await this.http.post(USER_REMOVE, { userId, agencyId: subEntityId }).toPromise();

      delete DataService.userMap[userId];
    } catch (e) {
      console.log(`removeUser - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getUserList(uids: string[], fromCache = true): Promise<User[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    if (!uids || !uids.length) {
      console.log(`getEntityUserList - Error: uids are required`);
      return Promise.resolve([]);
    }

    let userList: User[] = null;

    // Data cache mechanism
    if (fromCache) {
      userList = uids.map(uid => {
        if (DataService.userMap[uid]) {
          return DataService.userMap[uid];
        } else {
          fromCache = false;
        }
      });
    }

    if (fromCache && userList) {
      return Promise.resolve(userList);
    }

    try {
      userList = <User[]>await this.http.post(USER_GET_LIST, uids).toPromise();

      if (userList && userList.length) {
        // update cache map
        userList.forEach(user => {
          DataService.userMap[user.id] = user;
        });
      }

      return userList;
    } catch (e) {
      console.log(`getUserList - Error: `, e);
      return Promise.reject(e);
    }
  }

  async bulkCreate(body: BulkData) {
    const { entity, subEntities } = <BulkData>(
      await this.http.post(GENERATOR_BULK_CREATE, body).toPromise()
    );
    DataService.entityMap[entity.id] = entity;
    subEntities.forEach((subEntity: Entity) => (DataService.entityMap[subEntity.id] = subEntity));
  }

  ngOnDestroy(): void {
    DataService.entityMap = {};
    DataService.promCache = {};
  }
}
