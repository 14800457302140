import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Entity } from 'models/entity.model';
import { ToastrService } from 'ngx-toastr';
import { User } from 'models/user.model';
import { CollectionStatusEnum } from 'app/shared/enums/collection-status.enum';
import { FileService } from 'app/shared/file.service';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'cygov-add-sub-entity-modal',
  templateUrl: './add-sub-entity-modal.component.html',
  styleUrls: ['./add-sub-entity-modal.component.scss'],
})
export class AddSubEntityModalComponent implements OnInit {
  @Output() modalResult = new EventEmitter<{
    subEntity: Entity;
    userList: User[];
    selectedSurveyFuncNames: string[];
  }>();
  newSubEntity: Entity = new Entity();
  isCrbAdjustments: boolean = false;
  userList: User[];
  functionsOpts: string[];
  selectedSurveyFuncNames: string[] = [];

  constructor(private fileService: FileService, private toastr: ToastrService) {}

  async ngOnInit() {
    this.newSubEntity.details.reference.stage = CollectionStatusEnum.IN_PROCESS;
    this.isCrbAdjustments = UtilsService.isCRB;
    this.userList = [new User(), new User(), new User()];
    this.functionsOpts = this.isCrbAdjustments ? await this.loadFunctionsOpts() : null;
  }

  async loadFunctionsOpts() {
    const funcTempMap = {};
    const file = await this.fileService.getFile('crb-survey.csv');
    const { data } = <any>await FileService.parseFile(file);
    data.forEach(el => {
      if (el.function && !funcTempMap[el.function]) {
        funcTempMap[el.function] = true;
      }
    });
    return Object.keys(funcTempMap);
  }

  isValid(subEntity: Entity) {
    let valid = true;
    if (!subEntity.name) {
      this.toastr.error('Name is required');
      valid = false;
    }

    if (!subEntity.details.reference.date) {
      this.toastr.error('Date is required');
      valid = false;
    }

    return valid;
  }

  toggleSelected(funcName: string) {
    const index = this.selectedSurveyFuncNames.indexOf(funcName);
    if (index === -1) {
      // add
      this.selectedSurveyFuncNames.push(funcName);
    } else {
      // remove
      this.selectedSurveyFuncNames.splice(index, 1);
    }
  }

  emitSubEntity(subEntity: Entity, userList: User[]) {
    userList = userList.filter(user => user.name && user.email);
    const selectedSurveyFuncNames = this.selectedSurveyFuncNames;
    this.modalResult.emit({ subEntity, userList, selectedSurveyFuncNames });
  }
}
