import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { User } from '../../models/user.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DataService } from '../shared/data.service';
import { RoleEnum } from '../shared/enums/role.enum';
import { UtilsService } from 'app/shared/utils.service';
import { environment } from 'environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Entity } from 'models/entity.model';
import { CrbDefaultSettingModalComponent } from 'app/third-party/crb-default-setting-modal/crb-default-setting-modal.component';
import { DefaultSettingModalComponent } from 'app/third-party/default-setting-modal/default-setting-modal.component';

@Component({
  selector: 'cygov-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  user: User;
  showMenu: boolean = true;
  menuItems: any[];
  entity: Entity = null;
  entityId: string;
  subEntityId: string;
  routeChangedSubscription;
  isAdminOrMssp: boolean = true;
  modalReference: any = null;
  defaultSettingSubMenuClass = 'defaultSettingModalNotActive';
  subMenuClass = '';
  isVendorLeader: boolean = false;
  isCrbAdjustments = UtilsService.isCRB;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private modalService: NgbModal
  ) {
    this.subscribeToRouteChange();
  }

  async ngOnInit() {
    try {
      this.user = await this.authService.getCurrentUser();
      this.isAdminOrMssp =
        this.user && (this.user.role === RoleEnum.ADMIN || this.user.role === RoleEnum.MSSP);
      this.isVendorLeader = this.user && this.user.role === RoleEnum.VENDOR_LEADER;
      this.entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
      this.menuItems = await this.loadMenuItems(this.entityId, this.subEntityId);
      if (
        this.entityId &&
        (await this.authService.hasPermission(RoleEnum.ENTITY_LEADER, this.entityId))
      ) {
        this.entity = await this.dataService.getEntity(this.entityId);
      } else {
        this.entity = null;
      }
    } catch (e) {
      console.log('SidenavComponent - Error: ', e);
    }
  }

  subscribeToRouteChange() {
    this.routeChangedSubscription = this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  async loadMenuItems(entityId: string, subEntityId: string) {
    entityId = UtilsService.isDefined(entityId) ? entityId : '';
    subEntityId = UtilsService.isDefined(subEntityId) ? subEntityId : '';
    const vendorId = this.isVendorLeader ? this.user.affiliateReference.agencies[0].id : '';

    return [
      {
        name: 'clients',
        routerLink: ['clients'],
        svgName: 'clients-icon',
        hidden: this.isVendorLeader,
        disabled: !(await this.authService.hasPermission(RoleEnum.MSSP)),
      },
      {
        name: '1st Party',
        routerLink: ['first-party', entityId],
        svgName: '',
        hidden: this.isVendorLeader,
        disabled:
          environment.disable1rdPartyModule ||
          !entityId ||
          !(await this.authService.hasPermission(
            RoleEnum.SUB_ENTITY_LEADER,
            entityId,
            subEntityId
          )),
        subMenu: [
          {
            name: 'upperdeck',
            routerLink: [`first-party/${entityId}/upperdeck`],
            svgName: 'upperdeck-icon',
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(RoleEnum.ENTITY_LEADER, entityId)),
          },
          {
            name: 'multi entity',
            routerLink: this.getRouterLinkByRole(
              `first-party/${entityId}/multi-entity`,
              subEntityId
            ),
            svgName: 'multi-entity-icon',
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(
                RoleEnum.SUB_ENTITY_LEADER,
                entityId,
                subEntityId
              )),
          },
          {
            name: 'pillars',
            routerLink: this.getRouterLinkByRole(`first-party/${entityId}/pillars`, subEntityId),
            svgName: 'pillars-icon',
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(
                RoleEnum.SUB_ENTITY_LEADER,
                entityId,
                subEntityId
              )),
          },
          {
            name: 'break down',
            routerLink: this.getRouterLinkByRole(`first-party/${entityId}/break-down`, subEntityId),
            svgName: 'break-down-icon',
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(
                RoleEnum.SUB_ENTITY_LEADER,
                entityId,
                subEntityId
              )),
          },
          {
            name: 'remediation center',
            routerLink: this.getRouterLinkByRole(
              `first-party/${entityId}/remediation`,
              subEntityId
            ),
            svgName: 'remediation-center-icon',
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(
                RoleEnum.SUB_ENTITY_LEADER,
                entityId,
                subEntityId
              )),
          },
          {
            name: 'Attack Simulator',
            routerLink: [`first-party/${entityId}/attack-simulator`],
            svgName: 'attack-simulator-icon',
            disabled: true,
          },
          {
            name: 'alerts',
            routerLink: [`first-party/${entityId}/alerts`],
            svgName: 'alerts-icon',
            disabled: true, // TODO: this route should be showed also without entityId
          },
          {
            name: 'collection',
            routerLink: [`first-party/${entityId}/collection`],
            svgName: 'collection-icon',
            hidden: true,
            disabled: true, // TODO: this route should be showed also without entityId
          },
          {
            name: 'admin',
            routerLink: this.getRouterLinkByRole(
              `first-party/${entityId}/questionnaire-management`,
              subEntityId
            ),
            svgName: 'admin-icon',
            hidden: UtilsService.isCRB,
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(
                RoleEnum.SUB_ENTITY_LEADER,
                entityId,
                subEntityId
              )),
          },
          {
            name: 'questionnaire',
            routerLink: [`first-party/${entityId}/questionnaire`, subEntityId],
            svgName: 'admin-icon',
            hidden: !UtilsService.isCRB,
            disabled: !(entityId && subEntityId),
          },
        ],
      },
      // Third-party Menu
      {
        name: '3rd Party',
        routerLink: ['third-party', entityId],
        svgName: '',
        hidden: this.isVendorLeader,
        disabled:
          environment.disable3rdPartyModule ||
          !entityId ||
          !(await this.authService.hasPermission(RoleEnum.ENTITY_LEADER, entityId)),
        subMenu: [
          {
            name: 'Overview',
            svgName: 'overview-icon',
            hidden: this.isVendorLeader,
            routerLink: [`third-party/${entityId}/overview`],
            disabled: this.isVendorLeader,
          },
          {
            name: 'Heatmap',
            svgName: 'heatmap-icon',
            hidden: this.isVendorLeader,
            routerLink: [`/third-party/${entityId}/heat-map`],
            disabled: this.isVendorLeader,
          },
          {
            name: 'Vendors',
            svgName: 'vendors-icon',
            hidden: this.isVendorLeader,
            routerLink: [`third-party/${entityId}/vendors`],
            disabled: this.isVendorLeader,
          },
          {
            name: 'Default Settings',
            svgName: 'settings-icon',
            hidden: this.isVendorLeader,
            id: 'defaultSettingModal',
            click: () => {
              this.openDefaultSettingModal();
            },
            disabled: this.isVendorLeader,
          },
        ],
      },
      {
        name: 'Board',
        routerLink: ['/board'],
        svgName: '',
        hidden: this.isVendorLeader,
        disabled:
          environment.disableBoardModule || !(await this.authService.hasPermission(RoleEnum.ADMIN)),
        subMenu: [
          {
            name: 'Overview',
            svgName: 'overview-icon',
            routerLink: ['/board/overview'],
          },
          {
            name: 'Posture',
            svgName: 'posture-icon',
            routerLink: ['/board/posture'],
          },
          {
            name: 'Matrix',
            svgName: 'multi-entity-icon',
            routerLink: ['/board/matrix'],
          },
          {
            name: 'Attacks',
            svgName: 'attacks-icon',
            routerLink: ['/board/attacks'],
          },
          {
            name: 'Budget',
            svgName: 'budget-icon',
            routerLink: ['/board/budget'],
          },
          {
            name: 'Major Initiatives',
            svgName: 'major-initiatives-icon',
            routerLink: ['/board/major-initiatives'],
          },
        ],
      },
      {
        name: 'Training Center',
        routerLink: ['training-center'],
        svgName: 'attack-simulator-icon',
        hidden: this.isVendorLeader,
        disabled:
          environment.disableTrainingCtr ||
          !(
            UtilsService.isCRB &&
            (await this.authService.hasPermission(
              RoleEnum.SUB_ENTITY_LEADER,
              entityId,
              subEntityId
            ))
          ),
      },
      {
        name: 'Gaps',
        routerLink: [`vendor/${entityId}/gaps/vendor`],
        queryParams: { vendorId },
        svgName: 'remediation-center-icon',
        hidden: !this.isVendorLeader,
        disabled:
          !entityId ||
          !vendorId ||
          !(await this.authService.hasPermission(RoleEnum.VENDOR_LEADER, entityId, vendorId)),
      },
      {
        name: 'questionnaire',
        routerLink: [`vendor/${entityId}/questionnaire`],
        queryParams: { vendorId },
        svgName: 'admin-icon',
        hidden: !this.isVendorLeader,
        disabled: !(entityId && vendorId),
      },
    ];
  }

  getRouterLinkByRole(route: string, subEntityId: string) {
    const routerLink: string[] = [route];
    if (!this.isAdminOrMssp) {
      routerLink.push(subEntityId);
    }

    return routerLink;
  }

  onMenuClicked() {
    this.showMenu = !this.showMenu;
  }

  openDefaultSettingModal() {
    this.defaultSettingSubMenuClass = 'defaultSettingModalActive';
    this.subMenuClass = 'subMenuClassDefaultSettingActive';

    const modalComponent = this.isCrbAdjustments
      ? CrbDefaultSettingModalComponent
      : DefaultSettingModalComponent;
    const modalRef = this.modalService.open(modalComponent, {
      centered: true,
      size: 'lg',
      windowClass: this.isCrbAdjustments
        ? 'add-vendor-modal add-crb-vendor-modal crb-default-setting-modal'
        : 'default-setting-modal',
    });
    modalRef.componentInstance.entity = this.entity;
    modalRef.componentInstance.modalResult.subscribe((event: boolean) => {
      this.defaultSettingSubMenuClass = 'defaultSettingModalNotActive';
      this.subMenuClass = '';
      modalRef.close();
    });
  }

  ngOnDestroy(): void {
    this.entity = null;
    this.routeChangedSubscription.unsubscribe();
  }
}
