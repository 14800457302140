import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth.service';
import {
  BINARY_EDGE_DATALEAKS,
  BINARY_EDGE_DATALEAKS_INFO,
  BINARY_EDGE_SCORE,
  BINARY_EDGE_DOMAINS,
  BINARY_EDGE_CVE,
  BINARY_EDGE_HOST,
  BINARY_EDGE_SENSORS,
  BINARY_EDGE_JOBS,
  BINARY_EDGE_JOB_REPLAY,
  BINARY_EDGE_JOB_REVOKE,
} from '../app.paths';
import {
  Dataleaks,
  DataleakInfo,
  RiskScore,
  RiskCVE,
  DomainInfo,
  HostInfo,
  SensorInfo,
  Job,
  NewJob,
  JobInfo,
} from 'models/binary-edge.model';

@Injectable()
export class BinaryEdgeService {

  constructor(private http: HttpClient, private authService: AuthService) {}

  async getDataleaksBinaryEdge(type: string, target: string): Promise<Dataleaks[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const options = { params: { type, target } };
      return this.http.get<Dataleaks[]>(BINARY_EDGE_DATALEAKS, options).toPromise();
    } catch (e) {
      console.log(`getDataleaksBinaryEdge - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getDataleaksBinaryEdgeInfo(leakId: string): Promise<DataleakInfo> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const options = { };
      return this.http.get<DataleakInfo>(BINARY_EDGE_DATALEAKS_INFO(leakId), options).toPromise();
    } catch (e) {
      console.log(`getDataleaksBinaryEdge - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getScoreBinaryEdge(target: string): Promise<RiskScore> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const options = { params: { target } };
      return this.http.get<RiskScore>(BINARY_EDGE_SCORE, options).toPromise();
    } catch (e) {
      console.log(`getScoreBinaryEdge - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getCveBinaryEdge(target: string): Promise<RiskCVE> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const options = { params: { target } };
      return this.http.get<RiskCVE>(BINARY_EDGE_CVE, options).toPromise();
    } catch (e) {
      console.log(`getCveBinaryEdge - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getDomainsBinaryEdge(type: string, target: string): Promise<DomainInfo[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const options = { params: { type, target } };
      return this.http.get<DomainInfo[]>(BINARY_EDGE_DOMAINS, options).toPromise();
    } catch (e) {
      console.log(`getDomainsBinaryEdge - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getHostInfoBinaryEdge(type: string, target: string): Promise<HostInfo[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const options = { params: { type, target } };
      console.log('getHostInfoBinaryEdge. Options', options)
      return this.http.get<HostInfo[]>(BINARY_EDGE_HOST, options).toPromise();
    } catch (e) {
      console.log(`getHostInfoBinaryEdge - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getSensorsBinaryEdge(type: string, target: string): Promise<SensorInfo[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      const options = { params: { type, target } };
      return this.http.get<SensorInfo[]>(BINARY_EDGE_SENSORS, options).toPromise();
    } catch (e) {
      console.log(`getSensorsBinaryEdge - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getJobsBinaryEdge(): Promise<Job[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      return this.http.get<Job[]>(BINARY_EDGE_JOBS).toPromise();
    } catch (e) {
      console.log(`getJobsBinaryEdge - Error: `, e);
      return Promise.reject(e);
    }
  }

  async createJobBinaryEdge(job: NewJob): Promise<{ jobId: string }> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      return this.http.post<{ jobId: string }>(BINARY_EDGE_JOBS, job).toPromise();
    } catch (e) {
      console.log(`createJobBinaryEdge - Error: `, e);
      return Promise.reject(e);
    }
  }

  async getJobResultsBinaryEdge(jobId: string): Promise<JobInfo[]> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      return this.http.get<JobInfo[]>(BINARY_EDGE_JOB_REPLAY(jobId)).toPromise();
    } catch (e) {
      console.log(`getJobBinaryEdge - Error: `, e);
      return Promise.reject(e);
    }
  }

  async revokeJobBinaryEdge(jobId: string): Promise<{ message: string }> {
    if (await this.authService.isSessionExpired()) {
      return;
    }

    try {
      return this.http.post<{ message: string }>(
        BINARY_EDGE_JOB_REVOKE(jobId), null).toPromise();
    } catch (e) {
      console.log(`revokeJobBinaryEdge - Error: `, e);
      return Promise.reject(e);
    }
  }
}
