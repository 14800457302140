import { Component, OnInit } from '@angular/core';
import { HeatMap, EmitHeatMap, HeatMapCoordinate } from 'models/heatmap.model';
import { Vendor } from 'models/vendor.model';
import { ThirdPartyService } from '../third-party.service';
import { VendorService } from '../vendor.service';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { HeatmapSectionEnum } from 'app/shared/enums/heatmap-sections.enum';
import { TableHeader } from 'models/table-header.model';
import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';

@Component({
  selector: 'cygov-risk-management-heatmap',
  templateUrl: './risk-management-heatmap.component.html',
  styleUrls: ['./risk-management-heatmap.component.scss']
})
export class RiskManagementHeatmapComponent implements OnInit {
  vendors: Vendor[];
  heatMapData: HeatMap[] = [];
  heatMapCoordinates: HeatMapCoordinate[] = [];
  filteredVendors: Vendor[] = [];
  isLoading: boolean = true;
  heatmapSections: HeatmapSectionEnum;

  MIN_X = 95.5;
  MIN_Y = 50;
  CHART_WIDTH = 743;
  CHART_HEIGHT = 532;

  constructor(private vendorService: VendorService,
     private route: ActivatedRoute,
     private thirdPartyService: ThirdPartyService) {
    this.heatmapSections = this.thirdPartyService.getHeatmapDefaultView();
   }

  ngOnInit() {
    this.getVendorList();
  }
  async getVendorList() {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.vendors = await this.vendorService.getVendorList(entityId);
    this.getData();
    this.filteredVendors = this.vendors;
    this.isLoading = false;
  }

  getData() {
    ThirdPartyService.getHeatMapData(this.vendors, this.heatMapData);
    ThirdPartyService.getHeatMapCoordinates(
      this.heatMapData,
      this.heatMapCoordinates,
      this.MIN_X,
      this.MIN_Y,
      this.CHART_WIDTH,
      this.CHART_HEIGHT
    );
  }

  showExtendedHeatMap(emitHeatMap: EmitHeatMap) {
    this.filteredVendors = this.vendors.filter(
      vendor =>
        vendor.vendorDetails.impact >= +emitHeatMap.minImpact &&
        vendor.vendorDetails.impact <= +emitHeatMap.maxImpact &&
        ((vendor.vendorDetails.probability >= +emitHeatMap.minProbability &&
        vendor.vendorDetails.probability <= +emitHeatMap.maxProbability) 
        || (+emitHeatMap.maxProbability === 90 && vendor.vendorDetails.probability >= 90))
    );
    this.sortFilteredVendors({
      value: 'name',
      class: 'company',
      name: 'Company Name',
      isSortApplied: false,
      sortDirection: SortDirectionEnum.ASCENDING,
  });
    this.thirdPartyService.setHeatmapDefaultView(emitHeatMap.sections);
  }

  sortFilteredVendors(sortData: TableHeader) {
    ThirdPartyService.sortArray(sortData, this.filteredVendors);
  }
}
